import React, { useState, useEffect, useRef } from "react";
import IndexNavbar2 from "components/Navbars/IndexNavbar2";
import Footer2 from "components/Footers/Footer2";
import ss1 from "assets/img/ss1.png";
import ImageSection from "./ImageSection";
import img8 from "assets/img/img8.svg";
import img9 from "assets/img/img9.svg";
import shot from "assets/img/shot.png";
import building from "assets/img/building.svg";

export default function Servicenew() {
  const sectionRef = useRef();
  const [img9Visible, setImg9Visible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setImg9Visible(true);
          }
        });
      },
      { threshold: 0.1 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);
  return (
    <>
      <IndexNavbar2 fixed />
      <section className="bg xl:pt-28">
        <div className="container mx-auto overflow-hidden xl:px-20">
          <div className="grid md:grid-cols-2 grid-cols-1 gap-2 xl:py-20">
            <div>
              <h2 className="font-extrabold xl:text-6xl lg:text-2xl md:text-2xl text-xl text-white banner-font">
                THE WORLD'S FAVORITE CRM.
              </h2>
              <h2 className="font-extrabold outline xl:text-6xl lg:text-2xl md:text-2xl text-xl text-white banner-font">
                MADE IN INDIA.
              </h2>
              <p className="mt-3 ext-left xl:text-base1 text-base text-white leading-relaxed headlineheightt banner-font">
                Who said world-class software can only be built in Silicon
                Valley? Zoho CRM empowers 250,000+ global businesses to deliver
                fantastic customer experiences and drive remarkable growth. Made
                right here in India–try the SaaS from Madras!
              </p>
            </div>
            <div className="xl:px-20">
              <p className="mt-3 text-center xl:text-base1 font-semibold text-base text-white banner-font">
                Get started with your free trial
              </p>
              <input
                type="text"
                placeholder="Full Name"
                className="w-full rounded-md mt-2 text-white border-white mt-4"
              />
              <input
                type="email"
                placeholder="Email"
                className="w-full rounded-md mt-2 text-white border-white"
              />
              <input
                type="password"
                placeholder="Password"
                className="w-full rounded-md mt-2 text-white border-white"
              />
              <input
                type="text"
                placeholder="Number"
                className="w-full rounded-md mt-2 text-white border-white"
              />
              <button className="xl:mt-4 lg:mt-8 mt-8 bg-button text-white text-sm banner-font w-full py-2 font-medium">
                get started
              </button>
            </div>
          </div>
        </div>
        <div className="bg-button">
          <div className="container mx-auto overflow-hidden xl:px-20 xl:pt-20">
            <h2 className="xl:px-60 text-center font-medium xl:text-4xl lg:text-2xl md:text-2xl text-xl text-white banner-font">
              Build great customer relationships
            </h2>
            <p className="xl:px-52 lg:px-56 md:px-32 px-4 xl:mt-3 text-center xl:text-base1 text-base text-white banner-font">
              Providing personalized experiences is what keeps customers around,
              and Zoho CRM can help you do just that. With the power of
              omnichannel presence, segmentation, predictive intelligence, and
              more, you can build and maintain great customer relationships.
            </p>
            <a href="">
              <p className="xl:px-52 text-sky-blue lg:px-56 md:px-32 px-4 xl:mt-3 text-center xl:text-base1 text-base text-white banner-font">
                How Zoho CRM can help build better relationships ➜
              </p>
            </a>
            <div className="center-items">
              <img src={ss1} className="xl:w-12/12" />
            </div>
          </div>
        </div>
        <div className="bg-grey">
          <div className="container mx-auto overflow-hidden xl:px-20 xl:pt-20">
            <h2 className="text-center font-medium xl:text-4xl lg:text-2xl md:text-2xl text-xl text-black banner-font">
              Sensational software for a sensible price.
            </h2>
            <div className="center-items ">
              <div className="flex">
                <button className="xl:mt-4 lg:mt-8 mt-8 bg-red text-white text-xs banner-font px-8 uppercase py-2 font-semibold">
                  sign up for free
                </button>
                <button className="xl:mt-4 lg:mt-8 mt-8 bg-button text-white text-xs banner-font px-8 uppercase py-2 font-semibold ml-2">
                  see all features
                </button>
              </div>
            </div>
            <h2 className="xl:pt-12 text-center font-bold xl:text-4xl lg:text-2xl md:text-2xl text-xl text-black banner-font">
              Maximum productivity with minimum use
            </h2>
            <p className="xl:px-52 lg:px-56 md:px-32 px-4 xl:mt-3 text-center xl:text-base1 text-base text-black banner-font">
              Providing personalized experiences is what keeps customers around,
              and Zoho CRM can help you do just that. With the power of
              omnichannel presence, segmentation, predictive intelligence, and
              more, you can build and maintain great customer relationships.
            </p>
            <a href="">
              <p className="xl:px-52 text-sky-blue lg:px-56 md:px-32 px-4 xl:mt-3 text-center xl:text-base1 text-base text-black banner-font">
                How Zoho CRM can help build better relationships ➜
              </p>
            </a>
            <div className="center-items">
              <img src={ss1} className="xl:w-12/12" />
            </div>
          </div>
        </div>
        <div className="bg-blue">
          <div className="container mx-auto overflow-hidden xl:px-20 xl:py-20">
            <h2 className="text-center font-bold xl:text-4xl lg:text-2xl md:text-2xl text-xl text-white banner-font">
              Deep analytics for great ROI
            </h2>
            <p className="xl:px-52 lg:px-56 md:px-32 px-4 xl:mt-3 text-center xl:text-base1 text-base text-white banner-font">
              Zoho CRM comes equipped with advanced analytics tools that offer
              meaningful insights so you can make informed decisions. Create
              custom reports and dashboards and measure operations in real time
              to stay on top of your business performance. Our in-house AI
              assistant, Zia, offers accurate predictions and next steps to be
              taken for each lead.
            </p>
            <a href="">
              <p className="xl:px-52 lg:px-56 md:px-32 px-4 xl:mt-3 text-center xl:text-base1 text-base text-white banner-font">
                How to get the in-depth insights with Zoho CRM ➜
              </p>
            </a>
            <ImageSection />

            <h2 className="xl:pt-16 text-center font-bold xl:text-4xl lg:text-2xl md:text-2xl text-xl text-white banner-font">
              Customize, upscale, and deploy with ease
            </h2>
            <p className="xl:px-52 lg:px-56 md:px-32 px-4 xl:mt-3 text-center xl:text-base1 text-base text-white banner-font">
              Zoho CRM is yours—and we mean it. With a dedicated admin panel,
              state-of-the-art customization, hassle-free migration, consultant
              support, and a powerful developer platform, you can truly use Zoho
              CRM to the fullest.
            </p>
            <a href="">
              <p className="xl:px-52 lg:px-56 md:px-32 px-4 xl:mt-3 text-center xl:text-base1 text-base text-white banner-font">
                How Zoho CRM can be truly yours ➜
              </p>
            </a>
            {/* <div ref={sectionRef} className="flex xl:pt-12">
              <img src={img8} alt="Image 8" />
              {img9Visible && <img src={img9} alt="Image 9" />}
            </div> */}
          </div>
        </div>
        <div
          style={{
            backgroundImage: `url(${shot})`,
            backgroundSize: "cover",
          }}
        >
          <div className="container mx-auto overflow-hidden px-4 xl:pt-12 xl:pb-52 xl:px-12 lg:py-8 py-6">
            <div className="grid grid-cols-2 gap-4 items-center xl:pl-12">
              <div className="xl:ml-8 slide-from-right">
                <h2 className="text-left font-medium xl:text-4xl lg:text-2xl md:text-2xl text-xl text-black banner-font">
                  Integrate Zoho CRM with over 800+ apps
                </h2>
                <p className="xl:mt-3 xl:pb-4 text-left xl:text-base1 text-base text-black leading-relaxed headlineheightt banner-font">
                  Zoho CRM works seamlessly with your favorite apps–or find the
                  right app for your needs at Zoho Marketplace
                </p>
                <div className=" xl:mt-4 lg:mt-6  text-gray">
                  <button className="banner-font border-white bg-button xl:px-10 px-6 py-2 text-white">
                    Get started ➝
                  </button>
                </div>
              </div>
              <div className="xl:ml-20 xl:mt-8 slide-from-left">
                <img
                  src="https://i.ibb.co/tcW9NvZ/smoother-operations-integration-1x.webp"
                  className="xl:w-104 rounded-xll"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="bg-grey">
          <div className="container mx-auto overflow-hidden px-4 xl:pt-16 xl:pb-52 lg:py-8 py-6">
            <div className="grid md:grid-cols-3 xl:gap-8 grid-cols-1 xl:px-20">
              <div>
                <h1 className="text-left font-medium xl:text-4xl lg:text-2xl md:text-2xl text-xl text-black banner-font">
                  Why choose Zoho CRM?
                </h1>
              </div>
              <div>
                <p className="text-black xl:text-base1">
                  ✓ Refreshingly simple to use—a modern CRM for a modern
                  business
                </p>
                <p className="text-black xl:text-base1 pt-4">
                  ✓ Extensive developer platform for custom solutions and
                  integrations
                </p>
                <p className="text-black xl:text-base1 pt-4">
                  ✓ No hidden costs—we don't nickel and dime features
                </p>
              </div>
              <div>
                <p className="text-black xl:text-base1">
                  ✓ Dedicated programs for migration, deployment, and training
                </p>
                <p className="text-black xl:text-base1 pt-4">
                  ✓ Flexible contracts with no lock-in periods
                </p>
                <p className="text-black xl:text-base1 pt-4">
                  ✓ Price protection–get the best deal, always
                </p>
              </div>
            </div>
            <div className="rounded-xl bg-button xl:h-520px xl:p-12 xl:mt-12 xl:px-12">
              <div className="grid md:grid-cols-2 xl:gap-8 grid-cols-1">
                <div>
                  <p className="banner-font text-white xl:text-3xl">
                    Zoho CRM helps intelligent cloud management platform offer
                    better cloud visibility infrastructure to customers
                  </p>
                  <img
                    src="https://i.ibb.co/PZ3BkgB/faisal-saleh-centilytics1x.webp"
                    className="mt-4 rounded-xl"
                  />
                </div>
                <div>
                  <div className="bg-white shadow-xl rounded-xll">
                    <div className="xl:p-12 ">
                      <p className="banner-font text-black xl:text-3xl font-semibold">
                        The complete CRM for modern enterprises.
                      </p>
                      <div>
                        <p className="text-black xl:text-base1 pt-8">
                          ✓ Dedicated programs for migration, deployment, and
                          training
                        </p>
                        <p className="text-black xl:text-base1 pt-4">
                          ✓ Flexible contracts with no lock-in periods
                        </p>
                        <p className="text-black xl:text-base1 pt-4">
                          ✓ Price protection–get the best deal, always
                        </p>
                      </div>
                      <button className="bg-button px-6 text-white mt-4 py-2 banner-font ">
                        learn more
                      </button>
                    </div>
                    <div className="flex justify-between">
                      <img />
                      <img src={building} className="xl:w-10/12" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="xl:mt-12 xl:pb-20">
              <h2 className="text-center font-medium xl:text-4xl lg:text-2xl md:text-2xl text-xl text-black banner-font">
              Improved Sales. Lasting Relationships. Faster Growth.
              </h2>
              <p className="xl:text-base1 text-gray banner-font text-center pt-3">15-day free trial. No credit card required.</p>
              <div className="center-items ">
                <div className="flex">
                  <button className="xl:mt-4 lg:mt-8 mt-8 bg-red text-white text-xs banner-font px-8 uppercase py-2 font-semibold">
                    sign up for free
                  </button>
                  <button className="xl:mt-4 lg:mt-8 mt-8 bg-button text-white text-xs banner-font px-8 uppercase py-2 font-semibold ml-2">
                    see all features
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer2 />
    </>
  );
}
