import React, { useEffect, useRef, useState } from "react";
import elegantbgsection from "assets/img/elegantbgsection.jpg";
import productdesign from "assets/img/productdesign.png";
import success from "assets/img/success.png";
import futureresearch from "assets/img/futuresresearch.png";

const HoveredImage = ({ fixedImageSrc }) => {
  return (
    <div className="md:mt-0  my-4">
      {fixedImageSrc && (
        <>
          {fixedImageSrc.text && (
            <h1 className="text-left uppercase xl:text-base1 text-base text-blue leading-relaxed font-semibold  banner-font">
              {fixedImageSrc.text}
            </h1>
          )}
          {fixedImageSrc.text1 && (
            <p className="xl:pt-32 text-left font-medium xl:text-4xl lg:text-2xl md:text-2xl text-xl text-black banner-font">
              {fixedImageSrc.text1}
            </p>
          )}
          {fixedImageSrc.image && (
            <img
              src={fixedImageSrc.image}
              alt="Hovered Image"
              className="xl:w-max xl:h-max xl:mt-0 md:mt-4 ml-4 md:ml-0 md:mr-4"
            />
          )}
        </>
      )}
    </div>
  );
};

export default function HoverChange() {
  /* scrolldown slowly coming animation start */

  const sections = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("animate");
            observer.unobserve(entry.target); // Stop observing the section once it's animated
          }
        });
      },
      { threshold: 0.7 }
    );

    sections.current.forEach((section) => {
      if (section && section instanceof Element) {
        observer.observe(section);
      }
    });

    const handleScroll = debounce(() => {
      sections.current.forEach((section) => {
        if (section && section instanceof Element) {
          observer.observe(section);
        }
      });
    }, 100);

    window.addEventListener("scroll", handleScroll);

    return () => {
      observer.disconnect();
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function debounce(func, delay) {
    let timeout;
    return function (...args) {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(context, args), delay);
    };
  }

  /* scrolldown slowly coming animation end */

  const [fixedImageSrc, setFixedImageSrc] = useState({
    // text: "OTHER SERVICES",
    text1: "Vision",
    image: success,
  });
  const [hoveredBox, setHoveredBox] = useState(null);

  const handleBoxHover = (box) => {
    setHoveredBox(box);

    switch (box) {
      case "passionDriven":
        setFixedImageSrc({
          // text: "Strategies & Business",
          text1: "Our Vision",
          image: success,
        });
        break;
      case "customerCentric":
        setFixedImageSrc({
          // text: "Product Design",
          text1: "Our Mission",
          image: productdesign,
        });
        break;
      case "transparentApproach":
        setFixedImageSrc({
          // text: "Futures Research",
          text1: "Our Value",
          image: futureresearch,
        });
        break;
      // Add cases for other options as needed
    }
  };

  return (
    <>
      <section
        ref={(el) => sections.current.push(el)}
        style={{
          backgroundImage: `url(${elegantbgsection})`,
          width: "100%",
          backgroundSize: "cover",
        }}
        className="animate-on-scroll"
      >
        <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 xl:py-16 md:py-8">
          <div className="grid xl:grid-cols-2 md:grid-cols-2 ">
            <div className="grid grid-cols-1 xl:gap-4 md:gap-2 gap-2 xl:mb-0 mb-8">
              <div
                className={`cursor-pointer passionBox bg-white xl:w-12/12 xl:mt-4 items-center shadow-md rounded-md border-none xl:p-4 xl:h-36 ${
                  hoveredBox === "passionDriven" && "hovered"
                }`}
                onMouseEnter={() => handleBoxHover("passionDriven")}
                onMouseLeave={() => setHoveredBox(null)}
              >
                <div className="flex">
                  <div className="xl:mr-4 ">
                    <img
                      src="https://i.ibb.co/swZBVXz/4tg4.png"
                      className="xl:w-16 xl:mt-2"
                    />
                  </div>
                  <div className="xl:w-10/12">
                    <p className="xl:pt-0 xl:text-xl banner-font text-black font-medium text-left">
                      Our Vision
                    </p>
                    <p className="xl:pt-1 text-left xl:text-base1 text-base  text-gray leading-relaxed  banner-font">
                      We provide innovative, consumer-led, adaptable software to
                      build solutions that offer your audience an impeccable,
                      user-friendly, and fascinating digital experience driven
                      by dynamic digital experience driven by dynamic.
                    </p>
                  </div>
                </div>
              </div>

              <div
                className={`cursor-pointer passionBox bg-white  xl:w-12/12 items-center shadow-md rounded-md border-none xl:p-4 xl:h-36  ${
                  hoveredBox === "customerCentric" && "hovered"
                }`}
                onMouseEnter={() => handleBoxHover("customerCentric")}
                onMouseLeave={() => setHoveredBox(null)}
              >
                <div className="flex">
                  <div className="xl:mr-4 ">
                    <img
                      src="https://i.ibb.co/LSWHNjd/prgijv.png"
                      className="xl:w-16 xl:mt-2"
                    />
                  </div>
                  <div className="xl:w-10/12">
                    <p className="xl:text-xl banner-font font-medium text-black text-left">
                      Our Mission
                    </p>
                    <p className="xl:pt-1 text-left xl:text-base1 text-base text-gray leading-relaxed  banner-font">
                      We are deliver a high quality services and success for any
                      business. Our designs create websites that ensure an
                      enjoyable user experience, be it in visuality or in
                      functionality be it in visuality or in functionality.
                    </p>
                  </div>
                </div>
              </div>

              <div
                className={`cursor-pointer passionBox bg-white xl:w-12/12 items-center shadow-md rounded-md border-none xl:p-4 xl:h-36  ${
                  hoveredBox === "transparentApproach" && "hovered"
                }`}
                onMouseEnter={() => handleBoxHover("transparentApproach")}
                onMouseLeave={() => setHoveredBox(null)}
              >
                <div className="flex">
                  <div className="xl:mr-4 ">
                    <img
                      src="https://i.ibb.co/2W1JH6k/rgkbg.png"
                      className="xl:w-16 xl:mt-2"
                    />
                  </div>
                  <div className="xl:w-10/12">
                    <p className="xl:text-xl banner-font font-medium text-black text-left">
                      Our Values
                    </p>
                    <p className="xl:pt-1 text-left xl:text-base1 text-base  text-gray leading-relaxed  banner-font">
                      Our Team Understands the need of our clients to generate
                      better results. The great aspect of social media is that a
                      lot of the potential customers are there. We creating
                      brand awareness, having quality leads.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="xl:mt-8 xl:ml-12">
              <HoveredImage fixedImageSrc={fixedImageSrc} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
