import React, { useState, useEffect, useRef } from "react";
import img1 from "assets/img/img1.svg";
import img2 from "assets/img/img2.svg";
import img3 from "assets/img/img3.svg";
import img4 from "assets/img/img4.svg";
import img5 from "assets/img/img5.svg";
import img6 from "assets/img/img6.svg";

const ImageSection = () => {
  const sectionRef = useRef();
  const [visibleImages, setVisibleImages] = useState([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          console.log("Section is visible");

          const timers = [];
          const images = [img1, img2, img3, img4, img5, img6];

          images.forEach((img, index) => {
            const timer = setTimeout(() => {
              console.log(`Showing image ${index + 1}`);
              setVisibleImages((prev) => [...prev, img]);
            }, index * 900);
            timers.push(timer);
          });

          return () => timers.forEach(clearTimeout);
        }
      },
      { threshold: 0.3 } // Adjust the threshold as needed
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <div ref={sectionRef}>
      <div className="xl:w-12/12 flex gap-2 xl:pt-8">
        <div className="xl:w-5/12 rounded-xl image-wrapper">
          {visibleImages.includes(img1) && (
            <img
              src={img1}
              alt="Image 1"
              className="visible animate-slide-upp image-wrapper"
            />
          )}
        </div>
        <div className="xl:w-3/12 rounded-xl image-wrapper">
          {visibleImages.includes(img2) && (
            <img
              src={img2}
              alt="Image 2"
              className="visible animate-slide-upp image-wrapper"
            />
          )}
        </div>
        <div className="xl:w-3/12 rounded-xl image-wrapper">
          {visibleImages.includes(img3) && (
            <img
              src={img3}
              alt="Image 3"
              className="visible animate-slide-upp image-wrapper"
            />
          )}
        </div>
        <div className="xl:w-3/12 rounded-xl image-wrapper">
          {visibleImages.includes(img4) && (
            <img
              src={img4}
              alt="Image 4"
              className="visible animate-slide-upp image-wrapper"
            />
          )}
        </div>
      </div>
      <div className="xl:w-12/12 flex xl:pt-4">
        <div className="xl:w-6/12 rounded-xl image-wrapper">
          {visibleImages.includes(img5) && (
            <img
              src={img5}
              alt="Image 5"
              className="visible xl:h-51 animate-slide-upp image-wrapper"
            />
          )}
        </div>
        <div className="xl:w-7/12 rounded-xl image-wrapper">
          {visibleImages.includes(img6) && (
            <img
              src={img6}
              alt="Image 6"
              className="visible animate-slide-upp image-wrapper"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ImageSection;
