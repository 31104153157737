import React, { useState } from "react";
import Footer2 from "components/Footers/Footer2";
import IndexNavbar2 from "components/Navbars/IndexNavbar2";
import email from "assets/img/email.png";

export default function BlogDetail() {
  return (
    <>
      <IndexNavbar2 fixed />
      <section className="bg-grey">
        <div className="container xl:pt-48 xl:px-12 lg:pt-40 pt-16 mx-auto justify-center flex flex-wrap ">
          <div className="flex xl:w-12/12">
            {/* First section */}
            <div className="xl:w-8/12 xl:p-2 xl:mt-8 overflow-y-auto xl:mb-16">
              <div className="bg-white rounded-md xl:w-12/12 flex border-grey shadow-md">
                <div className="xl:p-8">
                  <h1 className="banner-font xl:text-4xl text-black">
                    Understanding how Links and protocols works
                  </h1>
                  <p className="xl:text-base1 text-gray xl:pt-4 banner-font ">
                    Having not yet visited Sector 10, follow these steps for a
                    free upgrade.
                  </p>
                  <img
                    src="https://i.ibb.co/sPFQz29/lorenzo-herrera-VFi-Qv-ZPlm2k-unsplash-1.jpg"
                    className="rounded-md xl:w-56 mt-4"
                  />

                  <p className="headlineheightt xl:text-base1 text-gray xl:pt-4 xl:pr-8">
                    Within a web browser, the ability to move from one document
                    to another is facilitated by links. These links consist of
                    two main parts: the protocol and server address, followed by
                    the document path.
                  </p>

                  <p className="headlineheightt xl:text-base1 text-gray xl:pt-4 xl:pr-8">
                    A federal government initiated report conducted by the Allen
                    Consulting Group released in July 2011 proposed, amongst
                    other detail, various standards of reporting criteria
                    ranging from voluntary to a comprehensive evaluation
                    conducted by qualified energy rating assessors.
                  </p>

                  <p className="headlineheightt xl:text-base1 text-gray xl:pt-4 xl:pr-8">
                    There were a lot of cut outs in the waists of gowns at the
                    Critics’ Choice Awards and there were mostly chic and fun
                    with a little peak of skin. This is not a little peak.
                  </p>

                  <div className="flex xl:mt-8">
                    <div>
                      <img
                        src="https://i.ibb.co/jMYQPyQ/quote-7350737-removebg-preview.png"
                        className="mt-1 xl:w-16"
                      />
                    </div>
                    <div>
                      <p className="text-black xl:text-2xl banner-font pl-4">
                        Only a quarter of young adults are financially literate.
                        You don’t want to overwhelm them with terrible advice.
                        Best to keep them on track.
                      </p>
                    </div>
                  </div>
                  <p className="text-gray text-xs mt-4 uppercase font-medium banner-font xl:ml-12">
                    - Jothae joe
                  </p>

                  <h1 className="banner-font xl:text-xl xl:pt-8 text-black">
                    Anatomy of a Link
                  </h1>
                  <p className="headlineheightt xl:text-base1 text-gray xl:pt-2 xl:pr-8">
                    A link is essentially composed of two distinct segments. The
                    <b> first part </b>determines the <b> protocol</b> and
                    server address, which can be specified either through a
                    <b> domain name </b> or an <b>IP address </b>. The second
                    part represents the document path appended to the address.
                    For instance, consider the following document address:
                  </p>

                  <h1 className="banner-font xl:text-xl xl:pt-8 text-black">
                    Breaking it down:
                  </h1>
                  <ul className="xl:pt-2">
                    <li className="flex banner-font xl:text-base1 text-gray xl:pt-2">
                      <b className="xl:mr-2">• </b>
                      <p>
                        <b className="pr-2 xl:text-base text-black">
                          Protocol (https):
                        </b>
                        Specifies the communication protocol to be used. In this
                        case, it’s HyperText Transfer Protocol Secure, denoted
                        by “https.”
                      </p>
                    </li>
                    <li className="flex banner-font xl:text-base1 text-gray xl:pt-2">
                      <b className="xl:mr-2">•</b>
                      <p>
                        <b className="pr-2 xl:text-base text-black">
                          Domain Name (estudiopatagon.com):
                        </b>
                        Identifies the server’s location using a human-readable
                        domain name. It points to the server that hosts the
                        website.
                      </p>
                    </li>
                    <li className="flex banner-font xl:text-base1 text-gray xl:pt-2">
                      <b className="xl:mr-2">• </b>
                      <p>
                        <b className="pr-2 xl:text-base banner-font text-black">
                          Document URL (/contact/):
                        </b>
                        Represents the specific path of the document relative to
                        the server’s root path. It guides the server to the
                        exact location of the requested content.
                      </p>
                    </li>
                  </ul>

                  <h1 className="banner-font xl:text-xl xl:pt-8 text-black">
                    The Role of the Web Server
                  </h1>
                  <p className="headlineheightt xl:text-base1 text-gray xl:pt-2 xl:pr-8">
                    Once a link is clicked, the web browser sends a request to
                    the specified server using the provided protocol, domain
                    name, and document path. The web server, in turn, is
                    responsible for interpreting this request.
                  </p>
                  <p className="headlineheightt xl:text-base1 text-gray xl:pt-4 xl:pr-8">
                    The server analyzes the request, extracts the document path,
                    and searches its directory structure for the corresponding
                    file.
                  </p>
                  <img
                    src="https://i.ibb.co/QnXwSQL/photo-1577648188599-291bb8b831c3.jpg"
                    className="rounded-md xl:mt-8 w-full"
                  />

                  <h1 className="banner-font xl:text-xl xl:pt-8 text-black">
                    Conclusion
                  </h1>
                  <p className="headlineheightt xl:text-base1 text-gray xl:pt-2 xl:pr-8">
                    In essence, the web server acts as the interpreter and
                    provider, ensuring that the correct response is delivered
                    based on the user’s request. This seamless interaction
                    between the browser and the server is fundamental to the
                    functionality of the World Wide Web.
                  </p>
                  <p className="headlineheightt xl:text-base1 text-gray xl:pt-4 xl:pr-8">
                    As we continue our exploration, we’ll delve deeper into the
                    intricacies of web development, understanding how links,
                    protocols, and servers collaborate to deliver the web
                    content we interact with daily.
                  </p>
                </div>
              </div>

              {/* <div className="bg-white rounded-md xl:mt-8  border-grey shadow-md xl:w-12/12 xl:p-12">
                <div className="center-items">
                  <div className="flex flex-wrap">
                    <p className="banner-font mr-4 ">Share Article</p>
                    <a
                      href="https://www.facebook.com/elegantcarve2022"
                      target="_blank"
                    >
                      <i className="text-blue fab fa-facebook text-lg leading-lg px-2" />
                    </a>
                    <a
                      href=" https://twitter.com/elegantcarve?lang=en"
                      target="_blank"
                    >
                      <i className="text-blue fab fa-twitter text-lg leading-lg px-2" />
                    </a>
                    <a
                      href=" https://instagram.com/elegantcarvetechnologies?igshid=YmMyMTA2M2Y="
                      target="_blank"
                    >
                      <i className="text-blue fab fa-instagram-square text-lg leading-lg px-2" />
                    </a>
                    <a
                      href=" https://in.linkedin.com/company/elegantcarve"
                      target="_blank"
                    >
                      <i className="text-blue fab fa-linkedin text-lg leading-lg px-2" />
                    </a>
                    <a
                      href=" https://in.pinterest.com/elegantcarve/"
                      target="_blank"
                    >
                      <i className="text-blue fab fa-pinterest text-lg leading-lg px-2" />
                    </a>
                  </div>
                </div>
                <div className="flex">
                  <input
                    type="search"
                    placeholder="https://themes.estudiopatagon.com/wordpress/zento/vertical/"
                    className="xl:px-4 banner-font  text-gray bg-white shadow-md rounded-l-md xl:mt-4 xl:w-max border xl:h-12"
                  />
                  <button className="xl:m-1 blue-gradient shadow-xl text-white rounded-r-md xl:px-4 xl:mt-4 xl:h-12 xl:py-1 banner-font xl:text-sm font-semibold">
                    Copy Link !
                  </button>
                </div>
              </div> */}

              <div className="bg-white rounded-md xl:mt-4 border-grey shadow-md xl:w-12/12 xl:p-8">
                <div className="flex xl:w-12/12">
                  <div className="xl:w-1/12 xl:-mt-2">
                    <img
                      src="https://i.ibb.co/pLP1dxc/code-icon.png"
                      className="rounded-full bg-blue xl:p-1 xl:w-12"
                    />
                  </div>
                  <div className="xl:w-8/12 xl:-mt-2">
                    <p className="banner-font xl:text-base1">
                      More in this Category
                    </p>
                    <p className="banner-font xl:text-xl">Fundamentals</p>
                  </div>
                  <div className="xl:w-4/12">
                    <button className="xl:ml-20 bg-button xl:text-sm xl:px-4 xl:py-2 banner-font text-white">
                      View All Articles
                    </button>
                  </div>
                </div>
                <div className="flex xl:mt-8 border-b xl:pb-3">
                  <p className="rounded-md text-sm bg-orange xl:w-8 font-semibold text-center text-white xl:p-1">
                    1
                  </p>
                  <p className="text-gray banner-font xl:text-base1 xl:pl-3">
                    HTTP, Web Browsers, and Web Servers
                  </p>
                </div>

                <div className="flex xl:mt-4 border-b xl:pb-3">
                  <p className="rounded-md text-sm bg-black xl:w-8 font-semibold text-center text-white xl:p-1">
                    2
                  </p>
                  <p className="text-gray banner-font xl:text-base1 xl:pl-3">
                    Unveiling the Web Browser: Gateway to the World Wide Web
                  </p>
                </div>

                <div className="flex xl:mt-4 border-b xl:pb-3">
                  <p className="rounded-md text-sm bg-sky-blue xl:w-8 font-semibold text-center text-white xl:p-1">
                    3
                  </p>
                  <p className=" text-gray banner-font xl:text-base1 xl:pl-3">
                    Introduction to CSS 🚀
                  </p>
                </div>
              </div>

              <div className="bg-white rounded-md xl:mt-4 border-grey shadow-md xl:w-12/12 xl:p-8">
                <h3 className="banner-font text-black xl:text-base1">
                  Leave a Reply
                </h3>
                <textarea
                  className="w-full border-grey rounded-md border-on-hover xl:mt-6"
                  placeholder="comment"
                />
                <div className="grid xl:grid-cols-2 xl:gap-2 xl:pt-1">
                  <input
                    type="text"
                    className="border-grey border-on-hover rounded-md"
                    placeholder="Name"
                  />
                  <input
                    type="email"
                    className="border-grey border-on-hover rounded-md"
                    placeholder="Name"
                  />
                </div>
                <div className="grid xl:grid-cols-1 xl:gap-2 xl:pt-2">
                  <input
                    type="text"
                    className="border-grey border-on-hover rounded-md"
                    placeholder="Website"
                  />
                </div>
                <button className="bg-button xl:mt-8 xl:text-sm xl:px-8 xl:py-2 banner-font text-white">
                  Post Comment
                </button>
              </div>
              <div className="bg-white rounded-md xl:mt-4 border-grey shadow-md xl:w-12/12 xl:p-8">
                <div className="flex justify-between">
                  <p className="banner-font text-gray xl:text-base1 pb-4">
                    Share
                  </p>
                  <div class="goodshare-color">
                    <a href="https://x.com/" data-type="tw">
                      <i className="bg-sky-blue text-white rounded px-8 py-11">
                        𝕏
                      </i>
                    </a>
                    <a href="https://www.facebook.com/" data-type="fb">
                      <i className="fab fa-facebook-f bg-blue text-white rounded px-8 py-2 ml-1"></i>
                    </a>
                    <a href="https://www.pinterest.com/" data-type="pt">
                      <i className="fab fa-pinterest bg-red text-white rounded px-8 py-2 ml-1"></i>
                    </a>
                    <a href="https://www.linkedin.com/" data-type="li">
                      <i className="fab fa-linkedin-in bg-blue text-white rounded px-8 py-2 ml-1"></i>
                    </a>
                    <a href="https://www.tumblr.com/" data-type="bl">
                      <i className="fab fa-tumblr bg-dark-gray text-white rounded px-8 py-2 ml-1"></i>
                    </a>
                    <a href="https://www.whatsapp.com/" data-type="di">
                      <i className="fab fa-whatsapp bg-green text-white rounded px-8 py-2 ml-1"></i>
                    </a>
                    <a href="https://telegram.org/" data-type="vk">
                      <i className="fab fa-telegram-plane bg-sky-blue text-white rounded px-8 py-2 ml-1"></i>
                    </a>
                  </div>
                </div>
                <div className="grid grid-cols-2 mt-4">
                  <div className="border-r xl:mt-4 pr-8 cursor-pointer">
                    <p className="uppercase font-semibold text-blue text-xs banner-font text-right">
                      ≪ previous article
                    </p>
                    <h1 className="banner-font header-on-hover xl:text-sm text-right pt-2 text-black">
                      Airlines Face Billions in Losses As COVID will Wipe Out
                      Even More Flights
                    </h1>
                  </div>
                  <div className="xl:mt-4 xl:pl-8 cursor-pointer ">
                    <p className="uppercase font-semibold text-blue text-xs banner-font text-left">
                      next article ≫
                    </p>
                    <h1 className="banner-font header-on-hover xl:text-sm text-left pt-2 text-black">
                      Coronavirus: Health Staff Braced for Expected Covid-19
                      Surge
                    </h1>
                  </div>
                </div>
              </div>
            </div>
            {/* Second section */}
            <div className="xl:w-4/12 sticky top-100 xl:h-1500">
              <h1 className="banner-font xl:text-lg text-black">
                Article Information
              </h1>
              <div className="xl:pl-8 xl:py-6 bg-white xl:mt-4 rounded-md  border-grey shadow-md">
                <div className="text-left">
                  <div>
                    <img
                      src="https://i.ibb.co/8zgvB2F/GOODNEWS-AD-SP-01-2-X.webp"
                      className="pr-8"
                    />
                  </div>
                  <div className="xl:text-sm banner-font mt-8">
                    📅 Category: Fundamentals
                  </div>
                  <div className="xl:pt-4 xl:text-sm banner-font ">
                    ⬇️ Updated: Mar 27, 2024
                  </div>
                  <div className="xl:pt-4 xl:text-sm banner-font ">
                    🥇 Author: Jonathan Doe
                  </div>
                  <div className="xl:pt-4 xl:text-sm banner-font ">
                    ⬇️ Reading time: 1 Min
                  </div>
                </div>

                <div className="xl:mt-8 mt-4 xl:pr-8">
                  <p className="bg-button w-full uppercase banner-font text-white text-xs pl-4 py-1">
                    our picks
                  </p>
                </div>

                <div className="xl:pr-6">
                  <div className="flex cursor-pointer xl:w-12/12 xl:gap-2 xl:mt-8 border-b pb-4">
                    <div className="xl:w-3/12">
                      <img
                        src="https://i.ibb.co/kxBns9G/Depositphotos-437955496-xl-2015-1024x683.webp"
                        className="xl:h-16 xl:w-12/12"
                      />
                    </div>
                    <div className="xl:w-9/12 pl-2">
                      <p className="banner-font text-gray xl:text-base -mt-1 header-on-hover">
                        Remember! Bad Habits That Make a Big Impact on Your
                        Lifestyle
                      </p>
                      <p className="banner-font text-xs text-gray pt-2">
                        Jan 13, 2021
                      </p>
                    </div>
                  </div>

                  <div className="flex cursor-pointer xl:w-12/12 xl:gap-2 xl:mt-4 border-b pb-4">
                    <div className="xl:w-3/12">
                      <img
                        src="https://i.ibb.co/xSPPHC9/pexels-karolina-grabowska-4466208-1-1167x1536.webp"
                        className="xl:h-16 xl:w-12/12"
                      />
                    </div>
                    <div className="xl:w-9/12 pl-2">
                      <p className="banner-font text-gray xl:text-sm -mt-1 header-on-hover">
                        The Right Morning Routine Can Keep You Energized & Happy
                      </p>
                      <p className="banner-font text-xs text-gray pt-2">
                        Jan 13, 2021
                      </p>
                    </div>
                  </div>

                  <div className="flex cursor-pointer xl:w-12/12 xl:gap-2 xl:mt-4 border-b pb-4">
                    <div className="xl:w-3/12">
                      <img
                        src="https://i.ibb.co/frWLjFn/Depositphotos-295262448-xl-2015-1024x683.webp"
                        className="xl:h-16 xl:w-12/12"
                      />
                    </div>
                    <div className="xl:w-9/12 pl-2">
                      <p className="banner-font text-gray xl:text-sm -mt-1 header-on-hover">
                        How to Make Perfume Last Longer Than Before
                      </p>
                      <p className="banner-font text-xs text-gray pt-2">
                        Jan 13, 2021
                      </p>
                    </div>
                  </div>

                  <div className="flex cursor-pointer xl:w-12/12 xl:gap-2 xl:mt-4">
                    <div className="xl:w-3/12">
                      <img
                        src="https://i.ibb.co/CKQ7YsR/1-x1-VVin4-ERPm-JPBhi-XTft-DSC0927-1030x579.webp"
                        className="xl:h-16 xl:w-12/12"
                      />
                    </div>
                    <div className="xl:w-9/12 pl-2">
                      <p className="banner-font text-gray xl:text-sm -mt-1 header-on-hover">
                        Stay off Social Media and Still Keep an Online Social
                        Life
                      </p>
                      <p className="banner-font text-xs text-gray pt-2">
                        Jan 13, 2021
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  // backgroundImage: `url(${email})`,
                  backgroundSize: "cover",
                }}
                className=" xl:py-6 bg-white xl:mt-4 rounded-md  border-grey shadow-md"
              >
                <div className="center-items px-8">
                  <h1 className="text-black banner-font font-semibold xl:text-lg">
                    Subscribe to Updates
                  </h1>
                  <p className="banner-font mt-4 text-gray xl:text-sm">
                    Get the latest creative news from SmartMag about art &
                    design.
                  </p>
                  <input
                    type="text"
                    placeholder="Your mail address"
                    className="border-grey border-on-hover mt-4 text-center rounded-md w-12/12"
                  />
                  <button className="bg-button xl:mt-4 xl:text-sm xl:px-8 xl:py-2 banner-font text-white">
                    Post Comment
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer2 />
    </>
  );
}
