import React, { useEffect, useRef } from "react";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer2 from "components/Footers/Footer2";
import elegantbgsection from "assets/img/elegantbgsection.jpg";
import bglast from "assets/img/bglast.png";
import IndexNavbar2 from "components/Navbars/IndexNavbar2";

export default function Service() {
  /* scrolldown slowly coming animation start */

  const sections = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("animate");
            observer.unobserve(entry.target); // Stop observing the section once it's animated
          }
        });
      },
      { threshold: 0.4 }
    );

    sections.current.forEach((section) => {
      if (section && section instanceof Element) {
        observer.observe(section);
      }
    });

    const handleScroll = debounce(() => {
      sections.current.forEach((section) => {
        if (section && section instanceof Element) {
          observer.observe(section);
        }
      });
    }, 100);

    window.addEventListener("scroll", handleScroll);

    return () => {
      observer.disconnect();
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function debounce(func, delay) {
    let timeout;
    return function (...args) {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(context, args), delay);
    };
  }

  /* scrolldown slowly coming animation end */

  return (
    <>
      <IndexNavbar2 fixed />
      <section
        ref={(el) => sections.current.push(el)}
        className="bg-white animate-on-scroll"
      >
        <div className="container mx-auto overflow-hidden px-4 xl:pt-44 xl:px-12 lg:py-8 py-6">
          <div className="grid grid-cols-2 gap-4 items-center xl:pl-12">
            <div className="xl:ml-8 slide-from-right">
              <h2 className="text-left font-medium xl:text-4xl lg:text-2xl md:text-2xl text-xl text-black banner-font">
                Your Website is Always Up
              </h2>
              <p className="xl:mt-3 xl:pb-4 text-left xl:text-base1 text-base text-gray leading-relaxed headlineheightt banner-font">
                We offer a wide range of cloud hosting solutions to give
                business flexibility and choice in how website hosted. Do
                everything easily with our platform.
              </p>
              <div className=" xl:mt-4 lg:mt-6  text-gray">
                <button className="bg-white banner-font border-blue bg-button xl:px-10 px-6 py-2 text-white border-white">
                  Get started ➝
                </button>
              </div>
            </div>
            <div className="xl:ml-20 slide-from-left">
              <img
                src="https://i.ibb.co/qyvYFjZ/ejfnkjf.png"
                className="xl:w-104 rounded-xll"
              />
            </div>
          </div>
        </div>
      </section>

      <section
        ref={(el) => sections.current.push(el)}
        className="bg-gray animate-on-scroll"
      >
        <div className="container mx-auto overflow-hidden px-4 xl:py-16 lg:py-8 py-6">
          <h2 className="xl:px-72 text-center font-medium xl:text-4xl lg:text-2xl md:text-2xl text-xl text-black banner-font">
            The Most Powerful Hosting Platform
          </h2>
          <p className="xl:px-84 lg:px-56 md:px-32 px-4 xl:mt-2 text-center xl:text-lg text-base text-gray headlineheightt banner-font">
            We offer a wide range of hosting solutions, from web hosting to
            dedicated servers. Everything you need is right here.
          </p>
          <div className="xl:pt-8 xl:gap-4 xl:px-32 grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-4 grid-cols-1">
            <a href="">
              <div className="bg-white border rounded-md shadow-xl center-items xl:p-12">
                <img
                  src="https://i.ibb.co/KyMzyP4/thumb-uphbh.png"
                  className="xl:w-16"
                />
                {/* <div className="xl:w-24 lg:w-20 border-t-blue xl:pb-6" /> */}
                <p className="xl:pt-6 xl:text-lg banner-font text-black font-medium text-center header-on-hover">
                  Easy to Use
                </p>
                <p className="xl:pt-1 text-center xl:text-base1 text-base  text-gray leading-relaxed headlineheight banner-font">
                  We offer a wide range of hosting solutions, from web hosting
                  to dedicated servers.
                </p>
              </div>
            </a>
            <a href="">
              <div className="bg-white border rounded-md shadow-xl center-items xl:p-12">
                <img
                  src="https://i.ibb.co/bdkvBsW/time-managementhk.png"
                  className="xl:w-16"
                />
                {/* <div className="xl:w-24 lg:w-20  border-t-orange xl:pb-6" /> */}
                <p className="xl:pt-6  xl:text-lg banner-font  text-black font-medium text-center header-on-hover">
                  Fast & Reliable
                </p>
                <p className="xl:pt-1 text-center xl:text-base1 text-base  text-gray leading-relaxed headlineheight banner-font">
                  We offer a wide range of hosting web hosting and web servers
                  to dedicated servers.
                </p>
              </div>
            </a>
            <a href="">
              <div className="bg-white border rounded-md shadow-xl center-items xl:p-12">
                <img
                  src="https://i.ibb.co/09WM628/customer-service.png"
                  className="xl:w-16"
                />
                {/* <div className="xl:w-24 lg:w-20  border-t-red xl:pb-6" /> */}
                <p className="xl:pt-6  xl:text-lg banner-font text-black font-medium text-center header-on-hover">
                  24/7 Support
                </p>
                <p className="xl:pt-1 text-center xl:text-base1 text-base  text-gray leading-relaxed headlineheight banner-font">
                  We offer a wide range of hosting solutions, from web hosting
                  to dedicated servers.
                </p>
              </div>
            </a>
          </div>
        </div>
      </section>

      <section
        ref={(el) => sections.current.push(el)}
        className="bg-white animate-on-scroll"
      >
        <div className="container mx-auto overflow-hidden px-4 xl:pt-16 xl:pb-16 xl:px-12 lg:py-8 py-6">
          <div className="grid grid-cols-2 gap-4 items-center xl:pl-12">
            <div className="xl:ml-8">
              <h2 className="xl:pr-40 xl:pt-4 text-left font-medium xl:text-4xl lg:text-2xl md:text-2xl text-xl text-black  banner-font">
                Specialized platforms for web development
              </h2>
              <p className="xl:mt-3 xl:pb-4 text-left xl:text-lg text-base text-gray leading-relaxed headlineheightt banner-font">
                We offer a wide range of cloud hosting solutions to give
                business flexibility and choice in how website hosted. Do
                everything easily with our platform.
              </p>
              <div className=" xl:mt-4 lg:mt-6  text-gray">
                <button className="bg-white banner-font border-blue bg-button xl:px-10 px-6 py-2 text-white border-white">
                  Get started ➝
                </button>
              </div>
            </div>
            <div className="xl:ml-20">
              <img
                src="https://i.ibb.co/Lh6grqb/offer.png"
                className="xl:w-104 rounded-xll"
              />
            </div>
          </div>
        </div>
      </section>

      <section
        ref={(el) => sections.current.push(el)}
        style={{
          // backgroundImage: `url(${elegantbgsection})`,
          backgroundColor: "#f8f9fb",
          width: "100%",
          backgroundSize: "cover",
        }}
        className="animate-on-scroll"
      >
        <div className="container mx-auto overflow-hidden px-4 xl:py-12 xl:px-12 lg:py-8 py-6">
          <div className="grid grid-cols-2 xl:px-7">
            <div>
              <h2 className="xl:pt-4 text-left font-medium xl:text-4xl lg:text-2xl md:text-2xl text-xl text-black banner-font">
                Hire professionals to get all the work done
              </h2>
            </div>
            <div>
              <p className="xl:mt-4 xl:pb-4 text-left xl:text-lg text-base text-gray leading-relaxed headlineheightt  banner-font">
                We offer a wide range of cloud hosting solutions to give
                business flexibility and choice in how website hosted. Do
                everything easily with our platform.
              </p>
            </div>
          </div>
          <div className="grid grid-cols-2 xl:gap-4 xl:pt-4">
            <div className="xl:ml-20 xl:mt-12">
              <img
                src="https://i.ibb.co/7VdZdJm/elegantlaptop.png"
                className="xl:w-104 rounded-xll"
              />
            </div>
            <div>
              <div className="bg-white flex xl:w-12/12 xl:mt-4 items-center shadow-md rounded-md border-none xl:p-4">
                <div className="xl:mr-4 ">
                  <img
                    src="https://i.ibb.co/swZBVXz/4tg4.png"
                    className="xl:w-16"
                  />
                </div>
                <div className="xl:w-10/12">
                  <p className="xl:pt-2 xl:text-xl banner-font text-black font-medium text-left">
                    Web design
                  </p>
                  <p className="xl:pt-1 xl:pb-3 text-left xl:text-base1 text-base  text-gray leading-relaxed  banner-font">
                    Begin by comprehensively understanding your business needs
                    to ensure a perfect fit for tech solutions.
                  </p>
                </div>
              </div>

              <div className="bg-white flex xl:w-12/12 xl:mt-4 items-center shadow-md rounded-md border-none xl:p-4">
                <div className="xl:mr-4 ">
                  <img
                    src="https://i.ibb.co/LSWHNjd/prgijv.png"
                    className="xl:w-16"
                  />
                </div>
                <div className="xl:w-10/12">
                  <p className="xl:pt-2 xl:text-xl  banner-font font-medium text-black text-left">
                    Web development
                  </p>
                  <p className="xl:pt-1 xl:pb-3 text-left xl:text-base1 text-base  text-gray leading-relaxed  banner-font">
                    Develop Custom Solutions Engage in crafting tailor-made
                    software designed meticulously to align with your specific
                    targets.eady, providing reliable assistance and technical.
                  </p>
                </div>
              </div>
              <div className="bg-white flex xl:w-12/12 xl:mt-4 items-center shadow-md rounded-md border-none xl:p-4">
                <div className="xl:mr-4 ">
                  <img
                    src="https://i.ibb.co/2W1JH6k/rgkbg.png"
                    className="xl:w-16"
                  />
                </div>
                <div className="xl:w-10/12">
                  <p className="xl:pt-2 xl:text-xl  banner-font font-medium text-black text-left">
                    SEO Marketing
                  </p>
                  <p className="xl:pt-1 xl:pb-3 text-left xl:text-base1 text-base  text-gray leading-relaxed  banner-font">
                    Seamlessly integrate the new systems into framework with
                    ongoing support for continual optimization.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        ref={(el) => sections.current.push(el)}
        className="bg-white animate-on-scroll"
      >
        <div className="xl:mx-44 rounded-xll xl:py-12 xl:px-28">
          <div class="container-fluid">
            {/* <h1 class="page-header">Linear Gradients</h1> */}
            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
              <div className="bg-blue rounded-lg">
                <div className="container text-left mx-auto overflow-hidden px-4 xl:py-8 xl:px-12 lg:py-8 py-6">
                  <div className="flex xl:w-12/12">
                    <div className="xl:w-9/12">
                      <h2 className="xl:pt-4 font-medium xl:text-4xl lg:text-2xl md:text-2xl text-xl text-white banner-font">
                        Hire a professional developer for your website
                      </h2>
                      <p className="xl:text-lg xl:pt-2 text-base text-white headlineheightt banner-font">
                        We are happy to assist you.
                      </p>
                    </div>
                    <div className="xl:w-3/12 items-center xl:ml-8 xl:pt-12">
                      <button className="bg-button text-white banner-font xl:px-10 lg:px-6 lg:py-2 xl:py-2 font-medium text-sm">
                        Hire Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        ref={(el) => sections.current.push(el)}
        style={{
          // backgroundImage: `url(${bglast})`,
          // backgroundColor: "#f8f9fb",
          width: "100%",
          backgroundSize: "cover",
        }}
        className="animate-on-scroll"
      >
        <div className="container xl:pt-4 xl:pb-20 lg:pt-40 pt-16 mx-auto justify-center flex flex-wrap ">
          <div className="w-full md:w-9/12 lg:w-8/12 xl:w-88/12 ">
            <h1 className="text-center font-medium xl:text-4xl lg:text-2xl md:text-2xl text-xl text-black banner-font">
              What people say
            </h1>
            <p className="xl:px-44 xl:mt-2 text-center xl:text-base1 text-base  text-gray leading-relaxed headlineheight banner-font">
              We offer a wide range of hosting solutions, from web hosting to
              dedicated servers. Everything you need is right here.
            </p>
            <div className="grid grid-cols-2 gap-4 xl:pt-12">
              <div className="bg-white shadow-md rounded-md xl:p-12">
                <div className="flex gap-1 xl:pr-6">
                  <img
                    src="https://i.ibb.co/K2N8PVv/star-14442199-removebg-preview.png"
                    className="xl:w-4 xl:h-4"
                  />
                  <img
                    src="https://i.ibb.co/K2N8PVv/star-14442199-removebg-preview.png"
                    className="xl:w-4 xl:h-4"
                  />
                  <img
                    src="https://i.ibb.co/K2N8PVv/star-14442199-removebg-preview.png"
                    className="xl:w-4 xl:h-4"
                  />
                  <img
                    src="https://i.ibb.co/K2N8PVv/star-14442199-removebg-preview.png"
                    className="xl:w-4 xl:h-4"
                  />
                  <img
                    src="https://i.ibb.co/K2N8PVv/star-14442199-removebg-preview.png"
                    className="xl:w-4 xl:h-4"
                  />
                </div>
                <p className="text-black xl:pt-8 text-left xl:text-lg font-medium text-base headlineheight banner-font">
                  “Great! This is the best hosting I have ever used before.”
                </p>
                <div className="flex xl:w-12/12 xl:pt-8">
                  <div className="xl:w-9/12  text-gray text-left">
                    <h1 className="banner-font font-semibold xl:text-lg">
                      Peter Watson
                    </h1>
                    <p className="banner-font ">Barista</p>
                  </div>
                  <div className="xl:w-3/12 xl:pt-2 xl:pl-4">
                    <img
                      src="https://i.ibb.co/V349Bt7/chkm.png"
                      className="rounded-full xl:w-12"
                    />
                  </div>
                </div>
              </div>
              <div className="bg-white shadow-md rounded-md xl:p-12">
                <div className="flex gap-1  xl:pr-6">
                  <img
                    src="https://i.ibb.co/K2N8PVv/star-14442199-removebg-preview.png"
                    className="xl:w-4 xl:h-4"
                  />
                  <img
                    src="https://i.ibb.co/K2N8PVv/star-14442199-removebg-preview.png"
                    className="xl:w-4 xl:h-4"
                  />
                  <img
                    src="https://i.ibb.co/K2N8PVv/star-14442199-removebg-preview.png"
                    className="xl:w-4 xl:h-4"
                  />
                  <img
                    src="https://i.ibb.co/K2N8PVv/star-14442199-removebg-preview.png"
                    className="xl:w-4 xl:h-4"
                  />
                  <img
                    src="https://i.ibb.co/K2N8PVv/star-14442199-removebg-preview.png"
                    className="xl:w-4 xl:h-4"
                  />
                </div>
                <p className="text-black xl:pt-8 text-left xl:text-lg font-medium text-base headlineheight banner-font">
                  “Great! This is the best hosting I have ever used before.”
                </p>
                <div className="flex xl:w-12/12 xl:pt-8">
                  <div className="xl:w-9/12  text-gray text-left">
                    <h1 className="banner-font font-semibold xl:text-lg">
                      Peter Watson
                    </h1>
                    <p className="banner-font ">Barista</p>
                  </div>
                  <div className="xl:w-3/12 xl:pt-2 xl:pl-4">
                    <img
                      src="https://i.ibb.co/V349Bt7/chkm.png"
                      className="rounded-full xl:w-12"
                    />
                  </div>
                </div>
              </div>
              <div className="bg-white shadow-md rounded-md xl:p-12">
                <div className="flex gap-1  xl:pr-6">
                  <img
                    src="https://i.ibb.co/K2N8PVv/star-14442199-removebg-preview.png"
                    className="xl:w-4 xl:h-4"
                  />
                  <img
                    src="https://i.ibb.co/K2N8PVv/star-14442199-removebg-preview.png"
                    className="xl:w-4 xl:h-4"
                  />
                  <img
                    src="https://i.ibb.co/K2N8PVv/star-14442199-removebg-preview.png"
                    className="xl:w-4 xl:h-4"
                  />
                  <img
                    src="https://i.ibb.co/K2N8PVv/star-14442199-removebg-preview.png"
                    className="xl:w-4 xl:h-4"
                  />
                  <img
                    src="https://i.ibb.co/K2N8PVv/star-14442199-removebg-preview.png"
                    className="xl:w-4 xl:h-4"
                  />
                </div>
                <p className="text-black xl:pt-8 text-left xl:text-lg font-medium text-base headlineheight banner-font">                  “Great! This is the best hosting I have ever used before.”
                </p>
                <div className="flex xl:w-12/12 xl:pt-8">
                  <div className="xl:w-9/12  text-gray text-left">
                    <h1 className="banner-font font-semibold xl:text-lg">
                      Peter Watson
                    </h1>
                    <p className="banner-font ">Barista</p>
                  </div>
                  <div className="xl:w-3/12 xl:pt-2 xl:pl-4">
                    <img
                      src="https://i.ibb.co/V349Bt7/chkm.png"
                      className="rounded-full xl:w-12"
                    />
                  </div>
                </div>
              </div>
              <div className="bg-white shadow-md rounded-md xl:p-12">
                <div className="flex gap-1  xl:pr-6">
                  <img
                    src="https://i.ibb.co/K2N8PVv/star-14442199-removebg-preview.png"
                    className="xl:w-4 xl:h-4"
                  />
                  <img
                    src="https://i.ibb.co/K2N8PVv/star-14442199-removebg-preview.png"
                    className="xl:w-4 xl:h-4"
                  />
                  <img
                    src="https://i.ibb.co/K2N8PVv/star-14442199-removebg-preview.png"
                    className="xl:w-4 xl:h-4"
                  />
                  <img
                    src="https://i.ibb.co/K2N8PVv/star-14442199-removebg-preview.png"
                    className="xl:w-4 xl:h-4"
                  />
                  <img
                    src="https://i.ibb.co/K2N8PVv/star-14442199-removebg-preview.png"
                    className="xl:w-4 xl:h-4"
                  />
                </div>
                <p className="text-black xl:pt-8 text-left xl:text-lg font-medium text-base headlineheight banner-font">                  “Great! This is the best hosting I have ever used before.”
                </p>
                <div className="flex xl:w-12/12 xl:pt-8">
                  <div className="xl:w-9/12  text-gray text-left">
                    <h1 className="banner-font font-semibold xl:text-lg">
                      Peter Watson
                    </h1>
                    <p className="banner-font ">Barista</p>
                  </div>
                  <div className="xl:w-3/12 xl:pt-2 xl:pl-4">
                    <img
                      src="https://i.ibb.co/V349Bt7/chkm.png"
                      className="rounded-full xl:w-12"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    

      {/* <section
        ref={(el) => sections.current.push(el)}
        style={{
          backgroundImage: `url(${bglast})`,
          width: "100%",
          backgroundSize: "cover",
        }}
        className="animate-on-scroll"
      >
        <div className="container xl:py-20 lg:pt-40 pt-16 mx-auto justify-center flex flex-wrap ">
          <div className="w-full md:w-9/12 lg:w-8/12 xl:w-88/12 ">
            <div className="pt-32 sm:pt-0">
              <p className="text-center xl:text-base1 text-base text-blue leading-relaxed font-semibold banner-font">
                GET IN TOUCH
              </p>
              <h1 className="text-center font-semibold xl:text-4xl lg:text-2xl md:text-2xl text-xl xl:pt-4 text-black banner-font">
                <span className="text-gradient banner-font xl:pl-2 xl:pt-4">
                  Contact our support
                </span>
              </h1>
              <p className="xl:px-52 xl:mt-4 text-center xl:text-xl text-base  text-gray leading-relaxed headlineheight banner-font">
                We offer a wide range of hosting solutions, from web hosting to
                dedicated servers. Everything you need is right here.
              </p>
              <div className="center-items xl:mt-8 lg:mt-6">
                <button className="blue-gradient banner-font text-white xl:px-10 lg:px-6 lg:py-2 xl:py-2 font-medium rounded-md ">
                  Get Started !
                </button>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <Footer2 />
    </>
  );
}
