import React, { useState, useEffect } from 'react';

const Slideshow = ({ images, interval }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const nextSlide = () => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const intervalId = setInterval(nextSlide, interval);

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [images.length, interval]);

  return (
    <div className=" w-full h-104 overflow-hidden">
      {images.map((image, index) => (
        <img
          key={index}
          src={image}
          alt=""
          className={`xl:w-380 rounded-md absolute top-150 transition-opacity duration-1000 ${
            index === currentIndex ? 'opacity-100 z-10' : 'opacity-0 z-0'
          }`}
        />
      ))}
    </div>
  );
};

export default Slideshow;
