import React, { useState } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";
import "assets/styles/style.css";

import Index from "views/Index.js";
import Contactus from "about/aboutpage/Contactus";
import Aboutus from "about/aboutpage/Aboutus";
import Footer2 from "components/Footers/Footer2";
import ServicesHomepage from "components/Homepage/ServicesHomepage";
import Service from "components/Homepage/Service";
import Blog from "components/Homepage/Blog";
import BlogDetail from "components/Homepage/BlogDetail";
import Portfolio from "components/Homepage/Portfolio";
import HoverChange from "components/Homepage/HoverChange";
import WorkingTool from "components/Homepage/WorkingTool";
import OurTeam from "components/Homepage/OurTeam";
import Imagescroll from "components/Homepage/Imagescroll";
import IndexNavbar2 from "components/Navbars/IndexNavbar2";
import HoverChange2 from "components/Homepage/HoverChange2";
import SliderAbout from "views/SliderAbout";
import Testimonials from "components/Homepage/Testimonials";
import SliderPage1 from "views/SliderPage1";
import BestPlans from "components/Homepage/BestPlans";
import Servicenew from "components/Homepage/Servicenew";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/homepage" exact component={Index} />

      {/* Contact us */}
      <Route path="/about-us" exact component={Aboutus} />
      <Route path="/contact-us" exact component={Contactus} />

      {/* Homepage */}
      <Route path="/" exact component={Index} />
      <Route path="/services-homepage" exact component={ServicesHomepage} />
      <Route path="/service" exact component={Service} />
      <Route path="/blog" exact component={Blog} />
      <Route path="/blog-detail" exact component={BlogDetail} />
      <Route path="/portfolio" exact component={Portfolio} />
      <Route path="/hover-change" exact component={HoverChange} />
      <Route path="/hover-change2" exact component={HoverChange2} />
      <Route path="/sliderabout" exact component={SliderAbout} />
      <Route path="/working-tool" exact component={WorkingTool} />
      <Route path="/our-team" exact component={OurTeam} />
      <Route path="/image-scroll" exact component={Imagescroll} />
      <Route path="/indexnavbar2" exact component={IndexNavbar2} />
      <Route path="/testimonials" exact component={Testimonials} />
      <Route path="/slider-1" exact component={SliderPage1} />
      <Route path="/best-plans" exact component={BestPlans} />
      <Route path="/service-new" exact component={Servicenew} />
      
      <Route path="/footer" exact component={Footer2} />

      {/* add redirect for first page */}
      <Redirect from="*" to="/" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
