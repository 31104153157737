import React, { useEffect, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import mendublicate from "assets/img/mendublicate.png";

const SliderAbout = () => {
  const settings = {
    dots: false,
    infinite: true,
    centerPadding: "1rem",
    speed: 1300,
    slidesToShow: 4.5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <section className="bg-white xl:py-20 overflow-hidden">
      <Slider {...settings} className="">
        <div className="px-2">
          <div className="rounded-xll flex bg-light-bluee xl:p-8">
            <div>
              <h1 className="text-black xl:text-xl font-semibold text-left banner-font">
                Healthcare
              </h1>
              <p className="banner-font text-gray xl:text-sm pt-2">
                Provide remote <br></br>medical consultations
              </p>
              <button className="bg-button px-6 py-2 banner-font text-white mt-4 font-medium text-sm">
                Learn more
              </button>
            </div>
            <div>
              <img
                src="https://i.ibb.co/SrqXHpk/healthcare-2382443-1.png"
                className="xl:w-16 xl:mt-16 xl:ml-12"
              />
            </div>
          </div>
        </div>

        <div>
          <div className="rounded-xll flex bg-light-green xl:p-8">
            <div>
              <h1 className="text-black xl:text-xl font-semibold text-left banner-font">
                Ratail
              </h1>
              <p className="banner-font text-gray xl:text-sm pt-2">
                Improve sales with <br></br>Video shopping
              </p>
              <button className="bg-button px-6 py-2 banner-font text-white mt-4 font-medium text-sm">
                Learn more
              </button>
            </div>
            <div>
              <img
                src="https://i.ibb.co/D4gJ81y/shopping-cart-3737372.png"
                className="xl:w-16 xl:mt-16 xl:ml-20"
              />
            </div>
          </div>
        </div>

        <div className="px-2">
          <div className="rounded-xll flex bg-light-reddd xl:p-8">
            <div>
              <h1 className="text-black xl:text-xl font-semibold text-left banner-font">
                Banking
              </h1>
              <p className="banner-font text-gray xl:text-sm pt-2">
                Build better customer <br></br>relationships
              </p>
              <button className="bg-button px-6 py-2 banner-font text-white mt-4 font-medium text-sm">
                Learn more
              </button>
            </div>
            <div>
              <img
                src="https://i.ibb.co/R9w9ZG7/online-banking-2967383.png"
                className="xl:w-16 xl:mt-16 xl:ml-12"
              />
            </div>
          </div>
        </div>
        <div>
          <div className="rounded-xll flex bggg xl:p-8">
            <div>
              <h1 className="text-black xl:text-xl font-semibold text-left banner-font">
                Education
              </h1>
              <p className="banner-font text-gray xl:text-sm pt-2">
                Teach online and <br></br>empower students
              </p>
              <button className="bg-button px-6 py-2 banner-font text-white mt-4 font-medium text-sm">
                Learn more
              </button>
            </div>
            <div>
              <img
                src="https://i.ibb.co/mt8tQ24/mortarboard-2682264.png"
                className="xl:w-16 xl:mt-16 xl:ml-12"
              />
            </div>
          </div>
        </div>

        <div className="px-2">
          <div className="rounded-xll flex bgg xl:p-8">
            <div>
              <h1 className="text-black xl:text-xl font-semibold text-left banner-font">
                Government
              </h1>
              <p className="banner-font text-gray xl:text-sm pt-2">
                Improve inter-agency <br></br>communications
              </p>
              <button className="bg-button px-6 py-2 banner-font text-white mt-4 font-medium text-sm">
                Learn more
              </button>
            </div>
            <div>
              <img
                src="https://i.ibb.co/fppN0Dm/government-1582292.png"
                className="xl:w-16 xl:mt-16 xl:ml-12"
              />
            </div>
          </div>
        </div>

        <div>
          <div className="rounded-xll flex bg-light-reddd xl:p-8">
            <div>
              <h1 className="text-black xl:text-xl font-semibold text-left banner-font">
                Ratail
              </h1>
              <p className="banner-font text-gray xl:text-sm pt-2">
                Improve sales with <br></br>Video shopping
              </p>
              <button className="bg-button px-6 py-2 banner-font text-white mt-4 font-medium text-sm">
                Learn more
              </button>
            </div>
            <div>
              <img
                src="https://i.ibb.co/D4gJ81y/shopping-cart-3737372.png"
                className="xl:w-16 xl:mt-16 xl:ml-20"
              />
            </div>
          </div>
        </div>

        <div className="px-2">
          <div className="rounded-xll flex bg-light-green xl:p-8">
            <div>
              <h1 className="text-black xl:text-xl font-semibold text-left banner-font">
                Healthcare
              </h1>
              <p className="banner-font text-gray xl:text-sm pt-2">
                Provide remote <br></br>medical consultations
              </p>
              <button className="bg-button px-6 py-2 banner-font text-white mt-4 font-medium text-sm">
                Learn more
              </button>
            </div>
            <div>
              <img
                src="https://i.ibb.co/SrqXHpk/healthcare-2382443-1.png"
                className="xl:w-16 xl:mt-16 xl:ml-12"
              />
            </div>
          </div>
        </div>
        <div>
          <div className="rounded-xll flex bgg xl:p-8">
            <div>
              <h1 className="text-black xl:text-xl font-semibold text-left banner-font">
                Government
              </h1>
              <p className="banner-font text-gray xl:text-sm pt-2">
                Improve inter-agency <br></br>communications
              </p>
              <button className="bg-button px-6 py-2 banner-font text-white mt-4 font-medium text-sm">
                Learn more
              </button>
            </div>
            <div>
              <img
                src="https://i.ibb.co/fppN0Dm/government-1582292.png"
                className="xl:w-16 xl:mt-16 xl:ml-12"
              />
            </div>
          </div>
        </div>
      </Slider>
    </section>
  );
};

export default SliderAbout;
