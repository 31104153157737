import React, { useState } from "react";
import Footer from "components/Footers/Footer.js";
import Elegantaboutus from "assets/img/Elegantaboutus.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer2 from "components/Footers/Footer2";
import HoverChange from "components/Homepage/HoverChange";
import IndexNavbar2 from "components/Navbars/IndexNavbar2";
import HoverChange2 from "components/Homepage/HoverChange2";
import SliderAbout from "views/SliderAbout";
import Slideshow from "components/Homepage/Slideshow";

export default function Aboutus() {
  const images = [
    "https://i.ibb.co/GvNDdX7/medium-shot-man-wearing-vr-glasses-23-2149126949.jpg",
    // "https://i.ibb.co/cbVZfxD/ai-nuclear-energy-background-future-innovation-disruptive-technology-53876-129783.jpg",
    "https://i.ibb.co/YbG6ymS/artificial-intelligence-with-machine-deep-learning-data-mining-other-modern-user-interface-futuristi.jpg",
    "https://i.ibb.co/GvNDdX7/medium-shot-man-wearing-vr-glasses-23-2149126949.jpg",
    // "https://i.ibb.co/cbVZfxD/ai-nuclear-energy-background-future-innovation-disruptive-technology-53876-129783.jpg",
    "https://i.ibb.co/YbG6ymS/artificial-intelligence-with-machine-deep-learning-data-mining-other-modern-user-interface-futuristi.jpg",
    "https://i.ibb.co/GvNDdX7/medium-shot-man-wearing-vr-glasses-23-2149126949.jpg",
    // "https://i.ibb.co/cbVZfxD/ai-nuclear-energy-background-future-innovation-disruptive-technology-53876-129783.jpg",
    "https://i.ibb.co/YbG6ymS/artificial-intelligence-with-machine-deep-learning-data-mining-other-modern-user-interface-futuristi.jpg",
  ];
  const settings = {
    dots: false,
    infinite: true,
    centerPadding: "1rem",
    speed: 1300,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    // prevArrow: <CustomPrevArrow />,
    // nextArrow: <CustomNextArrow />,
  };

  return (
    <>
      <IndexNavbar2 />
      {/* <section>
        <div className="relative  items-center xl:h-465">
          <section
            style={{
              backgroundImage: `url(${Elegantaboutus})`,
              width: "100%",
              backgroundSize: "cover",
            }}
            className="xl:h-450  lg:h-600 md:h-300"
          >
            <div className="container xl:pt-56 lg:pt-40 pt-16 mx-auto justify-center flex flex-wrap ">
              <div className="w-full md:w-9/12 lg:w-8/12 xl:w-88/12 ">
                <div className="pt-32 sm:pt-0">
                  <h2 className="text-left  xl:-ml-42 font-medium xl:text-4xl lg:text-2xl md:text-2xl text-xl text-white  banner-font">
                    About Us
                  </h2>
                  <p className="xl:mt-4 xl:-ml-42 text-left xl:text-xlll text-base text-white leading-relaxed headlineheight  banner-font">
                    <a
                      href="/"
                      className="xl:text-base1 text-white font-medium banner-font"
                    >
                      Home{" "}
                    </a>
                    |{" "}
                    <span className="xl:text-base1 text-sky-blue font-medium banner-font">
                      {" "}
                      About us
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section> */}

      <section className="bg-white xl:pt-28 lg:pt-40 pt-16">
        <div className="container mx-auto overflow-hidden px-4 xl:pt-20 xl:pb-52 xl:px-12 lg:py-8 py-6">
          <div className="grid grid-cols-2 gap-4 items-center ">
          <div className="xl:ml-20 xl:mt-20">
              <Slideshow images={images} interval={5000} />
            </div>
            <div className="xl:-ml-4 xl:pr-8">
              <h2 className="xl:pt-12 banner-font font-medium xl:text-4xl lg:text-2xl md:text-2xl text-xl text-black">
                Transform business to technology
              </h2>
              <p className="xl:mt-3 xl:pb-2 xl:text-base1 text-base  text-gray headlineheightt banner-font">
                IT Technology is a broad category encompassing all aspec
                information technology and the application of technology in
                various industries. It includes areas such as software
                development
              </p>
              <div className="grid grid-cols-2">
                <div>
                  <div className="xl:mt-4 flex xl:text-base1 text-base  text-gray headlineheightt banner-font">
                    <img
                      src="https://i.ibb.co/2YPRpPb/check-mark-1442912.png"
                      className="xl:w-5 xl:-mt-1 xl:h-5 mr-2"
                    />
                    <p className="xl:-mt-11">Enhanced Security Features</p>
                  </div>
                  <div className="xl:mt-4 flex xl:text-base1 text-base  text-gray headlineheightt banner-font">
                    <img
                      src="https://i.ibb.co/2YPRpPb/check-mark-1442912.png"
                      className="xl:w-5 xl:-mt-1 xl:h-5 mr-2"
                    />
                    <p className="xl:-mt-11">Intuitive User Interface</p>
                  </div>
                </div>
                <div>
                  <div className="xl:mt-4 flex xl:text-base1 text-base  text-gray headlineheightt banner-font">
                    <img
                      src="https://i.ibb.co/2YPRpPb/check-mark-1442912.png"
                      className="xl:w-5 xl:-mt-1 xl:h-5 mr-2"
                    />
                    <p className="xl:-mt-11">Advanced Analytics Tools</p>
                  </div>
                  <div className="xl:mt-4 flex xl:text-base1 text-base  text-gray headlineheightt banner-font">
                    <img
                      src="https://i.ibb.co/2YPRpPb/check-mark-1442912.png"
                      className="xl:w-5 xl:-mt-1 xl:h-5 mr-2 "
                    />
                    <p className="xl:-mt-11">Real-Time Insights Delivery</p>
                  </div>
                </div>
              </div>
              <div className="xl:mt-6 lg:mt-6">
                <button className="font-medium text-sm bg-button text-white xl:px-6 py-2">
                  Discover more ➝
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-gray">
        <div className="container mx-auto overflow-hidden px-4 xl:py-16 lg:py-8 py-6">
          <h2 className="xl:px-72 text-center font-medium xl:text-4xl lg:text-2xl md:text-2xl text-xl text-black banner-font">
            The most powerful hosting platform
          </h2>
          <p className="xl:px-84 lg:px-56 md:px-32 px-4 xl:mt-2 text-center xl:text-base1 text-base text-gray headlineheightt banner-font">
            We offer a wide range of hosting solutions, from web hosting to
            dedicated servers. Everything you need is right here.
          </p>
          <div className="xl:pt-8 xl:gap-4 xl:px-12 grid xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-4 grid-cols-1">
            <a href="">
              <div className="bg-white border rounded-md shadow-xl center-items xl:p-2">
                <div className="xl:p-10 center-items bg-light-green border">
                <img
                  src="https://i.ibb.co/KyMzyP4/thumb-uphbh.png"
                  className="xl:w-16"
                />
                {/* <div className="xl:w-24 lg:w-20 border-t-blue xl:pb-6" /> */}
                <p className="xl:pt-6 xl:text-lg banner-font text-black font-medium text-center header-on-hover">
                  Easy to Use
                </p>
                <p className="xl:pt-1 text-center xl:text-base1 text-base  text-gray leading-relaxed headlineheight banner-font">
                  We offer a wide range of hosting solutions, from web hosting
                  to dedicated servers.
                </p></div>
              </div>
            </a>
            <a href="">
              <div className="bg-white border rounded-md shadow-xl center-items xl:p-2">
              <div className="xl:p-10 center-items bgg border">
                <img
                  src="https://i.ibb.co/bdkvBsW/time-managementhk.png"
                  className="xl:w-16"
                />
                {/* <div className="xl:w-24 lg:w-20  border-t-orange xl:pb-6" /> */}
                <p className="xl:pt-6  xl:text-lg banner-font  text-black font-medium text-center header-on-hover">
                  Fast & Reliable
                </p>
                <p className="xl:pt-1 text-center xl:text-base1 text-base  text-gray leading-relaxed headlineheight banner-font">
                  We offer a wide range of hosting web hosting and web servers
                  to dedicated servers.
                </p></div>
              </div>
            </a>
            <a href="">
              <div className="bg-white border rounded-md shadow-xl center-items xl:p-2">
              <div className="xl:p-10 center-items bggg border">
                <img
                  src="https://i.ibb.co/09WM628/customer-service.png"
                  className="xl:w-16"
                />
                {/* <div className="xl:w-24 lg:w-20  border-t-red xl:pb-6" /> */}
                <p className="xl:pt-6  xl:text-lg banner-font text-black font-medium text-center header-on-hover">
                  24/7 Support
                </p>
                <p className="xl:pt-1 text-center xl:text-base1 text-base  text-gray leading-relaxed headlineheight banner-font">
                  We offer a wide range of hosting solutions, from web hosting
                  to dedicated servers.
                </p></div>
              </div>
            </a>
            <a href="">
              <div className="bg-white border rounded-md shadow-xl center-items xl:p-2">
              <div className="xl:p-10 center-items bg-light-orangeee border">
                <img
                  src="https://i.ibb.co/KyMzyP4/thumb-uphbh.png"
                  className="xl:w-16"
                />
                {/* <div className="xl:w-24 lg:w-20 border-t-blue xl:pb-6" /> */}
                <p className="xl:pt-6 xl:text-lg banner-font text-black font-medium text-center header-on-hover">
                  Easy to Use
                </p>
                <p className="xl:pt-1 text-center xl:text-base1 text-base  text-gray leading-relaxed headlineheight banner-font">
                  We offer a wide range of hosting solutions, from web hosting
                  to dedicated servers.
                </p></div>
              </div>
            </a>
          </div>
        </div>
      </section>

      <section className="bg-elegantcarve">
        <div className="container mx-auto overflow-hidden px-4 xl:py-16 xl:px-12 lg:py-8 py-6">
          <div className="grid grid-cols-2">
            <div>
              <h2 className="banner-font text-white font-medium xl:text-4xl lg:text-2xl md:text-2xl text-xl text-black">
                Sustainable and responsible computing
              </h2>
            </div>
            <div>
              <p className="xl:mt-2 xl:pb-4 xl:pl-10 xl:text-base1 text-base text-white headlineheightt banner-font">
                Discuss strategies for reducing energy consumption in data
                centers, personal devices, and reduce power usage.Liscuss
                strategies for reducing energy consumption in data centers,
                personal devices, and reduce power usage
              </p>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-2">
            <div>
              <div className="flex xl:w-12/12 xl:mt-4 items-center border-light-grey rounded-md xl:p-4">
                <div className="xl:mr-4 ">
                  <p className=" banner-font xl:text-3xl bg-sky-blue text-white xl:p-4 font-semibold rounded-full">
                    01
                  </p>
                </div>
                <div className="xl:w-10/12">
                  <p className="xl:pt-2 lg:text-2xl text-lg banner-font text-white font-medium text-left">
                    Assess Requirements Precisely
                  </p>
                  <p className="xl:pt-1 xl:text-base1 text-base text-white headlineheightt banner-font">
                    Begin by comprehensively understanding your business needs
                    to ensure a perfect fit for tech solutions.
                  </p>
                </div>
              </div>

              <div className="flex xl:w-12/12 xl:mt-4 items-center border-light-grey rounded-md xl:p-4">
                <div className="xl:mr-4 ">
                  <p className=" banner-font xl:text-3xl bg-sky-blue text-white xl:p-4 font-semibold rounded-full">
                    02
                  </p>
                </div>
                <div className="xl:w-10/12">
                  <p className="xl:pt-2 lg:text-2xl text-lg banner-font text-white font-medium text-left">
                    Develop Custom Solutions
                  </p>
                  <p className="xl:pt-1 xl:text-base1 text-base text-white headlineheightt banner-font">
                    Develop Custom Solutions Engage in crafting tailor-made
                    software designed meticulously to align with your specific
                    targets.eady, providing reliable assistance and technical.
                  </p>
                </div>
              </div>
              <div className="flex xl:w-12/12 xl:mt-4 items-center border-light-grey rounded-md xl:p-4">
                <div className="xl:mr-4 ">
                  <p className=" banner-font xl:text-3xl bg-sky-blue text-white xl:p-4 font-semibold rounded-full">
                    03
                  </p>
                </div>
                <div className="xl:w-10/12">
                  <p className="xl:pt-2 lg:text-2xl text-lg banner-font text-white font-medium text-left">
                    Implement and Support
                  </p>
                  <p className="xl:pt-1 xl:text-base1 text-base text-white headlineheightt banner-font">
                    Seamlessly integrate the new systems into framework with
                    ongoing support for continual optimization.
                  </p>
                </div>
              </div>
            </div>
            <div className="xl:ml-8 xl:pt-4">
              <div>
                <img
                  src="https://i.ibb.co/c1gDVZn/work.webp"
                  className="xl:w-200 rounded-md"
                />
              </div>
              <div className="flex xl:mt-8 xl:h-32 xl:w-200">
                <div className="rounded-l-md bg-sky-blue">
                  <p className=" banner-font xl:text-2xl font-semibold text-white xl:pt-4 xl:pl-16 xl:pr-16">
                    20+
                  </p>
                  <p className=" banner-font font-medium text-white xl:text-base1 xl:pl-16 xl:pr-12">
                    20 years of Experience
                  </p>
                </div>
                <div>
                  <img
                    src="https://i.ibb.co/Rjm9vW1/Screenshot-524.png"
                    className="xl:w-200 rounded-r-md xl:h-32 "
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <HoverChange2 />

      {/* <section className="bg-light-green">
        <div className="py-4">

        </div>
      </section> */}

      {/* <section className="bg-white">
        <div className="container mx-auto overflow-hidden px-4 xl:pt-20 lg:py-8 py-6">
          <p className="text-center uppercase xl:text-base1 text-base text-blue leading-relaxed font-semibold  banner-font">
            Features
          </p>
          <h2 className="xl:px-84 lg:px-56 md:px-32 px-4 xl:pt-4 text-center font-bold xl:text-4xl lg:text-2xl md:text-2xl text-xl text-black  banner-font">
            Our Features and Services
          </h2>
          <p className="xl:mt-4 xl:pb-4 text-center xl:px-84 lg:px-56 md:px-32 px-4 xl:text-lg text-base text-gray leading-relaxed  banner-font">
                IT Technology is a broad category encompassing all aspec
                information technology of technology in
                various industries. 
              </p>
          <div className="grid xl:grid-cols-3 xl:gap-4 xl:pt-4 xl:px-48 xl:pb-12">
            <div className="banner-font border xl:p-6 shadow-xl rounded-md bg-white xl:mt-8">
              <div className="center-items">
                <img src="https://i.ibb.co/1Kcn5Kz/Whats-App-Image-2024-05-04-at-9-03-10-AM.jpg" />
              </div>
              <h1 className="xl:pt-7 banner-font xl:text-xl text-blue text-center font-semibold">
                Communications
              </h1>
              <p className="xl:pt-4 banner-font xl:text-base1 text-center  text-gray">
                IT Technology is a broad category encompassing all aspec
                information in various industries.
              </p>
            </div>
            <div className="banner-font border xl:p-6 shadow-xl rounded-md bg-white xl:mt-8">
              <div className="center-items">
                <img src="https://i.ibb.co/sW5CQzh/Whats-App-Image-2024-05-04-at-9-03-10-AM-1.jpg" />
              </div>
              <h1 className="xl:pt-4 banner-font xl:text-xl text-blue text-center font-semibold">
                Inspired Design
              </h1>
              <p className="xl:pt-4 banner-font xl:text-base1 text-center  text-gray">
                IT Technology is a broad category encompassing all aspec
                information in various industries.
              </p>
            </div>
            <div className="banner-font border xl:p-6 shadow-xl rounded-md bg-white xl:mt-8">
              <div className="center-items">
                <img src="https://i.ibb.co/87fsQV7/Whats-App-Image-2024-05-04-at-9-03-10-AM-2.jpg" />
              </div>
              <h1 className="xl:pt-8 banner-font xl:text-xl text-blue text-center font-semibold">
                Happy Customers
              </h1>
              <p className="xl:pt-4 banner-font xl:text-base1 text-center  text-gray">
                IT Technology is a broad category encompassing all aspec
                information in various industries.
              </p>
            </div>
          </div>
        </div>
      </section> */}

      <SliderAbout />

      <Footer2 />
    </>
  );
}
