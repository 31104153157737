import React, { useState } from "react";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer2 from "components/Footers/Footer2";
import elegantcarvelogo from "assets/img/elegantcarvelogo.png";
import IndexNavbar2 from "components/Navbars/IndexNavbar2";
import SliderPage1 from "views/SliderPage1";

export default function Blog() {
  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  return (
    <>
      <IndexNavbar2 fixed />
      <SliderPage1/>

      <section className="bg-grey">
        <div className="container mx-auto px-4 xl:pt-8 xl:pb-20 lg:pt-8 lg:pb-20 py-6">
          <div className="xl:flex md:flex lg:flex flex-none xl:w-12/12">
            <div className="xl:w-3/12 md:w-3/12 gap-2 xl:sticky md:sticky lg:sticky top-0 xl:h-300 lg:h-550 md:h-700">
              <div className="xl:px-8 xl:grid-cols-1 grid border-b-c">
                <button
                  className={`${
                    activeTab === 1
                      ? "active font-semibold text-blue text-sm"
                      : "border-sky-600"
                  } font font-semibold xl:text-xl md:text-sm text-base xl:pt-2 xl:py-0 py-2 focus:outline-none`}
                  onClick={() => handleTabClick(1)}
                >
                  <p className="xl:text-lg xl:mb-2 ">
                    <div className="flex header-on-hover">
                      <div className="rounded-md bg-blue font-semibold xl:text-lg h-8 w-8 text-center  text-white">
                        1
                      </div>
                      <div className="font-normal xl:text-base1 pt-1 xl:pl-4 pl-2">
                        Fundamentals ➝
                      </div>
                    </div>
                    {activeTab === 1 && (
                      <div className="border-l animate-slide-up">
                        <div className="text-left xl:pl-8 pl-4 pt-4 xl:py-2 xl:mt-4">
                          <div className="text-sm text-black banner-font font-normal">
                            <b className="pr-2"> 1.</b>Understanding how Links
                            and protocols works 🚀
                          </div>
                          <div className="xl:pt-4 lg:pt-4 pt-4 text-sm banner-font text-black font-normal">
                            <b className="pr-2"> 2.</b>HTTP, Web Browsers, and
                            Web Servers
                          </div>
                          <div className="xl:pt-4 lg:pt-4 pt-4 text-sm banner-font text-black font-normal">
                            <b className="pr-2"> 3.</b>Unveiling the Web
                            Browser: Gateway to the World Wide Web
                          </div>
                        </div>
                      </div>
                    )}
                  </p>
                </button>
                <button
                  className={`${
                    activeTab === 2
                      ? "active font-semibold text-blue text-sm"
                      : "border-sky-600"
                  } font font-semibold xl:text-xl md:text-sm text-base xl:pt-2 xl:py-0 py-2 focus:outline-none`}
                  onClick={() => handleTabClick(2)}
                >
                  <p className="xl:text-lg xl:mb-2 ">
                    <div className="flex header-on-hover">
                      <div className="rounded-md bg-orange font-semibold xl:text-lg h-8 w-8 text-center  text-white">
                        2
                      </div>
                      <div className="font-normal xl:text-base1 pt-1 xl:pl-4 pl-2">
                        HTML ➝
                      </div>
                    </div>
                    {activeTab === 2 && (
                      <div className="border-l animate-slide-up">
                        <div className="text-left xl:pl-8 pl-4 pt-4 xl:py-2 xl:mt-4">
                          <div className="text-sm banner-font text-black font-normal">
                            <b className="pr-2"> 1.</b>Stands for Hyper Text
                            Markup Language
                          </div>
                          <div className="xl:pt-4 lg:pt-4 pt-4 text-sm banner-font text-black font-normal">
                            <b className="pr-2"> 2.</b>Describes the structure
                            of a Web page
                          </div>
                          <div className="xl:pt-4 lg:pt-4 pt-4 text-sm banner-font text-black font-normal">
                            <b className="pr-2"> 3.</b>Consists of a series of
                            elements
                          </div>
                        </div>
                      </div>
                    )}
                  </p>
                </button>
                <button
                  className={`${
                    activeTab === 3
                      ? "active font-semibold text-blue text-sm"
                      : "border-sky-600"
                  } font font-semibold xl:text-xl md:text-sm text-base xl:pt-2 xl:py-0 py-2 focus:outline-none`}
                  onClick={() => handleTabClick(3)}
                >
                  <p className="xl:text-lg xl:mb-2 ">
                    <div className="flex header-on-hover">
                      <div className="rounded-md bg-sky-blue font-semibold xl:text-lg h-8 w-8 text-center  text-white">
                        3
                      </div>
                      <div className="font-normal xl:text-base1 pt-1 xl:pl-4 pl-2">
                        CSS ➝
                      </div>
                    </div>
                    {activeTab === 3 && (
                      <div className="border-l animate-slide-up">
                        <div className="text-left xl:pl-8 pl-4 pt-4 xl:py-2 xl:mt-4">
                          <div className="text-sm banner-font text-black font-normal">
                            <b className="pr-2"> 1.</b>Set different list item
                            markers
                          </div>
                          <div className="xl:pt-4 lg:pt-4 pt-4 text-sm banner-font text-black font-normal">
                            <b className="pr-2"> 2.</b>Set an image as the list
                            item marker
                          </div>
                          <div className="xl:pt-4 lg:pt-4 pt-4 text-sm banner-font text-black font-normal">
                            <b className="pr-2"> 3.</b> Add background colors to
                            lists
                          </div>
                        </div>
                      </div>
                    )}
                  </p>
                </button>

                <button
                  className={`${
                    activeTab === 4
                      ? "active font-semibold text-blue text-sm"
                      : "border-sky-600"
                  } font font-semibold xl:text-xl md:text-sm text-base xl:pt-2 xl:py-0 py-2 focus:outline-none`}
                  onClick={() => handleTabClick(4)}
                >
                  <p className="xl:text-lg xl:mb-2 ">
                    <div className="flex header-on-hover">
                      <div className="rounded-md bg-blue font-semibold xl:text-lg h-8 w-8 text-center  text-white">
                        4
                      </div>
                      <div className="font-normal xl:text-base1 pt-1 xl:pl-4 pl-2">
                        JavaScript ➝
                      </div>
                    </div>
                    {activeTab === 4 && (
                      <div className="border-l animate-slide-up">
                        <div className="text-left xl:pl-8 pl-4 pt-4 xl:py-2 xl:mt-4">
                          <div className="text-sm banner-font text-black font-normal">
                            <b className="pr-2"> 1.</b>Programming language
                          </div>
                          <div className="xl:pt-4 lg:pt-4 pt-4 text-sm banner-font text-black font-normal">
                            <b className="pr-2"> 2.</b>JavaScript is easy to
                            learn.
                          </div>
                          <div className="xl:pt-4 lg:pt-4 pt-4 text-sm banner-font text-black font-normal">
                            <b className="pr-2"> 3.</b>Programming language of
                            the Web.
                          </div>
                        </div>
                      </div>
                    )}
                  </p>
                </button>
                <button
                  className={`${
                    activeTab === 5
                      ? "active font-semibold text-blue text-sm"
                      : "border-sky-600"
                  } font font-semibold xl:text-xl md:text-sm text-base xl:pt-2 xl:py-0 py-2 focus:outline-none`}
                  onClick={() => handleTabClick(5)}
                >
                  <p className="xl:text-lg xl:mb-2 ">
                    <div className="flex header-on-hover">
                      <div className="rounded-md bg-black font-semibold xl:text-lg h-8 w-8 text-center  text-white">
                        5
                      </div>
                      <div className="font-normal xl:text-base1 pt-1 xl:pl-4 pl-2">
                        Databases ➝
                      </div>
                    </div>
                    {activeTab === 5 && (
                      <div className="border-l animate-slide-up">
                        <div className="text-left xl:pl-8 pl-4 pt-4 xl:py-2 xl:mt-4">
                          <div className="text-sm banner-font text-black font-normal">
                            <b className="pr-2"> 1.</b>Collection of logically
                            related information
                          </div>
                          <div className="xl:pt-2 lg:pt-4 pt-4 text-sm banner-font text-black font-normal">
                            <b className="pr-2"> 2.</b>Easily accessible,
                            managed, and updated.
                          </div>
                          <div className="xl:pt-2 lg:pt-4 pt-4 text-sm banner-font text-black font-normal">
                            <b className="pr-2"> 3.</b>Accessed electronically
                            from a computer system
                          </div>
                        </div>
                      </div>
                    )}
                  </p>
                </button>
                <button
                  className={`${
                    activeTab === 6
                      ? "active font-semibold text-blue text-sm"
                      : "border-sky-600"
                  } font font-semibold xl:text-xl md:text-sm text-base xl:pt-2 xl:py-0 py-2 focus:outline-none`}
                  onClick={() => handleTabClick(6)}
                >
                  <p className="xl:text-lg xl:mb-2 ">
                    <div className="flex header-on-hover">
                      <div className="rounded-md bg-yellow font-semibold xl:text-lg h-8 w-8 text-center  text-white">
                        6
                      </div>
                      <div className="font-normal xl:text-base1 pt-1 xl:pl-4 pl-2">
                        Deployment ➝
                      </div>
                    </div>
                    {activeTab === 6 && (
                      <div className="border-l animate-slide-up">
                        <div className="text-left xl:pl-8 pl-4 pt-4 xl:py-2 xl:mt-4">
                          <div className="text-sm banner-font text-black font-normal">
                            <b className="pr-2"> 1.</b>Planning and Assessment.
                          </div>
                          <div className="xl:pt-2 lg:pt-4 pt-4 text-sm text-black banner-font font-normal">
                            <b className="pr-2"> 2.</b>Development or
                            Configuration.
                          </div>
                          <div className="xl:pt-2 lg:pt-4 pt-4 text-sm text-black banner-font font-normal">
                            <b className="pr-2"> 3.</b> Testing and Quality
                            Assurance.
                          </div>
                        </div>
                      </div>
                    )}
                  </p>
                </button>
                <button
                  className={`${
                    activeTab === 7
                      ? "active font-semibold text-blue text-sm"
                      : "border-sky-600"
                  } font font-semibold xl:text-xl md:text-sm text-base xl:pt-2 xl:py-0 py-2 focus:outline-none`}
                  onClick={() => handleTabClick(7)}
                >
                  <p className="xl:text-lg xl:mb-2 ">
                    <div className="flex header-on-hover">
                      <div className="rounded-md bg-sky-blue font-semibold xl:text-lg h-8 w-8 text-center  text-white">
                        7
                      </div>
                      <div className="font-normal xl:text-base1 pt-1 xl:pl-4 pl-2">
                        React JS ➝
                      </div>
                    </div>
                    {activeTab === 7 && (
                      <div className="border-l animate-slide-up">
                        <div className="text-left xl:pl-8 pl-4 pt-4 xl:py-2 xl:mt-4">
                          <div className="text-sm banner-font text-black font-normal">
                            <b className="pr-2"> 1.</b>It is Virtual DOM.
                          </div>
                          <div className="xl:pt-2 lg:pt-4 pt-4 text-black text-sm banner-font font-normal">
                            <b className="pr-2"> 2.</b>One way data binding.
                          </div>
                          <div className="xl:pt-2 lg:pt-4 pt-4 text-black text-sm banner-font font-normal">
                            <b className="pr-2"> 3.</b>Extension is .jsx
                          </div>
                        </div>
                      </div>
                    )}
                  </p>
                </button>
                <button
                  className={`${
                    activeTab === 8
                      ? "active font-semibold text-blue text-sm"
                      : "border-sky-600"
                  } font font-semibold xl:text-xl md:text-sm text-base xl:pt-2 xl:py-0 py-2 focus:outline-none`}
                  onClick={() => handleTabClick(8)}
                >
                  <p className="xl:text-lg xl:mb-2 ">
                    <div className="flex header-on-hover">
                      <div className="rounded-md bg-orange font-semibold xl:text-lg h-8 w-8 text-center  text-white">
                        8
                      </div>
                      <div className="font-normal xl:text-base1 pt-1 xl:pl-4 pl-2">
                        Shopify ➝
                      </div>
                    </div>
                    {activeTab === 8 && (
                      <div className="animate-slide-up">
                        <div className="text-left xl:pl-8 pl-4 pt-4 xl:py-2 xl:mt-4">
                          <div className="text-sm banner-font font-normal text-black">
                            <b className="pr-2"> 1.</b>Consists of various
                            analytics
                          </div>
                          <div className="xl:pt-2 lg:pt-4 pt-4 text-sm banner-font text-black font-normal">
                            <b className="pr-2"> 2.</b>Generates impactful
                            insights
                          </div>
                          <div className="xl:pt-2 lg:pt-4 pt-4 text-sm banner-font text-black font-normal">
                            <b className="pr-2"> 3.</b>Customer Activities,
                            Marketing, Sales, and others
                          </div>
                        </div>
                      </div>
                    )}
                  </p>
                </button>
              </div>
            </div>

            <div className="xl:w-9/12 md:w-9/12 overflow-y-auto">
              <div className="tab-content text-center">
                {activeTab === 1 && (
                  <div className="">
                    <div className="text-left bg-white rounded-md xl:w-12/12 lg:w-12/12 md:w-12/12 xl:flex lg:flex md:flex flex-none  xl:py-4 shadow-md">
                      <div className="xl:w-3/12 lg:w-3/12 md:w-3/12">
                        <div className="rounded-r-full uppercase font-semibold text-xs xl:w-6/12 lg:w-5/12 md:w-5/12 w-4/12 xl:p-2 lg:p-1 md:p-1 p-1 text-center bg-sky-blue text-white xl:mt-8 mt-8">
                          FUNDAMENTALS
                        </div>
                        <div className="xl:p-6 lg:p-6 md:p-4 p-4">
                          <div className="text-left">
                            <div className="text-sm banner-font font-normal">
                              📅 Jan 19, 2024
                            </div>
                            <div className="xl:pt-4 md:pt-2 pt-1 text-sm banner-font ">
                              ⬇️ 1 Min Read
                            </div>
                            <div className="xl:pt-4 md:pt-2 pt-1 text-sm banner-font ">
                              🥇 Difficulty:
                            </div>
                            {/* <div className="xl:pt-4 md:pt-2 pt-1 text-sm banner-font ">
                               ★ ★ ★
                            </div> */}
                          </div>
                        </div>
                      </div>
                      <div className="xl:w-9/12 lg:w-9/12 md:w-9/12 xl:pt-8 lg:pt-8 md:pt-4 xl:pr-8 lg:pr-8 pr-4 pb-4">
                        <a href="/blog-detail">
                          <h1 className="cursor-pointer banner-font xl:text-xl lg:text-xl md:text-lg text-base font-medium text-black">
                            Understanding how Links and protocols works 🚀
                          </h1>
                        </a>
                        <p className="headliheheightt banner-font text-gray xl:pt-2 lg:pt-4">
                          In the realm of technology blogging, captivating your
                          audience goes beyond just the written word.
                          Incorporating eye-catching CSS animations can elevate
                          your content and provide a dynamic user…
                        </p>
                         <div className="xl:pt-4 pt-8 flex justify-between">
                          <a href="/blog-detail"><p className="banner-font text-gray text-sm">Read more ➝</p></a>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4 text-left bg-white rounded-md xl:w-12/12 lg:w-12/12 md:w-12/12 xl:flex lg:flex md:flex flex-none  xl:py-4 shadow-md">
                      <div className="xl:w-3/12 lg:w-3/12 md:w-3/12">
                        <div className="rounded-r-full uppercase text-xs font-semibold xl:w-6/12 lg:w-5/12 md:w-5/12 w-4/12 xl:p-2 lg:p-1 md:p-1 p-1 text-center bg-sky-blue text-white xl:mt-8 mt-8">
                          FUNDAMENTALS
                        </div>
                        <div className="xl:p-6 lg:p-6 md:p-4 p-4">
                        <div className="text-left">
                            <div className="text-sm banner-font font-normal">
                              📅 Jan 19, 2024
                            </div>
                            <div className="xl:pt-4 md:pt-2 pt-1 text-sm banner-font ">
                              ⬇️ 1 Min Read
                            </div>
                            <div className="xl:pt-4 md:pt-2 pt-1 text-sm banner-font ">
                              🥇 Difficulty:
                            </div>
                            {/* <div className="xl:pt-4 md:pt-2 pt-1 text-sm banner-font ">
                               ★ ★ ★
                            </div> */}
                          </div>
                        </div>
                      </div>
                      <div className="xl:w-9/12 lg:w-9/12 md:w-9/12 xl:pt-8 lg:pt-8 md:pt-4 xl:pr-8 lg:pr-8 pr-4 pb-4">
                        <a href="/blog-detail">
                          <h1 className="cursor-pointer banner-font xl:text-xl lg:text-xl md:text-lg text-base font-medium text-black">
                            HTTP, Web Browsers, and Web Servers
                          </h1>
                        </a>
                        <p className="headliheheightt banner-font text-gray xl:pt-2 lg:pt-4">
                          In the realm of technology blogging, captivating your
                          audience goes beyond just the written word.
                          Incorporating eye-catching CSS animations can elevate
                          your content and provide a dynamic user…
                        </p>
                        <div className="xl:pt-4 pt-8 flex justify-between">
                          <a href="/blog-detail"><p className="banner-font text-gray text-sm">Read more ➝</p></a>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4 text-left bg-white rounded-md xl:w-12/12 lg:w-12/12 md:w-12/12 xl:flex lg:flex md:flex flex-none  xl:py-4 shadow-md">
                      <div className="xl:w-3/12 lg:w-3/12 md:w-3/12">
                        <div className="rounded-r-full uppercase text-xs xl:w-6/12 font-semibold lg:w-5/12 md:w-5/12 w-4/12 xl:p-2 lg:p-1 md:p-1 p-1 text-center bg-sky-blue text-white xl:mt-8 mt-8">
                          FUNDAMENTALS
                        </div>
                        <div className="xl:p-6 lg:p-6 md:p-4 p-4">
                        <div className="text-left">
                            <div className="text-sm banner-font font-normal">
                              📅 Jan 19, 2024
                            </div>
                            <div className="xl:pt-4 md:pt-2 pt-1 text-sm banner-font ">
                              ⬇️ 1 Min Read
                            </div>
                            <div className="xl:pt-4 md:pt-2 pt-1 text-sm banner-font ">
                              🥇 Difficulty:
                            </div>
                            {/* <div className="xl:pt-4 md:pt-2 pt-1 text-sm banner-font ">
                               ★ ★ ★
                            </div> */}
                          </div>
                        </div>
                      </div>
                      <div className="xl:w-9/12 lg:w-9/12 md:w-9/12 xl:pt-8 lg:pt-8 md:pt-4 xl:pr-8 lg:pr-8 pr-4 pb-4">
                        <a href="/blog-detail">
                          <h1 className="cursor-pointer banner-font xl:text-xl lg:text-xl md:text-lg text-base font-medium text-black">
                            Unveiling the Web Browser: Gateway to the World Wide
                            Web
                          </h1>
                        </a>
                        <p className="headliheheightt banner-font text-gray xl:pt-2 lg:pt-4">
                          In the realm of technology blogging, captivating your
                          audience goes beyond just the written word.
                          Incorporating eye-catching CSS animations can elevate
                          your content and provide a dynamic user…
                        </p>
                         <div className="xl:pt-4 pt-8 flex justify-between">
                          <a href="/blog-detail"><p className="banner-font text-gray text-sm">Read more ➝</p></a>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {activeTab === 2 && (
                  <div>
                    <div className="text-left bg-white rounded-md xl:w-12/12 lg:w-12/12 md:w-12/12 xl:flex lg:flex md:flex flex-none  xl:py-4 shadow-md">
                      <div className="xl:w-3/12 lg:w-3/12 md:w-3/12">
                        <div className="rounded-r-full uppercase font-semibold text-xs xl:w-4/12 lg:w-5/12 md:w-5/12 w-4/12 xl:p-2 lg:p-1 md:p-1 p-1 text-center bg-sky-blue text-white xl:mt-8 mt-8">
                          html
                        </div>
                        <div className="xl:p-6 lg:p-6 md:p-4 p-4">
                        <div className="text-left">
                            <div className="text-sm banner-font font-normal">
                              📅 Jan 19, 2024
                            </div>
                            <div className="xl:pt-4 md:pt-2 pt-1 text-sm banner-font ">
                              ⬇️ 1 Min Read
                            </div>
                            <div className="xl:pt-4 md:pt-2 pt-1 text-sm banner-font ">
                              🥇 Difficulty:
                            </div>
                            {/* <div className="xl:pt-4 md:pt-2 pt-1 text-sm banner-font ">
                               ★ ★ ★
                            </div> */}
                          </div>
                        </div>
                      </div>
                      <div className="xl:w-9/12 lg:w-9/12 md:w-9/12 xl:pt-8 lg:pt-8 md:pt-4 xl:pr-8 lg:pr-8 pr-4 pb-4">
                        <a href="/blog-detail">
                          <h1 className="cursor-pointer banner-font xl:text-xl lg:text-xl md:text-lg text-base font-medium text-black">
                            Stands for Hyper Text Markup Language
                          </h1>
                        </a>
                        <p className="headliheheightt banner-font text-gray xl:pt-2 lg:pt-4">
                          In the realm of technology blogging, captivating your
                          audience goes beyond just the written word.
                          Incorporating eye-catching CSS animations can elevate
                          your content and provide a dynamic user…
                        </p>
                         <div className="xl:pt-4 pt-8 flex justify-between">
                          <a href="/blog-detail"><p className="banner-font text-gray text-sm">Read more ➝</p></a>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4 text-left bg-white rounded-md xl:w-12/12 lg:w-12/12 md:w-12/12 xl:flex lg:flex md:flex flex-none  xl:py-4 shadow-md">
                      <div className="xl:w-3/12 lg:w-3/12 md:w-3/12">
                        <div className="rounded-r-full  uppercase font-semibold text-xs xl:w-4/12  lg:w-5/12 md:w-5/12 w-4/12 xl:p-2 lg:p-1 md:p-1 p-1 text-center bg-sky-blue text-white xl:mt-8 mt-8">
                          html
                        </div>
                        <div className="xl:p-6 lg:p-6 md:p-4 p-4">
                        <div className="text-left">
                            <div className="text-sm banner-font font-normal">
                              📅 Jan 19, 2024
                            </div>
                            <div className="xl:pt-4 md:pt-2 pt-1 text-sm banner-font ">
                              ⬇️ 1 Min Read
                            </div>
                            <div className="xl:pt-4 md:pt-2 pt-1 text-sm banner-font ">
                              🥇 Difficulty:
                            </div>
                            {/* <div className="xl:pt-4 md:pt-2 pt-1 text-sm banner-font ">
                               ★ ★ ★
                            </div> */}
                          </div>
                        </div>
                      </div>
                      <div className="xl:w-9/12 lg:w-9/12 md:w-9/12 xl:pt-8 lg:pt-8 md:pt-4 xl:pr-8 lg:pr-8 pr-4 pb-4">
                        <a href="/blog-detail">
                          <h1 className="cursor-pointer banner-font xl:text-xl lg:text-xl md:text-lg text-base font-medium text-black">
                            Describes the structure of a Web page
                          </h1>
                        </a>
                        <p className="headliheheightt banner-font text-gray xl:pt-2 lg:pt-4">
                          In the realm of technology blogging, captivating your
                          audience goes beyond just the written word.
                          Incorporating eye-catching CSS animations can elevate
                          your content and provide a dynamic user…
                        </p>
                         <div className="xl:pt-4 pt-8 flex justify-between">
                          <a href="/blog-detail"><p className="banner-font text-gray text-sm">Read more ➝</p></a>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4 text-left bg-white rounded-md xl:w-12/12 lg:w-12/12 md:w-12/12 xl:flex lg:flex md:flex flex-none  xl:py-4 shadow-md">
                      <div className="xl:w-3/12 lg:w-3/12 md:w-3/12">
                        <div className="rounded-r-full  uppercase font-semibold text-xs xl:w-4/12  lg:w-5/12 md:w-5/12 w-4/12 xl:p-2 lg:p-1 md:p-1 p-1 text-center bg-sky-blue text-white xl:mt-8 mt-8">
                          html
                        </div>
                        <div className="xl:p-6 lg:p-6 md:p-4 p-4">
                          <div className="text-left">
                            <div className="text-sm banner-font font-normal">
                              📅 Jan 19, 2024
                            </div>
                            <div className="xl:pt-4 md:pt-2 pt-1 text-sm banner-font ">
                              ⬇️ 1 Min Read
                            </div>
                            <div className="xl:pt-4 md:pt-2 pt-1 text-sm banner-font ">
                              🥇 Difficulty:
                            </div>
                            {/* <div className="xl:pt-4 md:pt-2 pt-1 text-sm banner-font ">
                               ★ ★ ★
                            </div> */}
                          </div>
                        </div>
                      </div>
                      <div className="xl:w-9/12 lg:w-9/12 md:w-9/12 xl:pt-8 lg:pt-8 md:pt-4 xl:pr-8 lg:pr-8 pr-4 pb-4">
                        <a href="/blog-detail">
                          <h1 className="cursor-pointer banner-font xl:text-xl lg:text-xl md:text-lg text-base font-medium text-black">
                            Consists of a Series of Elements
                          </h1>
                        </a>
                        <p className="headliheheightt banner-font text-gray xl:pt-2 lg:pt-4">
                          In the realm of technology blogging, captivating your
                          audience goes beyond just the written word.
                          Incorporating eye-catching CSS animations can elevate
                          your content and provide a dynamic user…
                        </p>
                         <div className="xl:pt-4 pt-8 flex justify-between">
                          <a href="/blog-detail"><p className="banner-font text-gray text-sm">Read more ➝</p></a>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {activeTab === 3 && (
                  <div>
                    <div className="text-left bg-white rounded-md xl:w-12/12 lg:w-12/12 md:w-12/12 xl:flex lg:flex md:flex flex-none  xl:py-4 shadow-md">
                      <div className="xl:w-3/12 lg:w-3/12 md:w-3/12">
                        <div className="rounded-r-full uppercase font-semibold text-xs xl:w-4/12 lg:w-5/12 md:w-5/12 w-4/12 xl:p-2 lg:p-1 md:p-1 p-1 text-center bg-sky-blue text-white xl:mt-8 mt-8">
                          CSS
                        </div>
                        <div className="xl:p-6 lg:p-6 md:p-4 p-4">
                          <div className="text-left">
                            <div className="text-sm banner-font font-normal">
                              📅 Jan 19, 2024
                            </div>
                            <div className="xl:pt-4 md:pt-2 pt-1 text-sm banner-font ">
                              ⬇️ 1 Min Read
                            </div>
                            <div className="xl:pt-4 md:pt-2 pt-1 text-sm banner-font ">
                              🥇 Difficulty:
                            </div>
                            {/* <div className="xl:pt-4 md:pt-2 pt-1 text-sm banner-font ">
                               ★ ★ ★
                            </div> */}
                          </div>
                        </div>
                      </div>
                      <div className="xl:w-9/12 lg:w-9/12 md:w-9/12 xl:pt-8 lg:pt-8 md:pt-4 xl:pr-8 lg:pr-8 pr-4 pb-4">
                        <a href="/blog-detail">
                          <h1 className="cursor-pointer banner-font xl:text-xl lg:text-xl md:text-lg text-base font-medium text-black">
                            Set different list item markers
                          </h1>
                        </a>
                        <p className="headliheheightt banner-font text-gray xl:pt-2 lg:pt-4">
                          In the realm of technology blogging, captivating your
                          audience goes beyond just the written word.
                          Incorporating eye-catching CSS animations can elevate
                          your content and provide a dynamic user…
                        </p>
                         <div className="xl:pt-4 pt-8 flex justify-between">
                          <a href="/blog-detail"><p className="banner-font text-gray text-sm">Read more ➝</p></a>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4 text-left bg-white rounded-md xl:w-12/12 lg:w-12/12 md:w-12/12 xl:flex lg:flex md:flex flex-none  xl:py-4 shadow-md">
                      <div className="xl:w-3/12 lg:w-3/12 md:w-3/12">
                        <div className="rounded-r-full uppercase font-semibold text-xs xl:w-4/12 lg:w-5/12 md:w-5/12 w-4/12 xl:p-2 lg:p-1 md:p-1 p-1 text-center bg-sky-blue text-white xl:mt-8 mt-8">
                          CSS
                        </div>
                        <div className="xl:p-6 lg:p-6 md:p-4 p-4">
                          <div className="text-left">
                            <div className="text-sm banner-font font-normal">
                              📅 Jan 19, 2024
                            </div>
                            <div className="xl:pt-4 md:pt-2 pt-1 text-sm banner-font ">
                              ⬇️ 1 Min Read
                            </div>
                            <div className="xl:pt-4 md:pt-2 pt-1 text-sm banner-font ">
                              🥇 Difficulty:
                            </div>
                            {/* <div className="xl:pt-4 md:pt-2 pt-1 text-sm banner-font ">
                               ★ ★ ★
                            </div> */}
                          </div>
                        </div>
                      </div>
                      <div className="xl:w-9/12 lg:w-9/12 md:w-9/12 xl:pt-8 lg:pt-8 md:pt-4 xl:pr-8 lg:pr-8 pr-4 pb-4">
                        <a href="/blog-detail">
                          <h1 className="cursor-pointer banner-font xl:text-xl lg:text-xl md:text-lg text-base font-medium text-black">
                            Set an image as the list item marker{" "}
                          </h1>
                        </a>
                        <p className="headliheheightt banner-font text-gray xl:pt-2 lg:pt-4">
                          In the realm of technology blogging, captivating your
                          audience goes beyond just the written word.
                          Incorporating eye-catching CSS animations can elevate
                          your content and provide a dynamic user…
                        </p>
                         <div className="xl:pt-4 pt-8 flex justify-between">
                          <a href="/blog-detail"><p className="banner-font text-gray text-sm">Read more ➝</p></a>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4 text-left bg-white rounded-md xl:w-12/12 lg:w-12/12 md:w-12/12 xl:flex lg:flex md:flex flex-none  xl:py-4 shadow-md">
                      <div className="xl:w-3/12 lg:w-3/12 md:w-3/12">
                        <div className="rounded-r-full uppercase font-semibold text-xs xl:w-4/12 lg:w-5/12 md:w-5/12 w-4/12 xl:p-2 lg:p-1 md:p-1 p-1 text-center bg-sky-blue text-white xl:mt-8 mt-8">
                          CSS
                        </div>
                        <div className="xl:p-6 lg:p-6 md:p-4 p-4">
                          <div className="text-left">
                            <div className="text-sm banner-font font-normal">
                              📅 Jan 19, 2024
                            </div>
                            <div className="xl:pt-4 md:pt-2 pt-1 text-sm banner-font ">
                              ⬇️ 1 Min Read
                            </div>
                            <div className="xl:pt-4 md:pt-2 pt-1 text-sm banner-font ">
                              🥇 Difficulty:
                            </div>
                            {/* <div className="xl:pt-4 md:pt-2 pt-1 text-sm banner-font ">
                               ★ ★ ★
                            </div> */}
                          </div>
                        </div>
                      </div>
                      <div className="xl:w-9/12 lg:w-9/12 md:w-9/12 xl:pt-8 lg:pt-8 md:pt-4 xl:pr-8 lg:pr-8 pr-4 pb-4">
                        <a href="/blog-detail">
                          <h1 className="cursor-pointer banner-font xl:text-xl lg:text-xl md:text-lg text-base font-medium text-black">
                            Add background colors to lists
                          </h1>
                        </a>
                        <p className="headliheheightt banner-font text-gray xl:pt-2 lg:pt-4">
                          In the realm of technology blogging, captivating your
                          audience goes beyond just the written word.
                          Incorporating eye-catching CSS animations can elevate
                          your content and provide a dynamic user…
                        </p>
                         <div className="xl:pt-4 pt-8 flex justify-between">
                          <a href="/blog-detail"><p className="banner-font text-gray text-sm">Read more ➝</p></a>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {activeTab === 4 && (
                  <div>
                    <div className="text-left bg-white rounded-md xl:w-12/12 lg:w-12/12 md:w-12/12 xl:flex lg:flex md:flex flex-none  xl:py-4 shadow-md">
                      <div className="xl:w-3/12 lg:w-3/12 md:w-3/12">
                        <div className="rounded-r-full uppercase font-semibold text-xs xl:w-5/12 lg:w-5/12 md:w-5/12 w-4/12 xl:p-2 lg:p-1 md:p-1 p-1 text-center bg-sky-blue text-white xl:mt-8 mt-8">
                          JavaScript
                        </div>
                        <div className="xl:p-6 lg:p-6 md:p-4 p-4">
                          <div className="text-left">
                            <div className="text-sm banner-font font-normal">
                              📅 Jan 19, 2024
                            </div>
                            <div className="xl:pt-4 md:pt-2 pt-1 text-sm banner-font ">
                              ⬇️ 1 Min Read
                            </div>
                            <div className="xl:pt-4 md:pt-2 pt-1 text-sm banner-font ">
                              🥇 Difficulty:
                            </div>
                            {/* <div className="xl:pt-4 md:pt-2 pt-1 text-sm banner-font ">
                               ★ ★ ★
                            </div> */}
                          </div>
                        </div>
                      </div>
                      <div className="xl:w-9/12 lg:w-9/12 md:w-9/12 xl:pt-8 lg:pt-8 md:pt-4 xl:pr-8 lg:pr-8 pr-4 pb-4">
                        <a href="/blog-detail">
                          <h1 className="cursor-pointer banner-font xl:text-xl lg:text-xl md:text-lg text-base font-medium text-black">
                            Programming Language
                          </h1>
                        </a>
                        <p className="headliheheightt banner-font text-gray xl:pt-2 lg:pt-4">
                          In the realm of technology blogging, captivating your
                          audience goes beyond just the written word.
                          Incorporating eye-catching CSS animations can elevate
                          your content and provide a dynamic user…
                        </p>
                         <div className="xl:pt-4 pt-8 flex justify-between">
                          <a href="/blog-detail"><p className="banner-font text-gray text-sm">Read more ➝</p></a>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4 text-left bg-white rounded-md xl:w-12/12 lg:w-12/12 md:w-12/12 xl:flex lg:flex md:flex flex-none  xl:py-4 shadow-md">
                      <div className="xl:w-3/12 lg:w-3/12 md:w-3/12">
                        <div className="rounded-r-full uppercase font-semibold text-xs xl:w-5/12 lg:w-5/12 md:w-5/12 w-4/12 xl:p-2 lg:p-1 md:p-1 p-1 text-center bg-sky-blue text-white xl:mt-8 mt-8">
                          JavaScript
                        </div>
                        <div className="xl:p-6 lg:p-6 md:p-4 p-4">
                          <div className="text-left">
                            <div className="text-sm banner-font font-normal">
                              📅 Jan 19, 2024
                            </div>
                            <div className="xl:pt-4 md:pt-2 pt-1 text-sm banner-font ">
                              ⬇️ 1 Min Read
                            </div>
                            <div className="xl:pt-4 md:pt-2 pt-1 text-sm banner-font ">
                              🥇 Difficulty:
                            </div>
                            {/* <div className="xl:pt-4 md:pt-2 pt-1 text-sm banner-font ">
                               ★ ★ ★
                            </div> */}
                          </div>
                        </div>
                      </div>
                      <div className="xl:w-9/12 lg:w-9/12 md:w-9/12 xl:pt-8 lg:pt-8 md:pt-4 xl:pr-8 lg:pr-8 pr-4 pb-4">
                        <a href="/blog-detail">
                          <h1 className="cursor-pointer banner-font xl:text-xl lg:text-xl md:text-lg text-base font-medium text-black">
                            JavaScript is easy to learn.
                          </h1>
                        </a>
                        <p className="headliheheightt banner-font text-gray xl:pt-2 lg:pt-4">
                          In the realm of technology blogging, captivating your
                          audience goes beyond just the written word.
                          Incorporating eye-catching CSS animations can elevate
                          your content and provide a dynamic user…
                        </p>
                         <div className="xl:pt-4 pt-8 flex justify-between">
                          <a href="/blog-detail"><p className="banner-font text-gray text-sm">Read more ➝</p></a>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4 text-left bg-white rounded-md xl:w-12/12 lg:w-12/12 md:w-12/12 xl:flex lg:flex md:flex flex-none  xl:py-4 shadow-md">
                      <div className="xl:w-3/12 lg:w-3/12 md:w-3/12">
                        <div className="rounded-r-full uppercase font-semibold text-xs xl:w-5/12 lg:w-5/12 md:w-5/12 w-4/12 xl:p-2 lg:p-1 md:p-1 p-1 text-center bg-sky-blue text-white xl:mt-8 mt-8">
                          JavaScript
                        </div>
                        <div className="xl:p-6 lg:p-6 md:p-4 p-4">
                          <div className="text-left">
                            <div className="text-sm banner-font font-normal">
                              📅 Jan 19, 2024
                            </div>
                            <div className="xl:pt-4 md:pt-2 pt-1 text-sm banner-font ">
                              ⬇️ 1 Min Read
                            </div>
                            <div className="xl:pt-4 md:pt-2 pt-1 text-sm banner-font ">
                              🥇 Difficulty:
                            </div>
                            {/* <div className="xl:pt-4 md:pt-2 pt-1 text-sm banner-font ">
                               ★ ★ ★
                            </div> */}
                          </div>
                        </div>
                      </div>
                      <div className="xl:w-9/12 lg:w-9/12 md:w-9/12 xl:pt-8 lg:pt-8 md:pt-4 xl:pr-8 lg:pr-8 pr-4 pb-4">
                        <a href="/blog-detail">
                          <h1 className="cursor-pointer banner-font xl:text-xl lg:text-xl md:text-lg text-base font-medium text-black">
                            Programming Language of the Web.
                          </h1>
                        </a>
                        <p className="headliheheightt banner-font text-gray xl:pt-2 lg:pt-4">
                          In the realm of technology blogging, captivating your
                          audience goes beyond just the written word.
                          Incorporating eye-catching CSS animations can elevate
                          your content and provide a dynamic user…
                        </p>
                         <div className="xl:pt-4 pt-8 flex justify-between">
                          <a href="/blog-detail"><p className="banner-font text-gray text-sm">Read more ➝</p></a>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {activeTab === 5 && (
                  <div>
                    <div className="text-left bg-white rounded-md xl:w-12/12 lg:w-12/12 md:w-12/12 xl:flex lg:flex md:flex flex-none  xl:py-4 shadow-md">
                      <div className="xl:w-3/12 lg:w-3/12 md:w-3/12">
                        <div className="rounded-r-full uppercase font-semibold text-xs xl:w-5/12 lg:w-5/12 md:w-5/12 w-4/12 xl:p-2 lg:p-1 md:p-1 p-1 text-center bg-sky-blue text-white xl:mt-8 mt-8">
                          databases
                        </div>
                        <div className="xl:p-6 lg:p-6 md:p-4 p-4">
                          <div className="text-left">
                            <div className="text-sm banner-font font-normal">
                              📅 Jan 19, 2024
                            </div>
                            <div className="xl:pt-4 md:pt-2 pt-1 text-sm banner-font ">
                              ⬇️ 1 Min Read
                            </div>
                            <div className="xl:pt-4 md:pt-2 pt-1 text-sm banner-font ">
                              🥇 Difficulty:
                            </div>
                            {/* <div className="xl:pt-4 md:pt-2 pt-1 text-sm banner-font ">
                               ★ ★ ★
                            </div> */}
                          </div>
                        </div>
                      </div>
                      <div className="xl:w-9/12 lg:w-9/12 md:w-9/12 xl:pt-8 lg:pt-8 md:pt-4 xl:pr-8 lg:pr-8 pr-4 pb-4">
                        <a href="/blog-detail">
                          <h1 className="cursor-pointer banner-font xl:text-xl lg:text-xl md:text-lg text-base font-medium text-black">
                            Collection of logically related information
                          </h1>
                        </a>
                        <p className="headliheheightt banner-font text-gray xl:pt-2 lg:pt-4">
                          In the realm of technology blogging, captivating your
                          audience goes beyond just the written word.
                          Incorporating eye-catching CSS animations can elevate
                          your content and provide a dynamic user…
                        </p>
                         <div className="xl:pt-4 pt-8 flex justify-between">
                          <a href="/blog-detail"><p className="banner-font text-gray text-sm">Read more ➝</p></a>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4 text-left bg-white rounded-md xl:w-12/12 lg:w-12/12 md:w-12/12 xl:flex lg:flex md:flex flex-none  xl:py-4 shadow-md">
                      <div className="xl:w-3/12 lg:w-3/12 md:w-3/12">
                        <div className="rounded-r-full uppercase font-semibold text-xs xl:w-5/12 lg:w-5/12 md:w-5/12 w-4/12 xl:p-2 lg:p-1 md:p-1 p-1 text-center bg-sky-blue text-white xl:mt-8 mt-8">
                          databases
                        </div>
                        <div className="xl:p-6 lg:p-6 md:p-4 p-4">
                          <div className="text-left">
                            <div className="text-sm banner-font font-normal">
                              📅 Jan 19, 2024
                            </div>
                            <div className="xl:pt-4 md:pt-2 pt-1 text-sm banner-font ">
                              ⬇️ 1 Min Read
                            </div>
                            <div className="xl:pt-4 md:pt-2 pt-1 text-sm banner-font ">
                              🥇 Difficulty:
                            </div>
                            {/* <div className="xl:pt-4 md:pt-2 pt-1 text-sm banner-font ">
                               ★ ★ ★
                            </div> */}
                          </div>
                        </div>
                      </div>
                      <div className="xl:w-9/12 lg:w-9/12 md:w-9/12 xl:pt-8 lg:pt-8 md:pt-4 xl:pr-8 lg:pr-8 pr-4 pb-4">
                        <a href="/blog-detail">
                          <h1 className="cursor-pointer banner-font xl:text-xl lg:text-xl md:text-lg text-base font-medium text-black">
                            Easily accessible, managed, and updated.
                          </h1>
                        </a>
                        <p className="headliheheightt banner-font text-gray xl:pt-2 lg:pt-4">
                          In the realm of technology blogging, captivating your
                          audience goes beyond just the written word.
                          Incorporating eye-catching CSS animations can elevate
                          your content and provide a dynamic user…
                        </p>
                         <div className="xl:pt-4 pt-8 flex justify-between">
                          <a href="/blog-detail"><p className="banner-font text-gray text-sm">Read more ➝</p></a>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4 text-left bg-white rounded-md xl:w-12/12 lg:w-12/12 md:w-12/12 xl:flex lg:flex md:flex flex-none  xl:py-4 shadow-md">
                      <div className="xl:w-3/12 lg:w-3/12 md:w-3/12">
                        <div className="rounded-r-full uppercase font-semibold text-xs xl:w-5/12 lg:w-5/12 md:w-5/12 w-4/12 xl:p-2 lg:p-1 md:p-1 p-1 text-center bg-sky-blue text-white xl:mt-8 mt-8">
                          databases
                        </div>
                        <div className="xl:p-6 lg:p-6 md:p-4 p-4">
                          <div className="text-left">
                            <div className="text-sm banner-font font-normal">
                              📅 Jan 19, 2024
                            </div>
                            <div className="xl:pt-4 md:pt-2 pt-1 text-sm banner-font ">
                              ⬇️ 1 Min Read
                            </div>
                            <div className="xl:pt-4 md:pt-2 pt-1 text-sm banner-font ">
                              🥇 Difficulty:
                            </div>
                            {/* <div className="xl:pt-4 md:pt-2 pt-1 text-sm banner-font ">
                               ★ ★ ★
                            </div> */}
                          </div>
                        </div>
                      </div>
                      <div className="xl:w-9/12 lg:w-9/12 md:w-9/12 xl:pt-8 lg:pt-8 md:pt-4 xl:pr-8 lg:pr-8 pr-4 pb-4">
                        <a href="/blog-detail">
                          <h1 className="cursor-pointer banner-font xl:text-xl lg:text-xl md:text-lg text-base font-medium text-black">
                            Accessed electronically from a computer system
                          </h1>
                        </a>
                        <p className="headliheheightt banner-font text-gray xl:pt-2 lg:pt-4">
                          In the realm of technology blogging, captivating your
                          audience goes beyond just the written word.
                          Incorporating eye-catching CSS animations can elevate
                          your content and provide a dynamic user…
                        </p>
                         <div className="xl:pt-4 pt-8 flex justify-between">
                          <a href="/blog-detail"><p className="banner-font text-gray text-sm">Read more ➝</p></a>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {activeTab === 6 && (
                  <div>
                    <div className="text-left bg-white rounded-md xl:w-12/12 lg:w-12/12 md:w-12/12 xl:flex lg:flex md:flex flex-none  xl:py-4 shadow-md">
                      <div className="xl:w-3/12 lg:w-3/12 md:w-3/12">
                        <div className="rounded-r-full font-semibold text-xs uppercase xl:w-6/12 lg:w-5/12 md:w-5/12 w-4/12 xl:p-2 lg:p-1 md:p-1 p-1 text-center bg-sky-blue text-white xl:mt-8 mt-8">
                          Deployment
                        </div>
                        <div className="xl:p-6 lg:p-6 md:p-4 p-4">
                          <div className="text-left">
                            <div className="text-sm banner-font font-normal">
                              📅 Jan 19, 2024
                            </div>
                            <div className="xl:pt-4 md:pt-2 pt-1 text-sm banner-font ">
                              ⬇️ 1 Min Read
                            </div>
                            <div className="xl:pt-4 md:pt-2 pt-1 text-sm banner-font ">
                              🥇 Difficulty:
                            </div>
                            {/* <div className="xl:pt-4 md:pt-2 pt-1 text-sm banner-font ">
                               ★ ★ ★
                            </div> */}
                          </div>
                        </div>
                      </div>
                      <div className="xl:w-9/12 lg:w-9/12 md:w-9/12 xl:pt-8 lg:pt-8 md:pt-4 xl:pr-8 lg:pr-8 pr-4 pb-4">
                        <a href="/blog-detail">
                          <h1 className="cursor-pointer banner-font xl:text-xl lg:text-xl md:text-lg text-base font-medium text-black">
                            Planning and Assessment.
                          </h1>
                        </a>
                        <p className="headliheheightt banner-font text-gray xl:pt-2 lg:pt-4">
                          In the realm of technology blogging, captivating your
                          audience goes beyond just the written word.
                          Incorporating eye-catching CSS animations can elevate
                          your content and provide a dynamic user…
                        </p>
                         <div className="xl:pt-4 pt-8 flex justify-between">
                          <a href="/blog-detail"><p className="banner-font text-gray text-sm">Read more ➝</p></a>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4 text-left bg-white rounded-md xl:w-12/12 lg:w-12/12 md:w-12/12 xl:flex lg:flex md:flex flex-none  xl:py-4 shadow-md">
                      <div className="xl:w-3/12 lg:w-3/12 md:w-3/12">
                        <div className="rounded-r-full font-semibold text-xs uppercase xl:w-6/12 lg:w-5/12 md:w-5/12 w-4/12 xl:p-2 lg:p-1 md:p-1 p-1 text-center bg-sky-blue text-white xl:mt-8 mt-8">
                          Deployment
                        </div>
                        <div className="xl:p-6 lg:p-6 md:p-4 p-4">
                          <div className="text-left">
                            <div className="text-sm banner-font font-normal">
                              📅 Jan 19, 2024
                            </div>
                            <div className="xl:pt-4 md:pt-2 pt-1 text-sm banner-font ">
                              ⬇️ 1 Min Read
                            </div>
                            <div className="xl:pt-4 md:pt-2 pt-1 text-sm banner-font ">
                              🥇 Difficulty:
                            </div>
                            {/* <div className="xl:pt-4 md:pt-2 pt-1 text-sm banner-font ">
                               ★ ★ ★
                            </div> */}
                          </div>
                        </div>
                      </div>
                      <div className="xl:w-9/12 lg:w-9/12 md:w-9/12 xl:pt-8 lg:pt-8 md:pt-4 xl:pr-8 lg:pr-8 pr-4 pb-4">
                        <a href="/blog-detail">
                          <h1 className="cursor-pointer banner-font xl:text-xl lg:text-xl md:text-lg text-base font-medium text-black">
                            Development or Configuration.
                          </h1>
                        </a>
                        <p className="headliheheightt banner-font text-gray xl:pt-2 lg:pt-4">
                          In the realm of technology blogging, captivating your
                          audience goes beyond just the written word.
                          Incorporating eye-catching CSS animations can elevate
                          your content and provide a dynamic user…
                        </p>
                         <div className="xl:pt-4 pt-8 flex justify-between">
                          <a href="/blog-detail"><p className="banner-font text-gray text-sm">Read more ➝</p></a>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4 text-left bg-white rounded-md xl:w-12/12 lg:w-12/12 md:w-12/12 xl:flex lg:flex md:flex flex-none  xl:py-4 shadow-md">
                      <div className="xl:w-3/12 lg:w-3/12 md:w-3/12">
                        <div className="rounded-r-full font-semibold text-xs uppercase xl:w-6/12 lg:w-5/12 md:w-5/12 w-4/12 xl:p-2 lg:p-1 md:p-1 p-1 text-center bg-sky-blue text-white xl:mt-8 mt-8">
                          Deployment
                        </div>
                        <div className="xl:p-6 lg:p-6 md:p-4 p-4">
                          <div className="text-left">
                            <div className="text-sm banner-font font-normal">
                              📅 Jan 19, 2024
                            </div>
                            <div className="xl:pt-4 md:pt-2 pt-1 text-sm banner-font ">
                              ⬇️ 1 Min Read
                            </div>
                            <div className="xl:pt-4 md:pt-2 pt-1 text-sm banner-font ">
                              🥇 Difficulty:
                            </div>
                            {/* <div className="xl:pt-4 md:pt-2 pt-1 text-sm banner-font ">
                               ★ ★ ★
                            </div> */}
                          </div>
                        </div>
                      </div>
                      <div className="xl:w-9/12 lg:w-9/12 md:w-9/12 xl:pt-8 lg:pt-8 md:pt-4 xl:pr-8 lg:pr-8 pr-4 pb-4">
                        <a href="/blog-detail">
                          <h1 className="cursor-pointer banner-font xl:text-xl lg:text-xl md:text-lg text-base font-medium text-black">
                            Testing and Quality Assurance.
                          </h1>
                        </a>
                        <p className="headliheheightt banner-font text-gray xl:pt-2 lg:pt-4">
                          In the realm of technology blogging, captivating your
                          audience goes beyond just the written word.
                          Incorporating eye-catching CSS animations can elevate
                          your content and provide a dynamic user…
                        </p>
                         <div className="xl:pt-4 pt-8 flex justify-between">
                          <a href="/blog-detail"><p className="banner-font text-gray text-sm">Read more ➝</p></a>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {activeTab === 7 && (
                  <div>
                    <div className="text-left bg-white rounded-md xl:w-12/12 lg:w-12/12 md:w-12/12 xl:flex lg:flex md:flex flex-none  xl:py-4 shadow-md">
                      <div className="xl:w-3/12 lg:w-3/12 md:w-3/12">
                        <div className="rounded-r-full font-semibold text-xs uppercase xl:w-6/12 lg:w-5/12 md:w-5/12 w-4/12 xl:p-2 lg:p-1 md:p-1 p-1 text-center bg-sky-blue text-white xl:mt-8 mt-8">
                          React js
                        </div>
                        <div className="xl:p-6 lg:p-6 md:p-4 p-4">
                          <div className="text-left">
                            <div className="text-sm banner-font font-normal">
                              📅 Jan 19, 2024
                            </div>
                            <div className="xl:pt-4 md:pt-2 pt-1 text-sm banner-font ">
                              ⬇️ 1 Min Read
                            </div>
                            <div className="xl:pt-4 md:pt-2 pt-1 text-sm banner-font ">
                              🥇 Difficulty:
                            </div>
                            {/* <div className="xl:pt-4 md:pt-2 pt-1 text-sm banner-font ">
                               ★ ★ ★
                            </div> */}
                          </div>
                        </div>
                      </div>
                      <div className="xl:w-9/12 lg:w-9/12 md:w-9/12 xl:pt-8 lg:pt-8 md:pt-4 xl:pr-8 lg:pr-8 pr-4 pb-4">
                        <a href="/blog-detail">
                          <h1 className="cursor-pointer banner-font xl:text-xl lg:text-xl md:text-lg text-base font-medium text-black">
                            It is Virtual DOM.
                          </h1>
                        </a>
                        <p className="headliheheightt banner-font text-gray xl:pt-2 lg:pt-4">
                          In the realm of technology blogging, captivating your
                          audience goes beyond just the written word.
                          Incorporating eye-catching CSS animations can elevate
                          your content and provide a dynamic user…
                        </p>
                         <div className="xl:pt-4 pt-8 flex justify-between">
                          <a href="/blog-detail"><p className="banner-font text-gray text-sm">Read more ➝</p></a>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4 text-left bg-white rounded-md xl:w-12/12 lg:w-12/12 md:w-12/12 xl:flex lg:flex md:flex flex-none  xl:py-4 shadow-md">
                      <div className="xl:w-3/12 lg:w-3/12 md:w-3/12">
                        <div className="rounded-r-full font-semibold text-xs uppercase xl:w-6/12 lg:w-5/12 md:w-5/12 w-4/12 xl:p-2 lg:p-1 md:p-1 p-1 text-center bg-sky-blue text-white xl:mt-8 mt-8">
                          React js
                        </div>
                        <div className="xl:p-6 lg:p-6 md:p-4 p-4">
                          <div className="text-left">
                            <div className="text-sm banner-font font-normal">
                              📅 Jan 19, 2024
                            </div>
                            <div className="xl:pt-4 md:pt-2 pt-1 text-sm banner-font ">
                              ⬇️ 1 Min Read
                            </div>
                            <div className="xl:pt-4 md:pt-2 pt-1 text-sm banner-font ">
                              🥇 Difficulty:
                            </div>
                            {/* <div className="xl:pt-4 md:pt-2 pt-1 text-sm banner-font ">
                               ★ ★ ★
                            </div> */}
                          </div>
                        </div>
                      </div>
                      <div className="xl:w-9/12 lg:w-9/12 md:w-9/12 xl:pt-8 lg:pt-8 md:pt-4 xl:pr-8 lg:pr-8 pr-4 pb-4">
                        <a href="/blog-detail">
                          <h1 className="cursor-pointer banner-font xl:text-xl lg:text-xl md:text-lg text-base font-medium text-black">
                            One way data binding.
                          </h1>
                        </a>
                        <p className="headliheheightt banner-font text-gray xl:pt-2 lg:pt-4">
                          In the realm of technology blogging, captivating your
                          audience goes beyond just the written word.
                          Incorporating eye-catching CSS animations can elevate
                          your content and provide a dynamic user…
                        </p>
                         <div className="xl:pt-4 pt-8 flex justify-between">
                          <a href="/blog-detail"><p className="banner-font text-gray text-sm">Read more ➝</p></a>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4 text-left bg-white rounded-md xl:w-12/12 lg:w-12/12 md:w-12/12 xl:flex lg:flex md:flex flex-none  xl:py-4 shadow-md">
                      <div className="xl:w-3/12 lg:w-3/12 md:w-3/12">
                        <div className="rounded-r-full font-semibold text-xs uppercase xl:w-6/12 lg:w-5/12 md:w-5/12 w-4/12 xl:p-2 lg:p-1 md:p-1 p-1 text-center bg-sky-blue text-white xl:mt-8 mt-8">
                          React js
                        </div>
                        <div className="xl:p-6 lg:p-6 md:p-4 p-4">
                          <div className="text-left">
                            <div className="text-sm banner-font font-normal">
                              📅 Jan 19, 2024
                            </div>
                            <div className="xl:pt-4 md:pt-2 pt-1 text-sm banner-font ">
                              ⬇️ 1 Min Read
                            </div>
                            <div className="xl:pt-4 md:pt-2 pt-1 text-sm banner-font ">
                              🥇 Difficulty:
                            </div>
                            {/* <div className="xl:pt-4 md:pt-2 pt-1 text-sm banner-font ">
                               ★ ★ ★
                            </div> */}
                          </div>
                        </div>
                      </div>
                      <div className="xl:w-9/12 lg:w-9/12 md:w-9/12 xl:pt-8 lg:pt-8 md:pt-4 xl:pr-8 lg:pr-8 pr-4 pb-4">
                        <a href="/blog-detail">
                          <h1 className="cursor-pointer banner-font xl:text-xl lg:text-xl md:text-lg text-base font-medium text-black">
                            Extension is .jsx
                          </h1>
                        </a>
                        <p className="headliheheightt banner-font text-gray xl:pt-2 lg:pt-4">
                          In the realm of technology blogging, captivating your
                          audience goes beyond just the written word.
                          Incorporating eye-catching CSS animations can elevate
                          your content and provide a dynamic user…
                        </p>
                         <div className="xl:pt-4 pt-8 flex justify-between">
                          <a href="/blog-detail"><p className="banner-font text-gray text-sm">Read more ➝</p></a>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {activeTab === 8 && (
                  <div>
                    <div className="text-left bg-white rounded-md xl:w-12/12 lg:w-12/12 md:w-12/12 xl:flex lg:flex md:flex flex-none  xl:py-4 shadow-md">
                      <div className="xl:w-3/12 lg:w-3/12 md:w-3/12">
                        <div className="rounded-r-full font-semibold text-xs uppercase xl:w-6/12 lg:w-5/12 md:w-5/12 w-4/12 xl:p-2 lg:p-1 md:p-1 p-1 text-center bg-sky-blue text-white xl:mt-8 mt-8">
                          Shopify
                        </div>
                        <div className="xl:p-6 lg:p-6 md:p-4 p-4">
                          <div className="text-left">
                            <div className="text-sm banner-font font-normal">
                              📅 Jan 19, 2024
                            </div>
                            <div className="xl:pt-4 md:pt-2 pt-1 text-sm banner-font ">
                              ⬇️ 1 Min Read
                            </div>
                            <div className="xl:pt-4 md:pt-2 pt-1 text-sm banner-font ">
                              🥇 Difficulty:
                            </div>
                            {/* <div className="xl:pt-4 md:pt-2 pt-1 text-sm banner-font ">
                               ★ ★ ★
                            </div> */}
                          </div>
                        </div>
                      </div>
                      <div className="xl:w-9/12 lg:w-9/12 md:w-9/12 xl:pt-8 lg:pt-8 md:pt-4 xl:pr-8 lg:pr-8 pr-4 pb-4">
                        <a href="/blog-detail">
                          <h1 className="cursor-pointer banner-font xl:text-xl lg:text-xl md:text-lg text-base font-medium text-black">
                            consists of various analytics
                          </h1>
                        </a>
                        <p className="headliheheightt banner-font text-gray xl:pt-2 lg:pt-4">
                          In the realm of technology blogging, captivating your
                          audience goes beyond just the written word.
                          Incorporating eye-catching CSS animations can elevate
                          your content and provide a dynamic user…
                        </p>
                         <div className="xl:pt-4 pt-8 flex justify-between">
                          <a href="/blog-detail"><p className="banner-font text-gray text-sm">Read more ➝</p></a>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4 text-left bg-white rounded-md xl:w-12/12 lg:w-12/12 md:w-12/12 xl:flex lg:flex md:flex flex-none  xl:py-4 shadow-md">
                      <div className="xl:w-3/12 lg:w-3/12 md:w-3/12">
                        <div className="rounded-r-full font-semibold text-xs uppercase xl:w-6/12 lg:w-5/12 md:w-5/12 w-4/12 xl:p-2 lg:p-1 md:p-1 p-1 text-center bg-sky-blue text-white xl:mt-8 mt-8">
                          Shopify
                        </div>
                        <div className="xl:p-6 lg:p-6 md:p-4 p-4">
                          <div className="text-left">
                            <div className="text-sm banner-font font-normal">
                              📅 Jan 19, 2024
                            </div>
                            <div className="xl:pt-4 md:pt-2 pt-1 text-sm banner-font ">
                              ⬇️ 1 Min Read
                            </div>
                            <div className="xl:pt-4 md:pt-2 pt-1 text-sm banner-font ">
                              🥇 Difficulty:
                            </div>
                            {/* <div className="xl:pt-4 md:pt-2 pt-1 text-sm banner-font ">
                               ★ ★ ★
                            </div> */}
                          </div>
                        </div>
                      </div>
                      <div className="xl:w-9/12 lg:w-9/12 md:w-9/12 xl:pt-8 lg:pt-8 md:pt-4 xl:pr-8 lg:pr-8 pr-4 pb-4">
                        <a href="/blog-detail">
                          <h1 className="cursor-pointer banner-font xl:text-xl lg:text-xl md:text-lg text-base font-medium text-black">
                            Generates impactful insights
                          </h1>
                        </a>
                        <p className="headliheheightt banner-font text-gray xl:pt-2 lg:pt-4">
                          In the realm of technology blogging, captivating your
                          audience goes beyond just the written word.
                          Incorporating eye-catching CSS animations can elevate
                          your content and provide a dynamic user…
                        </p>
                         <div className="xl:pt-4 pt-8 flex justify-between">
                          <a href="/blog-detail"><p className="banner-font text-gray text-sm">Read more ➝</p></a>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4 text-left bg-white rounded-md xl:w-12/12 lg:w-12/12 md:w-12/12 xl:flex lg:flex md:flex flex-none  xl:py-4 shadow-md">
                      <div className="xl:w-3/12 lg:w-3/12 md:w-3/12">
                        <div className="rounded-r-full font-semibold text-xs uppercase xl:w-6/12 lg:w-5/12 md:w-5/12 w-4/12 xl:p-2 lg:p-1 md:p-1 p-1 text-center bg-sky-blue text-white xl:mt-8 mt-8">
                          Shopify
                        </div>
                        <div className="xl:p-6 lg:p-6 md:p-4 p-4">
                          <div className="text-left">
                            <div className="text-sm banner-font font-normal">
                              📅 Jan 19, 2024
                            </div>
                            <div className="xl:pt-4 md:pt-2 pt-1 text-sm banner-font ">
                              ⬇️ 1 Min Read
                            </div>
                            <div className="xl:pt-4 md:pt-2 pt-1 text-sm banner-font ">
                              🥇 Difficulty:
                            </div>
                            {/* <div className="xl:pt-4 md:pt-2 pt-1 text-sm banner-font ">
                               ★ ★ ★
                            </div> */}
                          </div>
                        </div>
                      </div>
                      <div className="xl:w-9/12 lg:w-9/12 md:w-9/12 xl:pt-8 lg:pt-8 md:pt-4 xl:pr-8 lg:pr-8 pr-4 pb-4">
                        <a href="/blog-detail">
                          <h1 className="cursor-pointer banner-font xl:text-xl lg:text-xl md:text-lg text-base font-medium text-black">
                            Customer Activities, Marketing, Sales, and others
                          </h1>
                        </a>
                        <p className="headliheheightt banner-font text-gray xl:pt-2 lg:pt-4">
                          In the realm of technology blogging, captivating your
                          audience goes beyond just the written word.
                          Incorporating eye-catching CSS animations can elevate
                          your content and provide a dynamic user…
                        </p>
                         <div className="xl:pt-4 pt-8 flex justify-between">
                          <a href="/blog-detail"><p className="banner-font text-gray text-sm">Read more ➝</p></a>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
     
      <Footer2 />
    </>
  );
}
