import React, { useEffect, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import EClogowhite from "assets/img/EClogowhite.png";
const SliderPage1 = () => {
  const settings = {
    dots: false,
    infinite: true,
    centerPadding: "1rem",
    speed: 1900,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <section className="bg-grey xl:pt-20">
      <div className="container mx-auto overflow-hidden xl:pt-16 lg:pt-12 md:pt-16 pt-20">
        <Slider
          {...settings}
          className="xl:pt-0 lg:pt-20 pt-8 xl:mx-2 lg:mx-4 md:mx-0"
        >
          <div className="px-2">
            <div className="xl:h-450 lg:h-400 md:h-350 h-300 grid xl:grid-cols-1 xl:gap-4 md:grid-cols-1 p-1">
              <section
                style={{
                  // backgroundImage: `url(${elegantcarvebg})`,
                  backgroundColor: "#00244f",
                  backgroundSize: "cover",
                }}
                className="rounded-xl"
              >
                <div className="container xl:pt-64 lg:pt-52 md:pt-40 pt-28 mx-auto flex flex-wrap ">
                  <div className="w-full md:w-9/12 lg:w-8/12 xl:w-12/12 xl:ml-24 lg:ml-24 md:ml-16 ml-4">
                    <div className="flex mb-2">
                      <p className="banner-font px-2 text-xs text-white clone-blue uppercase">
                        featuredAAAA
                      </p>
                      <p className="banner-font px-2 text-xs text-white clone-blue uppercase ml-2">
                        Travel
                      </p>
                    </div>
                    <h1 className="xl:w-8/12 clone px-2 text-black md:text-2xl text-lg banner-font">
                      How to Spend the Perfect Day on Croatia’s <br></br> Most
                      Magical Island
                    </h1>
                  </div>
                </div>
              </section>
            </div>
          </div>

          <div className="px-2">
            <div className="xl:h-450 grid xl:grid-cols-1 xl:gap-4 md:grid-cols-1 xl:p-2 md:p-6 mb-8 xl:mb-0 p-6">
              <section
                style={{
                  // backgroundImage: `url(${elegantcarvebg})`,
                  backgroundColor: "#00244f",
                  width: "100%",
                  backgroundSize: "cover",
                }}
                className="rounded-xl"
              >
                <div className="container xl:pt-64 lg:pt-40 md:pt-16 pt-0 mx-auto justify-center flex flex-wrap ">
                  <div className="w-full md:w-9/12 lg:w-8/12 xl:w-12/12 ">
                    {/* <div className="center-items">
                      <img src={EClogowhite} className="xl:w-3/12" />
                    </div> */}
                    <div className="flex xl:mb-2">
                      <p className="banner-font px-2 text-xs text-white clone-blue uppercase xl:ml-24">
                        featuredbbbb
                      </p>
                      <p className="banner-font px-2 text-xs text-white clone-blue uppercase xl:ml-2">
                        Travel
                      </p>
                    </div>
                    <h1 className="xl:w-8/12 clone px-2 text-black text-left font-medium xl:text-2xl banner-font">
                      How to Spend the Perfect Day on Croatia’s <br></br> Most
                      Magical Island
                    </h1>
                  </div>
                </div>
              </section>
            </div>
          </div>

          <div className="px-2">
            <div className="px-4">
              <div className="xl:h-450 grid xl:grid-cols-1 xl:gap-4 md:grid-cols-1 xl:p-2 md:p-6 p-6">
                <section
                  style={{
                    // backgroundImage: `url(${elegantcarvebg})`,
                    backgroundColor: "#00244f",
                    width: "100%",
                    backgroundSize: "cover",
                  }}
                  className="rounded-xl"
                >
                  <div className="container xl:pt-64 lg:pt-40 md:pt-16 pt-0 mx-auto justify-center flex flex-wrap ">
                    <div className="w-full md:w-9/12 lg:w-8/12 xl:w-12/12 ">
                      {/* <div className="center-items">
                      <img src={EClogowhite} className="xl:w-3/12" />
                    </div> */}
                      <div className="flex xl:mb-2">
                        <p className="banner-font px-2 text-xs text-white clone-blue uppercase xl:ml-24">
                          featuredccc
                        </p>
                        <p className="banner-font px-2 text-xs text-white clone-blue uppercase xl:ml-2">
                          Travel
                        </p>
                      </div>
                      <h1 className="xl:w-8/12 clone px-2 text-black text-left font-medium xl:text-2xl banner-font">
                        How to Spend the Perfect Day on Croatia’s <br></br> Most
                        Magical Island
                      </h1>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>

          <div className="px-2">
            <div className="xl:h-450 grid xl:grid-cols-1 xl:gap-4 md:grid-cols-1 xl:p-2 md:p-6 mb-8 xl:mb-0 p-6">
              <section
                style={{
                  // backgroundImage: `url(${elegantcarvebg})`,
                  backgroundColor: "#00244f",
                  width: "100%",
                  backgroundSize: "cover",
                }}
                className="rounded-xl"
              >
                <div className="container xl:pt-64 lg:pt-40 md:pt-16 pt-0 mx-auto justify-center flex flex-wrap ">
                  <div className="w-full md:w-9/12 lg:w-8/12 xl:w-12/12 ">
                    {/* <div className="center-items">
                      <img src={EClogowhite} className="xl:w-3/12" />
                    </div> */}
                    <div className="flex xl:mb-2">
                      <p className="banner-font px-2 text-xs text-white clone-blue uppercase xl:ml-24">
                        featureddddd
                      </p>
                      <p className="banner-font px-2 text-xs text-white clone-blue uppercase xl:ml-2">
                        Travel
                      </p>
                    </div>
                    <h1 className="xl:w-8/12 clone px-2 text-black text-left font-medium xl:text-2xl banner-font">
                      How to Spend the Perfect Day on Croatia’s <br></br> Most
                      Magical Island
                    </h1>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </Slider>
      </div>
      <div className="xl:block md:block lg:block sm:block hidden xl:-mt-28 lg:-mt-28 md:-mt-24">
        <div className="container mx-auto  px-4 xl:px-52 lg:px-32 xl:pb-16 md:px-12 lg:py-8 py-6">
          <div className="grid xl:grid-cols-7 relative md:grid-cols-7 grid-cols-3 bg-white border shadow-md rounded-full p-4 xl:p-8 lg:p-8 md:p-8 xl:px-20">
            <div className="center-items">
              <img
                src="https://i.ibb.co/2Z0YvWx/html.png"
                className="rounded-full bg-orange xl:w-16 lg:w-16 md:w-12 xl:p-0"
              />
              <p className="banner-font font-medium text-sm text-black xl:pt-3">
                HTML
              </p>
            </div>
            <div className="center-items">
              <img
                src="https://i.ibb.co/hByJVD8/react-removebg-preview.png"
                className="rounded-full bg-black xl:w-16 lg:w-16 md:w-12 p-3"
              />
              <p className="banner-font font-medium text-sm text-black xl:pt-3">
                React
              </p>
            </div>
            <div className="center-items">
              <img
                src="https://i.ibb.co/gF7rz0Q/code-icon.png"
                className="rounded-full bg-blue xl:w-16 lg:w-16 md:w-12 xl:p-0"
              />
              <p className="banner-font font-medium text-sm text-black xl:pt-3">
                Fundamentals
              </p>
            </div>
            <div className="center-items">
              <img
                src="https://i.ibb.co/QrHkTkm/css-icon.png"
                className="rounded-full bg-sky-blue xl:w-16 lg:w-16 md:w-12 xl:p-0"
              />
              <p className="banner-font font-medium text-sm text-black xl:pt-3">
                CSS
              </p>
            </div>
            <div className="center-items">
              <img
                src="https://i.ibb.co/tq6YP7P/databases-icon.png"
                className="rounded-full bg-blue xl:w-16 lg:w-16 md:w-12 xl:p-0"
              />
              <p className="banner-font font-medium text-sm text-black xl:pt-3">
                Databases
              </p>
            </div>
            <div className="center-items">
              <img
                src="https://i.ibb.co/5vXPDd9/deploy-icon.png"
                className="rounded-full bg-orange xl:w-16 lg:w-16 md:w-12 xl:p-0"
              />
              <p className="banner-font font-medium text-sm text-black xl:pt-3">
                Deployment
              </p>
            </div>
            <div className="center-items">
              <img
                src="https://i.ibb.co/4Y19sz3/shopify-removebg-preview.png"
                className="rounded-full bg-black xl:w-16 lg:w-16 md:w-12 p-3"
              />
              <p className="banner-font font-medium text-sm text-black xl:pt-3">
                Shopify
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="xl:hidden md:hidden lg:hidden block -mt-20">
        <div className="container mx-auto  px-4 xl:px-44 lg:px-32 md:px-12 lg:py-8 py-6">
          <div className="grid xl:grid-cols-7 relative md:grid-cols-7 grid-cols-4 border shadow-md bg-white rounded-xll p-4 xl:p-8 lg:p-8 md:p-8 xl:px-12">
            <div className="center-items">
              <img
                src="https://i.ibb.co/2Z0YvWx/html.png"
                className="rounded-full bg-orange xl:w-16 lg:w-16 md:w-12 w-8 xl:p-0"
              />
              <p className="banner-font font-semibold text-sm xl:pt-3">HTML</p>
            </div>
            <div className="center-items">
              <img
                src="https://i.ibb.co/gF7rz0Q/code-icon.png"
                className="rounded-full bg-blue xl:w-16 lg:w-16 md:w-12 w-8 xl:p-0"
              />
              <p className="banner-font font-semibold text-sm xl:pt-3">
                Fundamentals
              </p>
            </div>
            <div className="center-items">
              <img
                src="https://i.ibb.co/QrHkTkm/css-icon.png"
                className="rounded-full bg-sky-blue xl:w-16 lg:w-16 md:w-12  w-8 xl:p-0"
              />
              <p className="banner-font font-semibold text-sm xl:pt-3">CSS</p>
            </div>
            <div className="center-items">
              <img
                src="https://i.ibb.co/tq6YP7P/databases-icon.png"
                className="rounded-full bg-blue xl:w-16 lg:w-16 md:w-12 w-8 xl:p-0"
              />
              <p className="banner-font font-semibold text-sm xl:pt-3">
                Databases
              </p>
            </div>
            <div className="center-items mt-4">
              <img
                src="https://i.ibb.co/5vXPDd9/deploy-icon.png"
                className="rounded-full bg-black xl:w-16 lg:w-16 md:w-12 w-8 xl:p-0"
              />
              <p className="banner-font font-semibold text-sm xl:pt-3">
                Deployment
              </p>
            </div>
            <div className="center-items">
              <p className="banner-font font-semibold xl:text-sm xl:pt-3">
                Or...
              </p>
            </div>
            <div className="xl:mt-6 lg:mt-8 mt-6 text-gray">
              <button className="blue-gradient xl:text-sm md:text-sm text-xs banner-font text-white xl:px-6 px-3 py-2 md:px-2 md:py-2 lg:px-4 lg:py-2 xl:py-2 font-medium rounded-md ">
                Explore All
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SliderPage1;
