import React from "react";
import clients1 from "assets/img/clients1.png";
import clients2 from "assets/img/clients2.png";
import clients3 from "assets/img/clients3.png";
import clients6 from "assets/img/clients6.png";
import clients7 from "assets/img/clients7.png";
import clients16 from "assets/img/clients16.png";

export default function Imagescroll() {
  return (
    <>
      <section className="">
        <div className="container mx-auto ">
          <div className="gallery">
            <div className="block-33 display--inline-top">
              <div className="gutter relative">
                <div className="gallery-h">
                  <div className="gallery-image relative">
                    <div className="gallery-image__img relative">
                      <div
                        className="fill-dimensions cover-img"
                        style={{ backgroundImage: `url('https://i.ibb.co/xCWS1fT/clrgirl.png` }}
                      ></div>
                    </div>
                  </div>
                  <div className="gallery-image">
                    <div className="gallery-image__img relative">
                      <div
                        className="fill-dimensions cover-img"
                        style={{ backgroundImage: `url('https://i.ibb.co/xCWS1fT/clrgirl.png` }}
                      ></div>
                    </div>
                  </div>
                  <div className="gallery-image">
                    <div className="gallery-image__img relative">
                      <div
                        className="fill-dimensions cover-img"
                        style={{ backgroundImage: `url('https://i.ibb.co/xCWS1fT/clrgirl.png` }}
                      ></div>
                    </div>
                  </div>
                  <div className="gallery-image">
                    <div className="gallery-image__img relative">
                      <div
                        className="fill-dimensions cover-img"
                        style={{ backgroundImage: `url('https://i.ibb.co/xCWS1fT/clrgirl.png` }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="block-33 display--inline-top">
              <div className="gutter relative">
                <div className="gallery-h gallery-h--tb">
                  <div className="gallery-image relative">
                    <div className="gallery-image__img relative">
                      <div
                        className="fill-dimensions cover-img"
                        style={{ backgroundImage: `url('https://i.ibb.co/xCWS1fT/clrgirl.png` }}
                      ></div>
                    </div>
                  </div>
                  <div className="gallery-image">
                    <div className="gallery-image__img relative">
                      <div
                        className="fill-dimensions cover-img"
                        style={{ backgroundImage: `url('https://i.ibb.co/xCWS1fT/clrgirl.png` }}
                      ></div>
                    </div>
                  </div>
                  <div className="gallery-image">
                    <div className="gallery-image__img relative">
                      <div
                        className="fill-dimensions cover-img"
                        style={{ backgroundImage: `url('https://i.ibb.co/xCWS1fT/clrgirl.png` }}
                      ></div>
                    </div>
                  </div>
                  <div className="gallery-image">
                    <div className="gallery-image__img relative">
                      <div
                        className="fill-dimensions cover-img"
                        style={{ backgroundImage: `url('https://i.ibb.co/xCWS1fT/clrgirl.png` }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="block-33 display--inline-top">
              <div className="gutter relative">
                <div className="gallery-h">
                  <div className="gallery-image relative">
                    <div className="gallery-image__img relative">
                      <div
                        className="fill-dimensions cover-img"
                        style={{ backgroundImage: `url('https://i.ibb.co/xCWS1fT/clrgirl.png` }}
                      ></div>
                    </div>
                  </div>
                  <div className="gallery-image">
                    <div className="gallery-image__img relative">
                      <div
                        className="fill-dimensions cover-img"
                        style={{ backgroundImage: `url('https://i.ibb.co/xCWS1fT/clrgirl.png` }}
                      ></div>
                    </div>
                  </div>
                  <div className="gallery-image">
                    <div className="gallery-image__img relative">
                      <div
                        className="fill-dimensions cover-img"
                        style={{ backgroundImage: `url('https://i.ibb.co/xCWS1fT/clrgirl.png` }}
                      ></div>
                    </div>
                  </div>
                  <div className="gallery-image">
                    <div className="gallery-image__img relative">
                      <div
                        className="fill-dimensions cover-img"
                        style={{ backgroundImage: `url('https://i.ibb.co/xCWS1fT/clrgirl.png` }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
