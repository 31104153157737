import React, { useEffect, useRef, useState } from "react";
import Footer2 from "components/Footers/Footer2";
import IndexNavbar2 from "components/Navbars/IndexNavbar2";

export default function Testimonials() {
  return (
    <>
      <IndexNavbar2 fixed />

      <section className="bg-gray ">
        <div className="container mx-auto overflow-hidden px-4 xl:pt-44 xl:px-20 lg:py-8 py-6">
          <h2 className="xl:px-72 text-center font-medium xl:text-4xl lg:text-2xl md:text-2xl text-xl lg:text-3xl md:text-2xl text-xl text-black banner-font">
            Testimonials
          </h2>

          <p className="xl:px-84 lg:px-56 md:px-32 px-4 lg:px-48 md:px-32 xl:mt-2 md:mt-2 mt-1 text-center xl:text-lg text-base  text-gray headlineheightt banner-font">
            We offer a wide range of hosting solutions, from web hosting to
            dedic
          </p>
          <div className="grid grid-cols-4 xl:gap-2 xl:pt-12">
            <div className="shadow-xl xl:h-31">
              <img src="https://i.ibb.co/30hL3PQ/dan-craddock.png" />
              <div className="bg-white xl:p-6">
                <img src="https://i.ibb.co/GPFpY0R/nz-logo.png" />
                <p className="text-gray banner-font xl:text-base1 pt-8">
                  At Catalyst, we found Zoho Analytics private label as our
                  perfect solution.
                </p>
                <p className="text-blue pt-6 font-semibold xl:text-base1">
                  Dan Craddock
                </p>
                <p className="text-xs text-gray banner-font">
                  Co-Founder, White Label Solution
                </p>
                <a>
                  <p className="text-gray pt-4">Read more ➝</p>
                </a>
              </div>
            </div>

            <div className="shadow-xl xl:h-26">
              <img
                src="https://i.ibb.co/txj90cJ/reedermedia-logo.png"
                className="xl:py-10 xl:px-20 bgg"
              />
              <div className="bg-white xl:p-6">
                {/* <img src="https://i.ibb.co/GPFpY0R/nz-logo.png" /> */}
                <p className="text-gray banner-font xl:text-base1 pt-0">
                  How a US-based publishing firm reduced its processing time by
                  75% and eliminated postage expenses using Zoho Subscriptions.
                </p>
                <p className="text-blue pt-6 font-semibold xl:text-base1">
                  Dan Craddock
                </p>
                <p className="text-xs text-gray banner-font">
                  Co-Founder, White Label Solution
                </p>
                <a>
                  <p className="text-gray pt-4">Read more ➝</p>
                </a>
              </div>
            </div>
            <div className="shadow-xl xl:h-33">
              <img
                src="https://i.ibb.co/Tv8FLg5/catalyst.png"
                className="xl:py-13 xl:px-8 bg-light-green"
              />
              <div className="bg-white xl:p-6">
                {/* <img src="https://i.ibb.co/GPFpY0R/nz-logo.png" /> */}
                <p className="text-gray banner-font xl:text-base1 pt-0">
                  Our favorite thing about Zoho Backstage is the ability to
                  track ticket sales and watch the money come in! The ease of
                  use in terms of editing the agenda thing about Zoho Backstage
                  and speaker details is also impressive.
                </p>
                <p className="text-blue pt-6 font-semibold xl:text-base1">
                  Dan Craddock
                </p>
                <p className="text-xs text-gray banner-font">
                  Co-Founder, White Label Solution
                </p>
                <a>
                  <p className="text-gray pt-4">Read more ➝</p>
                </a>
              </div>
            </div>
            <div className="shadow-xl">
              <img src="https://i.ibb.co/ZJMNhL2/hiral-pancholi.png" />
              <div className="bg-white xl:p-6">
                {/* <img src="https://i.ibb.co/GPFpY0R/nz-logo.png" /> */}
                <p className="text-gray banner-font xl:text-base1 pt-8">
                  I came across Zoho Books a couple of years ago and there was
                  no doubt that it was a match made in heaven. I use almost
                  every feature in Zoho Books. It has saved me and my team a lot
                  of time. In fact, I recommended Zoho Books to many of my
                  vendors and customers and they thank me today for it. So, go
                  ahead, try Zoho Books, I am sure you will not find anything
                  like it out there.
                </p>
                <p className="text-blue pt-6 font-semibold xl:text-base1">
                  Dan Craddock
                </p>
                <p className="text-xs text-gray banner-font">
                  Co-Founder, White Label Solution
                </p>
                <a>
                  <p className="text-gray pt-4">Read more ➝</p>
                </a>
              </div>
            </div>
            <div className="shadow-xl xl:-mt-52 xl:h-31">
              <img src="https://i.ibb.co/ZBxjSFs/lubrication-banner.jpg" />
              <div className="bg-white xl:p-6">
                <img src="https://i.ibb.co/GPFpY0R/nz-logo.png" />
                <p className="text-gray banner-font xl:text-base1 pt-8">
                  At Catalyst, we found Zoho Analytics private label as our
                  perfect solution.
                </p>
                <p className="text-blue pt-6 font-semibold xl:text-base1">
                  Dan Craddock
                </p>
                <p className="text-xs text-gray banner-font">
                  Co-Founder, White Label Solution
                </p>
                <a>
                  <p className="text-gray pt-4">Read more ➝</p>
                </a>
              </div>
            </div>
            <div className="shadow-xl xl:h-26 xl:-mt-68">
              <img
                src="https://i.ibb.co/z7Yz5TF/hash-tag-logo-1.png"
                className="xl:py-14 xl:px-20 bggg"
              />
              <div className="bg-white xl:p-6">
                {/* <img src="https://i.ibb.co/GPFpY0R/nz-logo.png" /> */}
                <p className="text-gray banner-font xl:text-base1 pt-0 ">
                  How a US-based publishing firm reduced its processing time by
                  75% and eliminated postage expenses using Zoho Subscriptions.
                </p>
                <p className="text-blue pt-6 font-semibold xl:text-base1">
                  Dan Craddock
                </p>
                <p className="text-xs text-gray banner-font">
                  Co-Founder, White Label Solution
                </p>
                <a>
                  <p className="text-gray pt-4">Read more ➝</p>
                </a>
              </div>
            </div>
            <div className="shadow-xl xl:-mt-42 xl:h-33">
              <img
                src="https://i.ibb.co/zn3V9sj/zb-espresso-world-logo.png"
                className="xl:py-10 xl:px-16 bg-light-redd"
              />
              <div className="bg-white xl:p-6">
                {/* <img src="https://i.ibb.co/GPFpY0R/nz-logo.png" /> */}
                <p className="text-gray banner-font xl:text-base1 pt-8">
                  Our favorite thing about Zoho Backstage is the ability to
                  track ticket sales and watch the money come in! The ease of
                  use in terms of editing the agenda and speaker details is also
                  impressive.
                </p>
                <p className="text-blue pt-6 font-semibold xl:text-base1">
                  Dan Craddock
                </p>
                <p className="text-xs text-gray banner-font">
                  Co-Founder, White Label Solution
                </p>
                <a>
                  <p className="text-gray pt-4">Read more ➝</p>
                </a>
              </div>
            </div>

            <div className="shadow-xl">
              <img src="https://i.ibb.co/ZJMNhL2/hiral-pancholi.png" />
              <div className="bg-white xl:p-6">
                {/* <img src="https://i.ibb.co/GPFpY0R/nz-logo.png" /> */}
                <p className="text-gray banner-font xl:text-base1 pt-8">
                  I came across Zoho Books a couple of years ago and there was
                  no doubt that it was a match made in heaven. I use almost
                  every feature in Zoho Books. It has saved me and my team a lot
                  of time. In fact, I recommended Zoho Books to many of my
                  vendors and customers and they thank me today for it. So, go
                  ahead, try Zoho Books, I am sure you will not find anything
                  like it out there.
                </p>
                <p className="text-blue pt-6 font-semibold xl:text-base1">
                  Dan Craddock
                </p>
                <p className="text-xs text-gray banner-font">
                  Co-Founder, White Label Solution
                </p>
                <a>
                  <p className="text-gray pt-4">Read more ➝</p>
                </a>
              </div>
            </div>
            <div className="shadow-xl xl:-mt-96">
              <img src="https://i.ibb.co/ZJMNhL2/hiral-pancholi.png" />
              <div className="bg-white xl:p-6">
                {/* <img src="https://i.ibb.co/GPFpY0R/nz-logo.png" /> */}
                <p className="text-gray banner-font xl:text-base1 pt-0">
                  I came across Zoho Books a couple of years ago and there was
                  no doubt that it was a match made in heaven. I use almost
                  every feature in Zoho Books. It has saved me and my team a lot
                  of time. In fact, I recommended Zoho Books to many of my
                  vendors and customers and they thank me today for it. So, go
                  ahead, try Zoho Books, I am sure you will not find anything
                  like it out there.
                </p>
                <p className="text-blue pt-6 font-semibold xl:text-base1">
                  Dan Craddock
                </p>
                <p className="text-xs text-gray banner-font">
                  Co-Founder, White Label Solution
                </p>
                <a>
                  <p className="text-gray pt-4">Read more ➝</p>
                </a>
              </div>
            </div>
            <div className="shadow-xl xl:h-33 xl:-mt-max">
              <img
                src="https://i.ibb.co/Tv8FLg5/catalyst.png"
                className="xl:py-13 xl:px-8 bg-light-green"
              />
              <div className="bg-white xl:p-6">
                {/* <img src="https://i.ibb.co/GPFpY0R/nz-logo.png" /> */}
                <p className="text-gray banner-font xl:text-base1 pt-0">
                  Our favorite thing about Zoho Backstage is the ability to
                  track ticket sales and watch the money come in! The ease of
                  use in terms of editing the agenda and speaker details is also
                  impressive.
                </p>
                <p className="text-blue pt-6 font-semibold xl:text-base1">
                  Dan Craddock
                </p>
                <p className="text-xs text-gray banner-font">
                  Co-Founder, White Label Solution
                </p>
                <a>
                  <p className="text-gray pt-4">Read more ➝</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer2 />
    </>
  );
}
