import React, { useState } from "react";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer2 from "components/Footers/Footer2";
import logo from "assets/img/logo.svg";
import little from "assets/img/little.png";
import bg from "assets/img/bg.svg";
import Imagescroll from "./Imagescroll";
import IndexNavbar2 from "components/Navbars/IndexNavbar2";
import bgnew from "assets/img/bgnew.svg";

export default function OurTeam() {
  const [isHoveredFirst, setIsHoveredFirst] = useState(false);
  const [isHoveredSecond, setIsHoveredSecond] = useState(false);
  const [isHoveredThird, setIsHoveredThird] = useState(false);
  const [isHoveredFour, setIsHoveredFour] = useState(false);
  const [isHoveredFive, setIsHoveredFive] = useState(false);
  const [isHoveredSix, setIsHoveredSix] = useState(false);
  const [isHoveredSeven, setIsHoveredSeven] = useState(false);
  const [isHoveredEight, setIsHoveredEight] = useState(false);
  const [isHoveredNine, setIsHoveredNine] = useState(false);

  const handleMouseEnterFirst = () => {
    setIsHoveredFirst(true);
  };

  const handleMouseLeaveFirst = () => {
    setIsHoveredFirst(false);
  };

  const handleMouseEnterSecond = () => {
    setIsHoveredSecond(true);
  };

  const handleMouseLeaveSecond = () => {
    setIsHoveredSecond(false);
  };

  const handleMouseEnterThird = () => {
    setIsHoveredThird(true);
  };

  const handleMouseLeaveThird = () => {
    setIsHoveredThird(false);
  };

  const handleMouseEnterFour = () => {
    setIsHoveredFour(true);
  };

  const handleMouseLeaveFour = () => {
    setIsHoveredFour(false);
  };

  const handleMouseEnterFive = () => {
    setIsHoveredFive(true);
  };

  const handleMouseLeaveFive = () => {
    setIsHoveredFive(false);
  };

  const handleMouseEnterSix = () => {
    setIsHoveredSix(true);
  };

  const handleMouseLeaveSix = () => {
    setIsHoveredSix(false);
  };

  const handleMouseEnterSeven = () => {
    setIsHoveredSeven(true);
  };

  const handleMouseLeaveSeven = () => {
    setIsHoveredSeven(false);
  };

  const handleMouseEnterEight = () => {
    setIsHoveredEight(true);
  };

  const handleMouseLeaveEight = () => {
    setIsHoveredEight(false);
  };

  const handleMouseEnterNine = () => {
    setIsHoveredNine(true);
  };

  const handleMouseLeaveNine = () => {
    setIsHoveredNine(false);
  };

  return (
    <>
      <IndexNavbar2 fixed />
      {/* <section className="background-team xl:pt-20 lg:pt-24 md:pt-20 pt-24 xl:px-12">
        <div className="container mx-auto overflow-hidden px-4">
          <div className="grid xl:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-4 items-center xl:ml-0 lg:ml-20">
            <div className="">
              <h2 className="xl:pt-4 text-left xl:text-5xl lg:text-3xl md:text-2xl text-2xl text-white banner-font">
                Humans of Elegant Carve
              </h2>
              <p className="xl:mt-4 lg:mt-3 md:mt-3 mt-2 xl:pb-4 text-left xl:text-base1 text-base text-white leading-relaxed headlineheightt banner-font">
                An insider’s perspective into the lives of the people behind our
                craft and how they built the very foundation of our culture and
                products.
              </p>
            </div>
            <div className="xl:ml-20 xl:mt-0 md:mt-0 mt-4">
              <Imagescroll/>
            </div>
          </div>
        </div>
      </section> */}

      <section className="bg-white xl:pt-24">
        <div className="container mx-auto overflow-hidden center-items xl:py-16 lg:py-8 py-6 xl:px-12">
          <h2 className="font-medium xl:text-4xl lg:text-3xl md:text-2xl text-2xl text-black banner-font">
            06+ years
          </h2>
          <p className="mt-3 xl:px-96 xl:text-base1 text-base text-blue banner-font">
            Of running a business has taught us many things
          </p>
          <p className="mt-2 xl:px-84 lg:px-56 md:px-32 px-4 xl:text-base1 text-base text-gray leading-relaxed banner-font">
            One of which is: long-term investment in people results in long-term
            commitment. We’re humbled by many of our colleagues who have chosen
            to stay with us for over a decade.
          </p>
        </div>
        <section
          style={{
            backgroundImage: `url(${little})`,
            width: "100%",
            backgroundSize: "cover",
          }}
          className="xl:h-max xl:-mt-20"
        ></section>
      </section>

      <section className="bg-white">
        <div className="container mx-auto overflow-hidden center-items xl:py-20 lg:py-8 py-6 xl:px-12">
          <h2 className="xl:pt-4 font-medium xl:text-4xl lg:text-3xl md:text-2xl text-2xl text-black banner-font">
            Our Team
          </h2>
          <div className="xl:pt-20 xl:px-maxi lg:pt-12 md:pt-8 pt-8 grid xl:grid-cols-1 gap-4 lg:grid-cols-3 md:grid-cols-3 grid-cols-1">
            {/* First Hover */}

            {/* <div
              style={{
                backgroundImage: `url(${bgnew})`,
              }}
            >
              <div
                className="relative w-full center-items shadow-on-hover border cursor-pointer rounded-lg"
                onMouseEnter={handleMouseEnterFirst}
                onMouseLeave={handleMouseLeaveFirst}
              >
                <img
                  src={
                    isHoveredFirst
                      ? "https://i.ibb.co/2vS380J/Screenshot-635.png"
                      : "https://i.ibb.co/HVrwLFJ/Screenshot-635.png"
                  }
                  onMouseEnter={handleMouseEnterFirst}
                  onMouseLeave={handleMouseLeaveFirst}
                  alt="Vijay Kumar"
                  className="xl:-mt-12 xl:w-44"
                />

                <div className="xl:p-4 banner-font rounded-b-xl">
                  <span className="font-semibold uppercase xl:text-base1 text-blue xl:pb-4">
                    ramkumar ramkrishnan
                  </span>
                  <h1
                    className={
                      isHoveredFirst
                        ? "animate-slide-upp pt-2"
                        : "animate-slide-up pt-2"
                    }
                  >
                    {isHoveredFirst ? "Founder and CEO" : "Founder and CEO"}
                  </h1>
                </div>
              </div>
            </div> */}

            <div
              style={{
                backgroundImage: `url(${bg})`,
                width: "100%",
                backgroundSize: "cover",
              }}
              className="border rounded-xl shadow-on-hover"
            >
              <div
                className="relative w-full cursor-pointer "
                onMouseEnter={handleMouseEnterFirst}
                onMouseLeave={handleMouseLeaveFirst}
              >
                <img
                  src={
                    isHoveredFirst
                      ? "https://i.ibb.co/xCWS1fT/clrgirl.png"
                      : "https://i.ibb.co/PMQfM7M/bwgirl.png"
                  }
                  onMouseEnter={handleMouseEnterFirst}
                  onMouseLeave={handleMouseLeaveFirst}
                  alt="Kavya Pai"
                  className={`xl:-mt-12 transition-transform duration-300 transform ${
                    isHoveredFirst ? "scale-105" : ""
                  }`}
                />
                <div className="bg-white xl:p-4 banner-font">
                  <span className="font-semibold uppercase xl:text-sm text-blue xl:pb-4">
                    ramkumar ramakrishnan
                  </span>
                  <h1
                    className={
                      isHoveredFirst
                        ? "animate-slide-upp pt-2"
                        : "animate-slide-up pt-2"
                    }
                  >
                    {isHoveredFirst
                      ? "Every failure provides valuable lessons for future endeavors."
                      : "Senior Product Manager of Zoho limited"}
                  </h1>
                </div>
              </div>
            </div>
          </div>

          <div className="xl:pt-20 xl:px-80 lg:pt-12 md:pt-8 pt-8 grid xl:grid-cols-2 xl:gap-8 gap-4 lg:grid-cols-3 md:grid-cols-3 grid-cols-1">
            {/* Second Hover */}
            <div
              style={{
                backgroundImage: `url(${bg})`,
                width: "100%",
                backgroundSize: "cover",
              }}
              className="border rounded-xl shadow-on-hover"
            >
              <div
                className="relative w-full cursor-pointer "
                onMouseEnter={handleMouseEnterSecond}
                onMouseLeave={handleMouseLeaveSecond}
              >
                <img
                  src={
                    isHoveredSecond
                      ? "https://i.ibb.co/xCWS1fT/clrgirl.png"
                      : "https://i.ibb.co/PMQfM7M/bwgirl.png"
                  }
                  onMouseEnter={handleMouseEnterSecond}
                  onMouseLeave={handleMouseLeaveSecond}
                  alt="Kavya Pai"
                  className={`xl:-mt-12 transition-transform duration-300 transform ${
                    isHoveredSecond ? "scale-105" : ""
                  }`}
                />
                <div className="bg-white xl:p-4 banner-font">
                  <span className="font-semibold uppercase xl:text-sm text-blue xl:pb-4">
                    anusha muthusamy
                  </span>
                  <h1
                    className={
                      isHoveredSecond
                        ? "animate-slide-upp pt-2"
                        : "animate-slide-up pt-2"
                    }
                  >
                    {isHoveredSecond
                      ? "Every failure provides valuable lessons for future endeavors."
                      : "Senior Product Manager of Zoho limited"}
                  </h1>
                </div>
              </div>
            </div>

            {/* Third Hover */}
            <div
              style={{
                backgroundImage: `url(${bg})`,
                width: "100%",
                backgroundSize: "cover",
              }}
              className="border rounded-xl shadow-on-hover"
            >
              <div
                className="relative w-full cursor-pointer "
                onMouseEnter={handleMouseEnterThird}
                onMouseLeave={handleMouseLeaveThird}
              >
                <img
                  src={
                    isHoveredThird
                      ? "https://i.ibb.co/xCWS1fT/clrgirl.png"
                      : "https://i.ibb.co/PMQfM7M/bwgirl.png"
                  }
                  onMouseEnter={handleMouseEnterThird}
                  onMouseLeave={handleMouseLeaveThird}
                  alt="Kavya Pai"
                  className={`xl:-mt-12 transition-transform duration-300 transform ${
                    isHoveredThird ? "scale-105" : ""
                  }`}
                />
                <div className="bg-white xl:p-4 banner-font">
                  <span className="font-semibold uppercase xl:text-sm text-blue xl:pb-4">
                    tambidurai ramakrishnan
                  </span>
                  <h1
                    className={
                      isHoveredThird
                        ? "animate-slide-upp pt-2"
                        : "animate-slide-up pt-2"
                    }
                  >
                    {isHoveredThird
                      ? "Every failure provides valuable lessons for future endeavors."
                      : "Senior Product Manager of Zoho limited"}
                  </h1>
                </div>
              </div>
            </div>
          </div>

          {/* Employees images start */}

          <div className="xl:pt-24 xl:px-12 lg:pt-12 md:pt-8 pt-8 grid xl:grid-cols-6 gap-4 lg:grid-cols-3 md:grid-cols-3 grid-cols-1">
            <div
              style={{
                backgroundImage: `url(${bg})`,
                width: "100%",
                backgroundSize: "cover",
              }}
              className="border rounded-xl shadow-on-hover"
            >
              <div
                className="relative w-full cursor-pointer "
                onMouseEnter={handleMouseEnterFour}
                onMouseLeave={handleMouseLeaveFour}
              >
                <img
                  src={
                    isHoveredFour
                      ? "https://i.ibb.co/xCWS1fT/clrgirl.png"
                      : "https://i.ibb.co/PMQfM7M/bwgirl.png"
                  }
                  onMouseEnter={handleMouseEnterFour}
                  onMouseLeave={handleMouseLeaveFour}
                  alt="Kavya Pai"
                  className={`xl:-mt-12 transition-transform duration-300 transform ${
                    isHoveredFour ? "scale-105" : ""
                  }`}
                />
                <div className="bg-white xl:p-4 banner-font">
                  <span className="font-semibold uppercase xl:text-sm text-blue xl:pb-4">
                    Praba
                  </span>
                  <h1
                    className={
                      isHoveredFour
                        ? "animate-slide-upp pt-2"
                        : "animate-slide-up pt-2"
                    }
                  >
                    {isHoveredFour
                      ? "Elegantcarve Infotech."
                      : "Front End Designer"}
                  </h1>
                </div>
              </div>
            </div>

            <div
              style={{
                backgroundImage: `url(${bg})`,
                width: "100%",
                backgroundSize: "cover",
              }}
              className="border rounded-xl shadow-on-hover"
            >
              <div
                className="relative w-full cursor-pointer "
                onMouseEnter={handleMouseEnterFive}
                onMouseLeave={handleMouseLeaveFive}
              >
                <img
                  src={
                    isHoveredFive
                      ? "https://i.ibb.co/xCWS1fT/clrgirl.png"
                      : "https://i.ibb.co/PMQfM7M/bwgirl.png"
                  }
                  onMouseEnter={handleMouseEnterFive}
                  onMouseLeave={handleMouseLeaveFive}
                  alt="Kavya Pai"
                  className={`xl:-mt-12 transition-transform duration-300 transform ${
                    isHoveredFive ? "scale-105" : ""
                  }`}
                />
                <div className="bg-white xl:p-4 banner-font">
                  <span className="font-semibold uppercase xl:text-sm text-blue xl:pb-4">
                    suhail
                  </span>
                  <h1
                    className={
                      isHoveredFive
                        ? "animate-slide-upp pt-2"
                        : "animate-slide-up pt-2"
                    }
                  >
                    {isHoveredFive
                      ? "Elegantcarve Infotech."
                      : "Front End Designer"}
                  </h1>
                </div>
              </div>
            </div>

            <div
              style={{
                backgroundImage: `url(${bg})`,
                width: "100%",
                backgroundSize: "cover",
              }}
              className="border rounded-xl shadow-on-hover"
            >
              <div
                className="relative w-full cursor-pointer "
                onMouseEnter={handleMouseEnterSix}
                onMouseLeave={handleMouseLeaveSix}
              >
                <img
                  src={
                    isHoveredSix
                      ? "https://i.ibb.co/xCWS1fT/clrgirl.png"
                      : "https://i.ibb.co/PMQfM7M/bwgirl.png"
                  }
                  onMouseEnter={handleMouseEnterSix}
                  onMouseLeave={handleMouseLeaveSix}
                  alt="Kavya Pai"
                  className={`xl:-mt-12 transition-transform duration-300 transform ${
                    isHoveredSix ? "scale-105" : ""
                  }`}
                />
                <div className="bg-white xl:p-4 banner-font">
                  <span className="font-semibold uppercase xl:text-sm text-blue xl:pb-4">
                    mahima
                  </span>
                  <h1
                    className={
                      isHoveredSix
                        ? "animate-slide-upp pt-2"
                        : "animate-slide-up pt-2"
                    }
                  >
                    {isHoveredSix
                      ? "Elegantcarve Infotech."
                      : "Front End Designer"}
                  </h1>
                </div>
              </div>
            </div>

            <div
              style={{
                backgroundImage: `url(${bg})`,
                width: "100%",
                backgroundSize: "cover",
              }}
              className="border rounded-xl shadow-on-hover"
            >
              <div
                className="relative w-full cursor-pointer "
                onMouseEnter={handleMouseEnterSeven}
                onMouseLeave={handleMouseLeaveSeven}
              >
                <img
                  src={
                    isHoveredSeven
                      ? "https://i.ibb.co/xCWS1fT/clrgirl.png"
                      : "https://i.ibb.co/PMQfM7M/bwgirl.png"
                  }
                  onMouseEnter={handleMouseEnterSeven}
                  onMouseLeave={handleMouseLeaveSeven}
                  alt="Kavya Pai"
                  className={`xl:-mt-12 transition-transform duration-300 transform ${
                    isHoveredSeven ? "scale-105" : ""
                  }`}
                />
                <div className="bg-white xl:p-4 banner-font">
                  <span className="font-semibold uppercase xl:text-sm text-blue xl:pb-4">
                    charan
                  </span>
                  <h1
                    className={
                      isHoveredSeven
                        ? "animate-slide-upp pt-2"
                        : "animate-slide-up pt-2"
                    }
                  >
                    {isHoveredSeven
                      ? "Elegantcarve Infotech."
                      : "Front End Designer"}
                  </h1>
                </div>
              </div>
            </div>

            <div
              style={{
                backgroundImage: `url(${bg})`,
                width: "100%",
                backgroundSize: "cover",
              }}
              className="border rounded-xl shadow-on-hover"
            >
              <div
                className="relative w-full cursor-pointer "
                onMouseEnter={handleMouseEnterNine}
                onMouseLeave={handleMouseLeaveNine}
              >
                <img
                  src={
                    isHoveredNine
                      ? "https://i.ibb.co/xCWS1fT/clrgirl.png"
                      : "https://i.ibb.co/PMQfM7M/bwgirl.png"
                  }
                  onMouseEnter={handleMouseEnterNine}
                  onMouseLeave={handleMouseLeaveNine}
                  alt="Kavya Pai"
                  className={`xl:-mt-12 transition-transform duration-300 transform ${
                    isHoveredNine ? "scale-105" : ""
                  }`}
                />
                <div className="bg-white xl:p-4 banner-font">
                  <span className="font-semibold uppercase xl:text-sm text-blue xl:pb-4">
                    rekha
                  </span>
                  <h1
                    className={
                      isHoveredNine
                        ? "animate-slide-upp pt-2"
                        : "animate-slide-up pt-2"
                    }
                  >
                    {isHoveredNine
                      ? "Elegantcarve Infotech."
                      : "Front End Designer"}
                  </h1>
                </div>
              </div>
            </div>

            <div
              style={{
                backgroundImage: `url(${bg})`,
                width: "100%",
                backgroundSize: "cover",
              }}
              className="border rounded-xl shadow-on-hover"
            >
              <div
                className="relative w-full cursor-pointer "
                onMouseEnter={handleMouseEnterEight}
                onMouseLeave={handleMouseLeaveEight}
              >
                <img
                  src={
                    isHoveredEight
                      ? "https://i.ibb.co/xCWS1fT/clrgirl.png"
                      : "https://i.ibb.co/PMQfM7M/bwgirl.png"
                  }
                  onMouseEnter={handleMouseEnterEight}
                  onMouseLeave={handleMouseLeaveEight}
                  alt="Kavya Pai"
                  className={`xl:-mt-12 transition-transform duration-300 transform ${
                    isHoveredEight ? "scale-105" : ""
                  }`}
                />
                <div className="bg-white xl:p-4 banner-font">
                  <span className="font-semibold uppercase xl:text-sm text-blue xl:pb-4">
                    sudagar
                  </span>
                  <h1
                    className={
                      isHoveredEight
                        ? "animate-slide-upp pt-2"
                        : "animate-slide-up pt-2"
                    }
                  >
                    {isHoveredEight
                      ? "Elegantcarve Infotech."
                      : "Front End Designer"}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer2 />
    </>
  );
}
