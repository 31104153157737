import React, { useEffect, useRef, useState } from "react";
import elegantbgsection from "assets/img/elegantbgsection.jpg";
import productdesign from "assets/img/productdesign.png";
import success from "assets/img/success.png";
import futureresearch from "assets/img/futuresresearch.png";
import visionmission from "assets/img/visionmission.webp";

const HoveredImage = ({ fixedImageSrc }) => {
  return (
    <div className="md:mt-0  my-4">
      {fixedImageSrc && (
        <>
          {fixedImageSrc.text1 && (
            <p className="xl:pt-0 text-left font-medium xl:text-4xl lg:text-2xl md:text-2xl text-xl text-black banner-font">
              {fixedImageSrc.text1}
            </p>
          )}
          {fixedImageSrc.text && (
            <h1 className="text-left xl:w-10/12 xl:text-base1 text-base text-gray headlineheightt font-normal pt-2 banner-font">
              {fixedImageSrc.text}
            </h1>
          )}
          {fixedImageSrc.image && (
            <img
              src={fixedImageSrc.image}
              alt="Hovered Image"
              className="xl:w-maxx xl:h-max xl:mt-12 md:mt-4 ml-4 md:ml-0 md:mr-4"
            />
          )}
        </>
      )}
    </div>
  );
};

export default function HoverChange2() {
  /* scrolldown slowly coming animation start */

  const sections = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("animate");
            observer.unobserve(entry.target); // Stop observing the section once it's animated
          }
        });
      },
      { threshold: 0.7 }
    );

    sections.current.forEach((section) => {
      if (section && section instanceof Element) {
        observer.observe(section);
      }
    });

    const handleScroll = debounce(() => {
      sections.current.forEach((section) => {
        if (section && section instanceof Element) {
          observer.observe(section);
        }
      });
    }, 100);

    window.addEventListener("scroll", handleScroll);

    return () => {
      observer.disconnect();
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function debounce(func, delay) {
    let timeout;
    return function (...args) {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(context, args), delay);
    };
  }

  /* scrolldown slowly coming animation end */

  const [fixedImageSrc, setFixedImageSrc] = useState({
    // text: "OTHER SERVICES",
    text1: "Our Vision",
    text: "We provide innovative, consumer-led, adaptable software to build solutions that offer your audience an impeccable, user-friendly, and fascinating digital experience driven by dynamic digital experience driven by dynamic.",
    image: visionmission,
  });
  const [hoveredBox, setHoveredBox] = useState(null);

  const handleBoxHover = (box) => {
    setHoveredBox(box);

    switch (box) {
      case "passionDriven":
        setFixedImageSrc({
          text1: "Our Vision",
          text: "We are deliver a high quality services and success for any business. Our designs create websites that ensure an enjoyable user experience, be it in visuality or in functionality be it in visuality or in functionality.",
          image: visionmission,
        });
        break;
      case "customerCentric":
        setFixedImageSrc({
          text1: "Our Mission",
          text: "Our Team Understands the need of our clients to generate better results. The great aspect of social media is that a lot of the potential customers are there. We creating brand awareness, having quality leads.",
          image: visionmission,
        });
        break;
      case "transparentApproach":
        setFixedImageSrc({
          text1: "Our Values",
          text: "We provide innovative, consumer-led, adaptable software to build solutions that offer your audience an impeccable, user-friendly, and fascinating digital experience driven by dynamic digital experience driven by dynamic.",
          image: visionmission,
        });
        break;
    }
  };

  return (
    <>
      <section
        ref={(el) => sections.current.push(el)}
        style={{
          //   backgroundImage: `url(${elegantbgsection})`,
          backgroundColor: "#f8f9fb",
          width: "100%",
          backgroundSize: "cover",
        }}
        className="animate-on-scroll"
      >
        <div className="container mx-auto relative overflow-hidden xl:px-8 px-4 xl:py-20 md:py-8">
          <div className="xl:w-12/12  flex gap-2">
            <div className="xl:w-4/12 xl:ml-8 xl:mt-12">
              <div
                className={`cursor-pointer passionBox bg-white xl:w-12/12 items-center xl:pl-8 xl:pt-4 xl:h-36 ${
                  hoveredBox === "passionDriven" && "hovered"
                }`}
                onMouseEnter={() => handleBoxHover("passionDriven")}
                onMouseLeave={() => setHoveredBox(null)}
              >
                {/* <p className="text-xs uppercase banner-font  font-semibold text-left xl:pt-4 text-blue">
                  featured apps
                </p> */}
                <div className="flex mt-8 header-on-hover">
                  <div className="xl:mr-4 ">
                    <img
                      src="https://i.ibb.co/5j4VHSr/witness-2210317-removebg-preview.png"
                      className="xl:w-8 xl:mt-1"
                    />
                  </div>
                  <div className="xl:w-10/12 xl:pl-3 header-on-hover">
                    <p className="xl:text-lg banner-font text-black font-medium text-left">
                      Our Vision
                    </p>
                    <p className="xl:pt-1 header-on-hover text-left xl:text-base1 text-base text-gray banner-font">
                      Hover the here to know more information
                    </p>
                  </div>
                  <div className="xl:mr-12 font-extrabold header-on-hover" >﹥</div>
                </div>
              </div>

              <div
                className={`cursor-pointer passionBox bg-white  xl:w-12/12 items-center xl:pl-8 xl:pt-6 xl:h-36  ${
                  hoveredBox === "customerCentric" && "hovered"
                }`}
                onMouseEnter={() => handleBoxHover("customerCentric")}
                onMouseLeave={() => setHoveredBox(null)}
              >
                <div className="flex">
                  <div className="xl:mr-4 ">
                    <img
                      src="https://i.ibb.co/Bc5hwfH/radar-2543631-removebg-preview.png"
                      className="xl:w-8 xl:mt-1"
                    />
                  </div>
                  <div className="xl:w-10/12 xl:pl-3">
                    <p className="xl:text-lg banner-font font-medium text-black text-left">
                      Our Mission
                    </p>
                    <p className="xl:pt-1 text-left header-on-hover xl:text-base1 text-base text-gray leading-relaxed  banner-font">
                      Hover the here to know more information
                    </p>
                  </div>
                  <div className="xl:mr-12 font-extrabold header-on-hover" >﹥</div>
                </div>
              </div>

              <div
                className={`cursor-pointer passionBox bg-white xl:w-12/12 items-center xl:pl-8 xl:pt-6 xl:h-36  ${
                  hoveredBox === "transparentApproach" && "hovered"
                }`}
                onMouseEnter={() => handleBoxHover("transparentApproach")}
                onMouseLeave={() => setHoveredBox(null)}
              >
                <div className="flex xl:pb-12">
                  <div className="xl:mr-4 ">
                    <img
                      src="https://i.ibb.co/hXYQTjC/value-10614734-removebg-preview.png"
                      className="xl:w-8 xl:mt-1"
                    />
                  </div>
                  <div className="xl:w-10/12 xl:pl-3">
                    <p className="xl:text-lg banner-font font-medium text-black text-left">
                      Our Values
                    </p>
                    <p className="xl:pt-1 text-left header-on-hover xl:text-base1 text-base text-gray leading-relaxed  banner-font">
                      Hover the here to know more information
                    </p>
                  </div>
                  <div className="xl:mr-12 font-extrabold header-on-hover" >﹥</div>
                </div>
              </div>
            </div>
            <div className="xl:w-7/12 xl:ml-12">
              <HoveredImage fixedImageSrc={fixedImageSrc} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
