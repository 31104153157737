import React, { useEffect, useRef, useState } from "react";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer2 from "components/Footers/Footer2";
import backgroundservice from "assets/img/backgroundservice.png";
import bglast from "assets/img/bglast.png";
import dkkg from "assets/img/dkkg.png";
import jncldesign from "assets/img/jncldesign.png";
import jnfrl from "assets/img/jnfrl.png";
import IndexNavbar2 from "components/Navbars/IndexNavbar2";

export default function ServicesHomepage() {
  // 4 tab
  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  const [showParagraph1, setShowParagraph1] = useState(false);
  const [showParagraph2, setShowParagraph2] = useState(false);
  const [showParagraph3, setShowParagraph3] = useState(false);
  const [showParagraph4, setShowParagraph4] = useState(false);
  const [showParagraph5, setShowParagraph5] = useState(false);
  const [showParagraph6, setShowParagraph6] = useState(false);

  const toggleParagraph1 = () => {
    setShowParagraph1(!showParagraph1);
  };

  const toggleParagraph2 = () => {
    setShowParagraph2(!showParagraph2);
  };

  const toggleParagraph3 = () => {
    setShowParagraph3(!showParagraph3);
  };

  const toggleParagraph4 = () => {
    setShowParagraph4(!showParagraph4);
  };

  const toggleParagraph5 = () => {
    setShowParagraph5(!showParagraph5);
  };

  const toggleParagraph6 = () => {
    setShowParagraph6(!showParagraph6);
  };

  const settings = {
    dots: false,
    infinite: true,
    centerPadding: "1rem",
    speed: 1300,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    // prevArrow: <CustomPrevArrow />,
    // nextArrow: <CustomNextArrow />,
  };

  /* scrolldown slowly coming animation start */

  const sections = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("animate");
            observer.unobserve(entry.target); // Stop observing the section once it's animated
          }
        });
      },
      { threshold: 0.4 }
    );

    sections.current.forEach((section) => {
      if (section && section instanceof Element) {
        observer.observe(section);
      }
    });

    const handleScroll = debounce(() => {
      sections.current.forEach((section) => {
        if (section && section instanceof Element) {
          observer.observe(section);
        }
      });
    }, 100);

    window.addEventListener("scroll", handleScroll);

    return () => {
      observer.disconnect();
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function debounce(func, delay) {
    let timeout;
    return function (...args) {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(context, args), delay);
    };
  }

  /* scrolldown slowly coming animation end */

  return (
    <>
      <IndexNavbar2 fixed />
      {/* Desktop View */}
      {/* <section className="hidden md:block">
        <div className="header relative  items-center">
          <section
            ref={(el) => sections.current.push(el)}
            style={{
              backgroundImage: `url(${backgroundservice})`,
              width: "100%",
              backgroundSize: "cover",
            }}
            className="animate-on-scroll"
          >
            <div className="container xl:pt-60 lg:pt-40 pt-16 mx-auto justify-center flex flex-wrap ">
              <div className="w-full md:w-9/12 lg:w-8/12 xl:w-88/12 ">
                <div className="pt-32 sm:pt-0">
                  <h1 className="text-center font-semibold xl:text-6xl lg:text-4xl md:text-3xl text-black banner-font">
                    Bring Your<br></br>
                    <p className="text-gradient banner-font xl:pt-4">
                      Website to Life
                    </p>
                  </h1>
                  <p className="xl:px-20 xl:mt-6 text-center xl:text-xl text-base  text-gray leading-relaxed headlineheight banner-font">
                    Everything you need is right here.
                  </p>
                  <div className="center-items xl:mt-6 xl:mb-26 lg:mt-6">
                    <button className="blue-gradient text-sm banner-font text-white xl:px-10 lg:px-6 lg:py-2 xl:py-2 font-medium rounded-md ">
                      Get Started !
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section> */}

      {/* Mobile View */}
      <section
        ref={(el) => sections.current.push(el)}
        className="animate-on-scroll header relative pt-12 items-center flex seasalt md:hidden block"
      >
        <div className="container mx-auto justify-center flex flex-wrap ">
          <div className="w-full md:w-8/12 lg:w-8/12 xl:w-8/12 px-4">
            <h2 className="font-extrabold text-4xl text-blue texthome">
              Locally Nationally Internationally
            </h2>
            <h2 className=" text-base pt-2 text-white banner-font">
              We are a good technology partner for all your IT needs
            </h2>
            <p className="mt-2 text-base  text-gray leading-relaxed banpara headlineheight banner-font">
              Elegant Carve Infotech Pvt Ltd, Provides various services in this
              technology world to achieve your business Goal through online
              presence.
            </p>
            <div className="mt-6">
              <a href="/services">
                <h1
                  className="get-started text-white font-bold rounded outline-none focus:outline-none mr-1 mb-1 
                 uppercase text-sm  ease-linear transition-all duration-150 "
                >
                  <span className="border-b">
                    To Know More about Our Service
                  </span>
                </h1>
              </a>
            </div>
          </div>
        </div>
        <div id="chartdiv" className=""></div>
        <img
          className="absolute right-0 h-500-px  mt-2  w-7/12"
          src={require("assets/img/samplecopy.png").default}
          alt="Bangalore web designing company"
        />
      </section>

      {/* Trusted Clients Desktop View*/}

      {/* <section
        ref={(el) => sections.current.push(el)}
        className="bg-white md:block hidden animate-on-scroll"
      >
        <div className="container mx-auto xl:pt-12 xl:pb-12">
          <div class="">
            <Slider {...settings} className="xl:pt-12 xl:mx-20">
              <div class=" lg:w-2/12 md:w-4/12">
                <img
                  class="xl:w-32"
                  src={clients1}
                  alt="top web and mobile app development companies"
                />
              </div>
              <div class=" lg:w-2/12 md:w-4/12">
                <img
                  class="xl:w-32"
                  src={clients2}
                  alt="best website designers near me"
                />
              </div>
              <div className=" lg:w-2/12 md:w-4/12">
                <img
                  className="xl:w-32"
                  src={clients3}
                  alt="software company in Bangalore"
                />
              </div>
              <div className=" lg:w-2/12 md:w-4/12 ">
                <img
                  className="xl:w-32"
                  src={clients7}
                  alt="best website designers in Bangalore"
                />
              </div>
              <div className=" lg:w-2/12 md:w-4/12">
                <img
                  className="xl:w-32"
                  src={clients16}
                  alt="best app development company in Bangalore"
                />
              </div>
              <div className=" lg:w-2/12 md:w-4/12">
                <img
                  className="xl:w-32"
                  src={clients6}
                  alt="best ecommerce web design"
                />
              </div>
              <div class=" lg:w-2/12 md:w-4/12">
                <img
                  class="xl:w-32"
                  src={clients1}
                  alt="top web and mobile app development companies"
                />
              </div>
              <div class=" lg:w-2/12 md:w-4/12">
                <img
                  class="xl:w-32"
                  src={clients2}
                  alt="best website designers near me"
                />
              </div>
              <div className=" lg:w-2/12 md:w-4/12">
                <img
                  className="xl:w-32"
                  src={clients3}
                  alt="software company in Bangalore"
                />
              </div>
              <div className=" lg:w-2/12 md:w-4/12 ">
                <img
                  className="xl:w-32"
                  src={clients7}
                  alt="best website designers in Bangalore"
                />
              </div>
              <div className=" lg:w-2/12 md:w-4/12">
                <img
                  className="xl:w-32"
                  src={clients16}
                  alt="best app development company in Bangalore"
                />
              </div>
              <div className=" lg:w-2/12 md:w-4/12">
                <img
                  className="xl:w-32"
                  src={clients6}
                  alt="best ecommerce web design"
                />
              </div>{" "}
            </Slider>
          </div>
        </div>
      </section> */}

      <section
        ref={(el) => sections.current.push(el)}
        style={{
          // backgroundImage: `url(${dkkg})`,
          backgroundColor: "#ffff",
          width: "100%",
          backgroundSize: "cover",
        }}
        className="animate-on-scroll"
      >
        <div className="container mx-auto overflow-hidden px-4 xl:pt-44 xl:px-20 lg:py-8 py-6">
          <div className="grid grid-cols-2 gap-4 items-center ">
            <div className="xl:ml-4">
              <img
                src="https://i.ibb.co/XJSxfs5/littlelogo.png"
                className="logorotate"
                alt="logorotate"
              />
              <h2 className="xl:pr-4 xl:pt-6 text-left font-medium xl:text-4xl lg:text-2xl md:text-2xl text-xl text-black  banner-font">
                Grow faster by using our hosting services
              </h2>
              <p className="xl:mt-3 xl:pr-12 xl:pb-4 text-left xl:text-lg text-base text-gray headlineheightt banner-font">
                We offer a wide range of cloud hosting solutions to give
                business flexibility and choice in how website hosted. Do
                everything easily with our platform.
              </p>
              <div className="xl:mt-0 lg:mt-6">
                <a href="/contact-us">
                  <button className="xl:text-sm text-center text-white border-b py-2 px-6 banner-font font-medium bg-button">
                    Learn more ➝
                  </button>
                </a>
              </div>
            </div>
            <div className="xl:ml-20">
              <img
                src="https://i.ibb.co/6PjWdQX/lap2.png"
                className="xl:w-120 rounded-xll"
              />
            </div>
          </div>
        </div>
      </section>

      <section
        ref={(el) => sections.current.push(el)}
        style={{
          // backgroundImage: `url(${jncldesign})`,
          backgroundColor: "#f8f9fb",
          width: "100%",
          backgroundSize: "cover",
        }}
        className="animate-on-scroll"
      >
        <div className="container mx-auto overflow-hidden px-4 xl:py-16 xl:px-20 lg:py-8 py-6">
          <div className="grid grid-cols-2 gap-4 items-center ">
            <div className="xl:ml-12">
              <img
                src="https://i.ibb.co/Lh6grqb/offer.png"
                className="xl:w-124 rounded-xll"
              />
            </div>
            <div className="xl:ml-8">
              <img
                src="https://i.ibb.co/B39kDnV/ltkfn.png"
                className="logorotate"
              />
              <h2 className="xl:pr-20 xl:pt-6 text-left font-medium xl:text-4xl lg:text-2xl md:text-2xl text-xl text-black banner-font">
                We offer solutions for every business
              </h2>
              <p className="xl:mt-3 xl:pr-8 xl:pb-4 text-left xl:text-base1 text-base text-gray headlineheightt banner-font">
                We offer a wide range of cloud hosting solutions to give
                business flexibility and choice in how website hosted. Do
                everything easily with our platform.
              </p>
              <div className="xl:mt-0 lg:mt-6  text-gray">
                <button className="bg-elegantcarve-dark banner-font text-white px-6 py-2 font-semibold text-sm">
                  Get Started ➝
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        ref={(el) => sections.current.push(el)}
        style={{
          // backgroundImage: `url(${dkkg})`,
          backgroundColor: "#fff",
          width: "100%",
          backgroundSize: "cover",
        }}
        className="animate-on-scroll"
      >
        <div className="container mx-auto overflow-hidden px-4 xl:py-16 xl:px-20 lg:py-8 py-6">
          <div className="grid grid-cols-2 gap-4 items-center ">
            <div className="xl:ml-4">
              <img
                src="https://i.ibb.co/XJSxfs5/littlelogo.png"
                className="logorotate"
                alt="logorotate"
              />
              <h2 className="xl:pr-4 xl:pt-8 text-left font-medium xl:text-4xl lg:text-2xl md:text-2xl text-xl text-black  banner-font">
                Grow faster by using our hosting services
              </h2>
              <p className="xl:mt-2 xl:pr-12 xl:pb-4 text-left xl:text-lg text-base  text-gray leading-relaxed headlineheightt banner-font">
                We offer a wide range of cloud hosting solutions to give
                business flexibility and choice in how website hosted. Do
                everything easily with our platform.
              </p>
              <div className="xl:mt-0 lg:mt-6  text-gray">
                <a href="/contact-us">
                <button className="bg-elegantcarve-dark banner-font text-white px-6 py-2 font-semibold text-sm">
                  Get Started ➝
                </button>
                </a>
              </div>
            </div>
            <div className="xl:ml-20">
              <img
                src="https://i.ibb.co/6PjWdQX/lap2.png"
                className="xl:w-120 rounded-xll"
              />
            </div>
          </div>
        </div>
      </section>

      <section
        ref={(el) => sections.current.push(el)}
        style={{
          // backgroundImage: `url(${jncldesign})`,
          backgroundColor: "#f8f9fb",
          width: "100%",
          backgroundSize: "cover",
        }}
        className="animate-on-scroll"
      >
        <div className="container mx-auto overflow-hidden px-4 xl:py-16 xl:px-20 lg:py-8 py-6">
          <div className="grid grid-cols-2 gap-4 items-center ">
            <div className="xl:ml-12">
              <img
                src="https://i.ibb.co/Lh6grqb/offer.png"
                className="xl:w-124 rounded-xll"
              />
            </div>
            <div className="xl:ml-8">
              <img
                src="https://i.ibb.co/B39kDnV/ltkfn.png"
                className="logorotate"
              />
              <h2 className="xl:pr-20 xl:pt-8 text-left font-medium xl:text-4xl lg:text-2xl md:text-2xl text-xl text-black  banner-font">
                We offer solutions for every business
              </h2>
              <p className="xl:mt-2 xl:pr-8 xl:pb-4 text-left xl:text-lg text-base  text-gray leading-relaxed headlineheightt banner-font">
                We offer a wide range of cloud hosting solutions to give
                business flexibility and choice in how website hosted. Do
                everything easily with our platform.
              </p>
              <div className="xl:mt-0 lg:mt-6  text-gray">
              <button className="bg-elegantcarve-dark banner-font text-white px-6 py-2 font-semibold text-sm">
                  Get Started ➝
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section
        ref={(el) => sections.current.push(el)}
        className="bg-white animate-on-scroll"
      >
        <div className="container mx-auto overflow-hidden px-4 xl:pt-20 xl:pb-12 lg:py-8 py-6">
          <p className="text-center xl:text-base1 text-base text-blue-gradient leading-relaxed font-semibold banner-font">
            ABOUT US
          </p>
          <h2 className="xl:px-72 xl:pt-4 text-center font-bold xl:text-4xl lg:text-2xl md:text-2xl text-xl text-black banner-font">
            The Most Powerful Hosting Platform
          </h2>
          <p className="xl:px-84 lg:px-56 md:px-32 px-4 xl:mt-3 text-center xl:text-lg text-base text-black headlineheightt banner-font">
            We offer a wide range of hosting solutions, from web hosting to
            dedicated servers. Everything you need is right here.
          </p>
          <div className="xl:pt-12 xl:gap-4 xl:px-32 grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-4 grid-cols-1">
            <a href="">
              <div className="bg-white border rounded-md shadow-xl xl:p-8">
                <p className="text-left xl:text-base1 text-base  text-gray leading-relaxed headlineheight banner-font">
                  May 7, 2024
                </p>
                <p className="xl:pt-4 text-left xl:text-lg banner-font text-blue font-semibold header-on-hover">
                  PayPal’s 2023 Global Impact Report: Revolutionizing...
                </p>
                <a>
                  <p className="head1 header-on-hover xl:text-base1 text-gray font-semibold xl:pt-24">
                    Read More ➝
                  </p>
                </a>
              </div>
            </a>
            <a href="">
            <div className="bg-white border rounded-md shadow-xl xl:p-8">
                <p className="text-left xl:text-base1 text-base  text-gray leading-relaxed headlineheight banner-font">
                  May 7, 2024
                </p>
                <p className="xl:pt-4 text-left xl:text-lg banner-font text-blue font-semibold header-on-hover">
                PayPal Names Steve Winoker as Chief Investor Relations... 
                </p>
                <a>
                  <p className="banner-font header-on-hover xl:text-base1 text-gray font-semibold xl:pt-24">
                    Read More ➝
                  </p>
                </a>
              </div>
            </a>
            <a href="">
            <div className="bg-white border rounded-md shadow-xl xl:p-8">
                <p className="text-left xl:text-base1 text-base  text-gray leading-relaxed headlineheight banner-font">
                  May 7, 2024
                </p>
                <p className="xl:pt-4 text-left xl:text-lg banner-font text-blue font-semibold header-on-hover">
                MoonPay Now Lets Users Buy & Sell Crypto funded by... 
                </p>
                <a>
                  <p className="banner-font header-on-hover xl:text-base1 text-gray font-semibold xl:pt-16">
                    Read More ➝
                  </p>
                </a>
              </div>
            </a>
          </div>
        </div>
      </section> */}

      <section className="bg-white">
        <div className="container mx-auto overflow-hidden px-4 xl:py-0 xl:pt-12 xl:pb-8 lg:py-8 py-6">
          <h2 className="xl:px-84 lg:px-56 md:px-32 px-4 xl:pt-2 text-center font-medium xl:text-4xl lg:text-2xl md:text-2xl text-xl text-black  banner-font">
            Our features and services
          </h2>
          <p className="xl:mt-2 xl:pb-2 text-center xl:px-84 lg:px-56 md:px-32 px-4 xl:text-lg text-base text-gray headlineheightt banner-font">
            IT Technology is a broad category encompassing all aspec information
            technology of technology in various industries.
          </p>
          <div className="grid xl:grid-cols-3 xl:gap-4 xl:px-44 xl:pb-12 xl:mt-4">
            <div className="banner-font border xl:p-6 shadow-xl rounded-md bg-white">
              <div className="center-items">
                <img src="https://i.ibb.co/1Kcn5Kz/Whats-App-Image-2024-05-04-at-9-03-10-AM.jpg" />
              </div>
              <h1 className="xl:pt-7 banner-font xl:text-xl text-blue text-center font-medium">
                Communications
              </h1>
              <p className="xl:pt-2 banner-font xl:text-base1 text-center text-gray">
                IT Technology is a broad category encompassing all aspec
                information in various industries.
              </p>
            </div>
            <div className="banner-font border xl:p-6 shadow-xl rounded-md bg-white">
              <div className="center-items">
                <img src="https://i.ibb.co/sW5CQzh/Whats-App-Image-2024-05-04-at-9-03-10-AM-1.jpg" />
              </div>
              <h1 className="xl:pt-4 banner-font xl:text-xl text-blue text-center font-medium">
                Inspired Design
              </h1>
              <p className="xl:pt-2 banner-font xl:text-base1 text-center text-gray">
                IT Technology is a broad category encompassing all aspec
                information in various industries.
              </p>
            </div>
            <div className="banner-font border xl:p-6 shadow-xl rounded-md bg-white">
              <div className="center-items">
                <img src="https://i.ibb.co/87fsQV7/Whats-App-Image-2024-05-04-at-9-03-10-AM-2.jpg" />
              </div>
              <h1 className="xl:pt-8 banner-font xl:text-xl text-blue text-center font-medium">
                Happy Customers
              </h1>
              <p className="xl:pt-2 banner-font xl:text-base1 text-center text-gray">
                IT Technology is a broad category encompassing all aspec
                information in various industries.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section
        ref={(el) => sections.current.push(el)}
        style={{
          // backgroundImage: `url(${jnfrl})`,
          backgroundColor: "#f8f9fb",
          width: "100%",
          backgroundSize: "cover",
        }}
        className="animate-on-scroll"
      >
        <div className="container mx-auto overflow-hidden px-4 xl:pb-20 xl:pt-12 xl:px-12 lg:pb-8 pb-6">
          <div className="grid grid-cols-2 xl:px-8">
            <div>
              <h2 className="xl:pt-4 text-left font-medium xl:text-4xl lg:text-2xl md:text-2xl text-xl text-black banner-font">
                What makes us so different <br></br>in your website
              </h2>
            </div>
            <div>
              <p className="xl:mt-6 xl:pb-4 text-left xl:text-base1 text-base text-gray leading-relaxed headlineheightt  banner-font">
                We offer a wide range of cloud hosting solutions to give
                business flexibility and choice in how website hosted. Do
                everything easily with our platform.
              </p>
            </div>
          </div>
          <div className="grid grid-cols-2 xl:gap-4 xl:pt-8 xl:px-8">
            <div>
              <div className="bg-white flex xl:w-12/12 xl:mt-4 items-center shadow-md rounded-md border-none xl:p-6">
                <div className="xl:mr-4 ">
                  <img
                    src="https://i.ibb.co/PQz51ss/securetg.png"
                    className="xl:w-16"
                  />
                </div>
                <div className="xl:w-10/12">
                <p className="xl:pt-2 xl:text-xl banner-font font-medium text-black text-left">
                    Security
                  </p>
                  <p className="xl:pt-1 text-left xl:text-base1 text-base  text-gray-light leading-relaxed  banner-font">
                    Begin by comprehensively understanding your business needs
                    to ensure a perfect fit for tech solutions.
                  </p>
                </div>
              </div>

              <div className="bg-white flex xl:w-12/12 xl:mt-4 items-center shadow-md rounded-md border-none xl:p-6">
                <div className="xl:mr-4 ">
                  <img
                    src="https://i.ibb.co/s19FKpw/rkrjng.png"
                    className="xl:w-16"
                  />
                </div>
                <div className="xl:w-10/12">
                <p className="xl:pt-2 xl:text-xl banner-font font-medium text-black text-left">
                    Privacy
                  </p>
                  <p className="xl:pt-1 text-left xl:text-base1 text-base  text-gray-light leading-relaxed  banner-font">
                    Develop Custom Solutions Engage in crafting tailor-made
                    software designed meticulously to align with your specific
                    targets.eady, providing reliable assistance and technical.
                  </p>
                </div>
              </div>
              <div className="bg-white flex xl:w-12/12 xl:mt-4 items-center shadow-md rounded-md border-none xl:p-6">
                <div className="xl:mr-4 ">
                  <img
                    src="https://i.ibb.co/NLRRZss/ptgk.png"
                    className="xl:w-16"
                  />
                </div>
                <div className="xl:w-10/12">
                <p className="xl:pt-2 xl:text-xl banner-font font-medium text-black text-left">
                    Open Source
                  </p>
                  <p className="xl:pt-1 text-left xl:text-base1 text-base  text-gray-light leading-relaxed  banner-font">
                    Seamlessly integrate the new systems into framework with
                    ongoing support for continual optimization.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="bg-white flex xl:w-12/12 xl:mt-4 items-center shadow-md rounded-md border-none xl:p-6">
                <div className="xl:mr-4 ">
                  <img
                    src="https://i.ibb.co/swZBVXz/4tg4.png"
                    className="xl:w-16"
                  />
                </div>
                <div className="xl:w-10/12">
                <p className="xl:pt-2 xl:text-xl banner-font font-medium text-black text-left">
                    24/7 Support
                  </p>
                  <p className="xl:pt-1 text-left xl:text-base1 text-base  text-gray-light leading-relaxed  banner-font">
                    Begin by comprehensively understanding your business needs
                    to ensure a perfect fit for tech solutions.
                  </p>
                </div>
              </div>

              <div className="bg-white flex xl:w-12/12 xl:mt-4 items-center shadow-md rounded-md border-none xl:p-6">
                <div className="xl:mr-4 ">
                  <img
                    src="https://i.ibb.co/LSWHNjd/prgijv.png"
                    className="xl:w-16"
                  />
                </div>
                <div className="xl:w-10/12">
                <p className="xl:pt-2 xl:text-xl banner-font font-medium text-black text-left">
                    100% Uptime
                  </p>
                  <p className="xl:pt-1 text-left xl:text-base1 text-base  text-gray-light leading-relaxed  banner-font">
                    Develop Custom Solutions Engage in crafting tailor-made
                    software designed meticulously to align with your specific
                    targets.eady, providing reliable assistance and technical.
                  </p>
                </div>
              </div>
              <div className="bg-white flex xl:w-12/12 xl:mt-4 items-center shadow-md rounded-md border-none xl:p-6">
                <div className="xl:mr-4 ">
                  <img
                    src="https://i.ibb.co/2W1JH6k/rgkbg.png"
                    className="xl:w-16"
                  />
                </div>
                <div className="xl:w-10/12">
                  <p className="xl:pt-2 xl:text-xl banner-font font-medium text-black text-left">
                    Community
                  </p>
                  <p className="xl:pt-1 text-left xl:text-base1 text-base  text-gray-light leading-relaxed  banner-font">
                    Seamlessly integrate the new systems into framework with
                    ongoing support for continual optimization.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section
        ref={(el) => sections.current.push(el)}
        className="bg-white animate-on-scroll"
      >
        <div className="xl:mx-44 rounded-xll xl:py-12 xl:px-28">
          <div class="container-fluid">
            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
              <div className="gradient-elegantcarve rounded-xll">
                <div className="container text-left mx-auto overflow-hidden px-4 xl:py-8 xl:px-12 lg:py-8 py-6">
                  <div className="flex xl:w-12/12">
                    <div className="xl:w-9/12">
                      <h2 className="xl:pt-4 font-semibold xl:text-4xl lg:text-2xl md:text-2xl text-xl text-white banner-font">
                        Hire a professional developer for your website
                      </h2>
                      <p className="xl:text-lg xl:pt-4 text-base text-white headlineheightt banner-font">
                        We are happy to assist you.
                      </p>
                    </div>
                    <div className="xl:w-3/12 items-center xl:ml-8 xl:pt-12">
                      <button className="bg-white banner-font text-blue xl:px-10 lg:px-6 lg:py-2 xl:py-2 font-semibold rounded-md ">
                        Hire Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* <section
        ref={(el) => sections.current.push(el)}
        style={{
          backgroundImage: `url(${bglast})`,
          width: "100%",
          backgroundSize: "cover",
        }}
        className="animate-on-scroll"
      >
        <div className="container xl:py-20 lg:pt-40 pt-16 mx-auto justify-center flex flex-wrap ">
          <div className="w-full md:w-9/12 lg:w-8/12 xl:w-88/12 ">
            <div className="pt-32 sm:pt-0">
              <p className="text-center xl:text-base1 text-base text-blue leading-relaxed font-semibold banner-font">
                TESTIMONIALS
              </p>
              <h1 className="text-center font-semibold xl:text-4xl lg:text-2xl md:text-2xl text-xl xl:pt-4 text-black banner-font">
                Why people
                <span className="text-gradient banner-font xl:pl-2 xl:pt-4">
                  choose us
                </span>
              </h1>
              <p className="xl:px-52 xl:mt-4 text-center xl:text-xl text-base  text-gray leading-relaxed headlineheight banner-font">
                We offer a wide range of hosting solutions, from web hosting to
                dedicated servers. Everything you need is right here.
              </p>
            </div>
            <div className="grid grid-cols-2 gap-4 xl:pt-12">
              <div className="bg-white shadow-md rounded-md xl:p-12">
                <div className="flex gap-1 xl:pr-6">
                  <img
                    src="https://i.ibb.co/K2N8PVv/star-14442199-removebg-preview.png"
                    className="xl:w-4 xl:h-4"
                  />
                  <img
                    src="https://i.ibb.co/K2N8PVv/star-14442199-removebg-preview.png"
                    className="xl:w-4 xl:h-4"
                  />
                  <img
                    src="https://i.ibb.co/K2N8PVv/star-14442199-removebg-preview.png"
                    className="xl:w-4 xl:h-4"
                  />
                  <img
                    src="https://i.ibb.co/K2N8PVv/star-14442199-removebg-preview.png"
                    className="xl:w-4 xl:h-4"
                  />
                  <img
                    src="https://i.ibb.co/K2N8PVv/star-14442199-removebg-preview.png"
                    className="xl:w-4 xl:h-4"
                  />
                </div>
                <p className="text-black xl:pt-8 text-left xl:text-lg font-semibold text-base headlineheight banner-font">
                  “Great! This is the best hosting I have ever used before.”
                </p>
                <div className="flex xl:w-12/12 xl:pt-8">
                  <div className="xl:w-9/12  text-gray text-left">
                    <h1 className="banner-font font-semibold xl:text-lg">
                      Peter Watson
                    </h1>
                    <p className="banner-font ">Barista</p>
                  </div>
                  <div className="xl:w-3/12 xl:pt-2 xl:pl-4">
                    <img
                      src="https://i.ibb.co/V349Bt7/chkm.png"
                      className="rounded-full xl:w-12"
                    />
                  </div>
                </div>
              </div>
              <div className="bg-white shadow-md rounded-md xl:p-12">
                <div className="flex gap-1  xl:pr-6">
                  <img
                    src="https://i.ibb.co/K2N8PVv/star-14442199-removebg-preview.png"
                    className="xl:w-4 xl:h-4"
                  />
                  <img
                    src="https://i.ibb.co/K2N8PVv/star-14442199-removebg-preview.png"
                    className="xl:w-4 xl:h-4"
                  />
                  <img
                    src="https://i.ibb.co/K2N8PVv/star-14442199-removebg-preview.png"
                    className="xl:w-4 xl:h-4"
                  />
                  <img
                    src="https://i.ibb.co/K2N8PVv/star-14442199-removebg-preview.png"
                    className="xl:w-4 xl:h-4"
                  />
                  <img
                    src="https://i.ibb.co/K2N8PVv/star-14442199-removebg-preview.png"
                    className="xl:w-4 xl:h-4"
                  />
                </div>
                <p className="text-black xl:pt-8 text-left xl:text-lg font-semibold text-base headlineheight banner-font">
                  “Great! This is the best hosting I have ever used before.”
                </p>
                <div className="flex xl:w-12/12 xl:pt-8">
                  <div className="xl:w-9/12  text-gray text-left">
                    <h1 className="banner-font font-semibold xl:text-lg">
                      Peter Watson
                    </h1>
                    <p className="banner-font ">Barista</p>
                  </div>
                  <div className="xl:w-3/12 xl:pt-2 xl:pl-4">
                    <img
                      src="https://i.ibb.co/V349Bt7/chkm.png"
                      className="rounded-full xl:w-12"
                    />
                  </div>
                </div>
              </div>
              <div className="bg-white shadow-md rounded-md  xl:p-12">
                <div className="flex gap-1  xl:pr-6">
                  <img
                    src="https://i.ibb.co/K2N8PVv/star-14442199-removebg-preview.png"
                    className="xl:w-4 xl:h-4"
                  />
                  <img
                    src="https://i.ibb.co/K2N8PVv/star-14442199-removebg-preview.png"
                    className="xl:w-4 xl:h-4"
                  />
                  <img
                    src="https://i.ibb.co/K2N8PVv/star-14442199-removebg-preview.png"
                    className="xl:w-4 xl:h-4"
                  />
                  <img
                    src="https://i.ibb.co/K2N8PVv/star-14442199-removebg-preview.png"
                    className="xl:w-4 xl:h-4"
                  />
                  <img
                    src="https://i.ibb.co/K2N8PVv/star-14442199-removebg-preview.png"
                    className="xl:w-4 xl:h-4"
                  />
                </div>
                <p className="text-black xl:pt-8 text-left xl:text-lg font-semibold text-base headlineheight banner-font">
                  “Great! This is the best hosting I have ever used before.”
                </p>
                <div className="flex xl:w-12/12 xl:pt-8">
                  <div className="xl:w-9/12  text-gray text-left">
                    <h1 className="banner-font font-semibold xl:text-lg">
                      Peter Watson
                    </h1>
                    <p className="banner-font ">Barista</p>
                  </div>
                  <div className="xl:w-3/12 xl:pt-2 xl:pl-4">
                    <img
                      src="https://i.ibb.co/V349Bt7/chkm.png"
                      className="rounded-full xl:w-12"
                    />
                  </div>
                </div>
              </div>
              <div className="bg-white shadow-md rounded-md xl:p-12">
                <div className="flex gap-1  xl:pr-6">
                  <img
                    src="https://i.ibb.co/K2N8PVv/star-14442199-removebg-preview.png"
                    className="xl:w-4 xl:h-4"
                  />
                  <img
                    src="https://i.ibb.co/K2N8PVv/star-14442199-removebg-preview.png"
                    className="xl:w-4 xl:h-4"
                  />
                  <img
                    src="https://i.ibb.co/K2N8PVv/star-14442199-removebg-preview.png"
                    className="xl:w-4 xl:h-4"
                  />
                  <img
                    src="https://i.ibb.co/K2N8PVv/star-14442199-removebg-preview.png"
                    className="xl:w-4 xl:h-4"
                  />
                  <img
                    src="https://i.ibb.co/K2N8PVv/star-14442199-removebg-preview.png"
                    className="xl:w-4 xl:h-4"
                  />
                </div>
                <p className="text-black xl:pt-8 text-left xl:text-lg font-semibold text-base headlineheight banner-font">
                  “Great! This is the best hosting I have ever used before.”
                </p>
                <div className="flex xl:w-12/12 xl:pt-8">
                  <div className="xl:w-9/12  text-gray text-left">
                    <h1 className="banner-font font-semibold xl:text-lg">
                      Peter Watson
                    </h1>
                    <p className="banner-font ">Barista</p>
                  </div>
                  <div className="xl:w-3/12 xl:pt-2 xl:pl-4">
                    <img
                      src="https://i.ibb.co/V349Bt7/chkm.png"
                      className="rounded-full xl:w-12"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section
        ref={(el) => sections.current.push(el)}
        style={{
          // backgroundImage: `url(${bglast})`,
          backgroundColor: "#ffff",
          width: "100%",
          backgroundSize: "cover",
        }}
        className="animate-on-scroll"
      >
        <div className="container xl:pt-12 mx-auto justify-center flex flex-wrap ">
          <div className="w-full md:w-9/12 lg:w-8/12 xl:w-88/12 ">
              <h1 className="text-center font-medium xl:text-4xl lg:text-2xl md:text-2xl text-xl text-black banner-font">
                Still have questions?
              </h1>
              <p className="xl:px-44 xl:mt-2 text-center xl:text-base1 headlineheightt text-base text-gray banner-font">
                We offer a wide range of hosting solutions, from web hosting to
                dedicated servers. Everything you need is right here.
              </p>
            <div className="grid xl:grid-cols-2 gap-4 xl:pt-8">
              <div>
                <h1
                  className={`banner-font rounded-t-md bg-white shadow-md xl:p-3 border font-medium xl:text-base1 ${
                    showParagraph1
                      ? "active-heading"
                      : "banner-font font-medium"
                  }`}
                  onClick={toggleParagraph1}
                  style={{ cursor: "pointer" }}
                >
                  What is web hosting?
                </h1>
                {showParagraph1 && (
                  <p
                    className={`paragraph xl:p-3 rounded-b-md bg-white  text-gray banner-font shadow-md border ${
                      showParagraph1 ? "show" : "hide"
                    }`}
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
                    elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus
                    leo.
                  </p>
                )}
              </div>
              <div>
                <h1
                  className={`banner-font rounded-t-md bg-white shadow-md xl:p-3 border font-medium xl:text-base1 ${
                    showParagraph2 ? "active-heading" : ""
                  }`}
                  onClick={toggleParagraph2}
                  style={{ cursor: "pointer" }}
                >
                  How to set up a WooCommerce site?
                </h1>
                {showParagraph2 && (
                  <p className="xl:p-3 rounded-b-md  text-gray bg-white banner-font shadow-md border">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
                    elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus
                    leo.
                  </p>
                )}
              </div>
              <div>
                <h1
                  className={`banner-font rounded-t-md bg-white shadow-md xl:p-3 border font-medium xl:text-base1 ${
                    showParagraph3 ? "active-heading" : ""
                  }`}
                  onClick={toggleParagraph3}
                  style={{ cursor: "pointer" }}
                >
                  How do I create a website?
                </h1>
                {showParagraph3 && (
                  <p className="xl:p-3 rounded-b-md  text-gray bg-white banner-font shadow-md border">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
                    elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus
                    leo.
                  </p>
                )}
              </div>

              <div>
                <h1
                  className={`banner-font rounded-t-md bg-white shadow-md xl:p-3 border font-medium xl:text-base1 ${
                    showParagraph4 ? "active-heading" : ""
                  }`}
                  onClick={toggleParagraph4}
                  style={{ cursor: "pointer" }}
                >
                  When will I be billed for renewal?
                </h1>
                {showParagraph4 && (
                  <p className="xl:p-3 rounded-b-md  text-gray bg-white banner-font shadow-md border">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
                    elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus
                    leo.
                  </p>
                )}
              </div>
              <div>
                <h1
                  className={`banner-font rounded-t-md bg-white shadow-md xl:p-3 border font-medium xl:text-base1 ${
                    showParagraph5 ? "active-heading" : ""
                  }`}
                  onClick={toggleParagraph5}
                  style={{ cursor: "pointer" }}
                >
                  How can I transfer my website?
                </h1>
                {showParagraph5 && (
                  <p className="xl:p-3 rounded-b-md  text-gray bg-white banner-font shadow-md border">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
                    elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus
                    leo.
                  </p>
                )}
              </div>
              <div>
                <h1
                  className={`banner-font rounded-t-md shadow-md bg-white xl:p-3 border font-medium xl:text-base1 ${
                    showParagraph6 ? "active-heading" : ""
                  }`}
                  onClick={toggleParagraph6}
                  style={{ cursor: "pointer" }}
                >
                  What is a nameserver and DNS?
                </h1>
                {showParagraph6 && (
                  <p className="xl:p-3 rounded-b-md xl:text-base1  text-gray bg-white banner-font shadow-md border">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
                    elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus
                    leo.
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="container xl:py-20 lg:pt-40 pt-16 mx-auto justify-center flex flex-wrap ">
          <div className="w-full md:w-9/12 lg:w-8/12 xl:w-88/12 ">
            <div className="pt-32 sm:pt-0">
              <h1 className="text-center font-medium xl:text-4xl lg:text-2xl md:text-2xl text-xl text-black banner-font">
                Subscribe and get<br></br>
                <p className="text-gradient banner-font xl:pt-0">50% off</p>
              </h1>
              <p className="xl:px-20 xl:mt-2 text-center xl:text-base1 text-base text-gray leading-relaxed headlineheight banner-font">
                Everything you need is right here.
              </p>
              <div className="center-items xl:mt-4 lg:mt-4">
                <button className="bg-elegantcarve-dark banner-font text-white px-6 py-2 font-medium text-sm">
                  Get Started !
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section
        ref={(el) => sections.current.push(el)}
        style={{
          backgroundImage: `url(${bglast})`,
          width: "100%",
          backgroundSize: "cover",
        }}
        className="animate-on-scroll"
      >
        <div className="container xl:py-20 lg:pt-40 pt-16 mx-auto justify-center flex flex-wrap ">
          <div className="w-full md:w-9/12 lg:w-8/12 xl:w-88/12 ">
            <div className="pt-32 sm:pt-0">
              <h1 className="text-center font-semibold xl:text-4xl lg:text-2xl md:text-2xl text-xl text-black banner-font">
                Subscribe and get<br></br>
                <p className="text-gradient banner-font xl:pt-4">50% off</p>
              </h1>
              <p className="xl:px-20 xl:mt-6 text-center xl:text-xl text-base  text-gray leading-relaxed headlineheight banner-font">
                Everything you need is right here.
              </p>
              <div className="center-items xl:mt-8 lg:mt-6">
                <button className="bg-elegantcarve-dark banner-font text-white xl:px-10 lg:px-6 lg:py-2 xl:py-2 font-semibold rounded-md ">
                  Get Started !
                </button>
              </div>
            </div>
          </div>
        </div>
      </section> */}
     
      <Footer2 />
    </>
  );
}
