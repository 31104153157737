import React, { useEffect, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import mendublicate from "assets/img/mendublicate.png";

const SliderPage = () => {
  const settings = {
    dots: false,
    infinite: true,
    centerPadding: "1rem",
    speed: 1300,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    // prevArrow: <CustomPrevArrow />,
    // nextArrow: <CustomNextArrow />,
  };

  // scrollinf code

  const sections = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("animate");
            observer.unobserve(entry.target); // Stop observing the section once it's animated
          }
        });
      },
      { threshold: 0.4 }
    );

    sections.current.forEach((section) => {
      if (section && section instanceof Element) {
        observer.observe(section);
      }
    });

    const handleScroll = debounce(() => {
      sections.current.forEach((section) => {
        if (section && section instanceof Element) {
          observer.observe(section);
        }
      });
    }, 100);

    window.addEventListener("scroll", handleScroll);

    return () => {
      observer.disconnect();
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function debounce(func, delay) {
    let timeout;
    return function (...args) {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(context, args), delay);
    };
  }

  return (
    <section
      ref={(el) => sections.current.push(el)}
      className="bg-light-green animate-on-scroll"
    >
      <div className="container mx-auto overflow-hidden px-4 xl:pt-16 lg:pt-12 md:pt-12 pt-6">
        <h2 className="xl:px-84 lg:px-56 md:px-32 px-4 text-center font-medium xl:text-4xl lg:text-2xl md:text-2xl text-xl text-black banner-font">
          Customer Experiences
        </h2>
        <p className="xl:px-84 lg:px-56 md:px-32 px-4 xl:mt-3 text-center xl:text-base1 text-base  text-gray  banner-font">
          We offer a wide range of hosting solutions, from web hosting to
          dedicated servers. Everything you need is right here.
        </p>
        <Slider
          {...settings}
          className="xl:pt-4 lg:pt-12 pt-8 xl:mx-52 lg:mx-40 md:mx-20"
        >
          <div className="px-4">
            <div className="xl:h-400 grid bg-light-green xl:grid-cols-1 xl:gap-4 md:grid-cols-1 bg-light-green xl:p-2 md:p-6 p-2">
              <div className="cursor-pointer xl:p-12 xl:pt-12">
                <div className="xl:flex lg:flex md:flex flex-none w-12/12 ">
                  <div className="w-3/12 xl:pt-2">
                    <img
                      src="https://i.ibb.co/BBwJknR/fashion-men-business-mens-fashion.jpg"
                      className="rounded-md xl:w-40"
                    />
                  </div>
                  <div className="md:w-9/12 w-12/12 text-gray text-left xl:pl-12 lg:pl-8 pl-0 md:pt-0 pt-4">
                    <div className="flex justify-between">
                      <div>
                        <img
                          src="https://i.ibb.co/jMYQPyQ/quote-7350737-removebg-preview.png"
                          className="xl:w-12 lg:w-12 md:w-16 w-12"
                        />
                      </div>
                      <div className="gap-1">
                        <div className="center-items">
                          <a href="https://iksoftec.com/" target="_blank">
                            <img
                              src="https://i.ibb.co/7jQf71m/Screenshot-571-removebg-preview.png"
                              className="xl:w-36 lg:w-32 md:w-32 w-24 xl:-mt-3"
                            />
                          </a>
                        </div>
                        <div className="flex pt-2 xl:pl-14 lg:pl-12 md:pl-10">
                          <img
                            src="https://i.ibb.co/K2N8PVv/star-14442199-removebg-preview.png"
                            className="w-4 h-4"
                          />
                          <img
                            src="https://i.ibb.co/K2N8PVv/star-14442199-removebg-preview.png"
                            className="w-4 h-4"
                          />
                          <img
                            src="https://i.ibb.co/K2N8PVv/star-14442199-removebg-preview.png"
                            className="w-4 h-4"
                          />
                          <img
                            src="https://i.ibb.co/K2N8PVv/star-14442199-removebg-preview.png"
                            className="w-4 h-4"
                          />
                          <img
                            src="https://i.ibb.co/K2N8PVv/star-14442199-removebg-preview.png"
                            className="w-4 h-4"
                          />
                        </div>
                      </div>
                    </div>

                    <p className="text-gray pt-4 text-justify text-left xl:text-base1 font-medium text-base headlineheightt banner-font">
                      My new site designed by Elegant Carve infotech. They've
                      done a great job of creating website what I expected. Easy
                      to work with and very responsive. They met all of my
                      expectations and make us happy, I really existed. Thank
                      you so much!.
                    </p>
                    <h1 className=" banner-font font-semibold xl:text-lg mt-4">
                      Deepthi Srinivasasa
                    </h1>
                    <p className=" banner-font ">CEO, IK Softec</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="xl:h-380  grid bg-light-green xl:grid-cols-1 xl:gap-4 md:grid-cols-1 bg-light-green xl:p-2 md:p-6 mb-8 xl:mb-0 p-2 rounded-xl ">
            <div className="cursor-pointer xl:p-12 xl:pt-12">
              <div className="xl:flex lg:flex md:flex flex-none w-12/12 ">
                <div className="w-3/12 xl:pt-2">
                  <img
                    src="https://i.ibb.co/BBwJknR/fashion-men-business-mens-fashion.jpg"
                    className="rounded-md xl:w-40"
                  />
                </div>
                <div className="md:w-9/12 w-12/12 text-gray text-left xl:pl-12 lg:pl-8 pl-0 md:pt-0 pt-4">
                  <div className="flex justify-between">
                    <div>
                      <img
                        src="https://i.ibb.co/jMYQPyQ/quote-7350737-removebg-preview.png"
                        className="xl:w-12 lg:w-12 md:w-16 w-12"
                      />
                    </div>
                    <div className="gap-1  xl:pr-1">
                      <div className="center-items">
                        <a href="https://ibc.iksoftec.com/" target="_blank">
                          <img
                            src="https://i.ibb.co/p3gG2G8/ibc.png"
                            className="xl:w-36 lg:w-32 md:w-32 w-24 xl:-mt-3"
                          />
                        </a>
                      </div>
                      <div className="flex pt-2 xl:pl-16 lg:pl-12 md:pl-10">
                        <img
                          src="https://i.ibb.co/K2N8PVv/star-14442199-removebg-preview.png"
                          className="w-4 h-4"
                        />
                        <img
                          src="https://i.ibb.co/K2N8PVv/star-14442199-removebg-preview.png"
                          className="w-4 h-4"
                        />
                        <img
                          src="https://i.ibb.co/K2N8PVv/star-14442199-removebg-preview.png"
                          className="w-4 h-4"
                        />
                        <img
                          src="https://i.ibb.co/K2N8PVv/star-14442199-removebg-preview.png"
                          className="w-4 h-4"
                        />
                        <img
                          src="https://i.ibb.co/K2N8PVv/star-14442199-removebg-preview.png"
                          className="w-4 h-4"
                        />
                      </div>
                    </div>
                  </div>

                  <p className="text-gray pt-4 text-left text-justify xl:text-base1 font-medium text-base headlineheightt banner-font">
                    They Never stop unless the client is satisfied, It’s a very
                    good Web design and developing company. project submitted in
                    a particular time and date. The Elegant Carve team guided us
                    with current trends and helped us with smart content.
                  </p>
                  <h1 className="banner-font font-semibold xl:text-lg mt-4">
                    Hakim Safa
                  </h1>
                  <p className="banner-font xl:text-lg">
                    CEO, iBusiness Consulting
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="px-4">
            <div className="xl:h-420 grid bg-light-green xl:grid-cols-1 xl:gap-4 md:grid-cols-1 bg-light-green xl:p-2 md:p-6 p-2 rounded-xl ">
              <div className="cursor-pointer xl:p-12 xl:pt-12">
                <div className="xl:flex lg:flex md:flex flex-none w-12/12 ">
                  <div className="w-3/12 xl:pt-2">
                    <img
                      src="https://i.ibb.co/BBwJknR/fashion-men-business-mens-fashion.jpg"
                      className="rounded-md xl:w-40"
                    />
                  </div>
                  <div className="md:w-9/12 w-12/12 text-gray text-left xl:pl-12 lg:pl-8 pl-0 md:pt-0 pt-4">
                    <div className="flex justify-between">
                      <div>
                        <img
                          src="https://i.ibb.co/jMYQPyQ/quote-7350737-removebg-preview.png"
                          className="xl:w-12 lg:w-12 md:w-16 w-12"
                        />
                      </div>
                      <div className="flex gap-1 pt-2">
                        <img
                          src="https://i.ibb.co/K2N8PVv/star-14442199-removebg-preview.png"
                          className="w-4 h-4"
                        />
                        <img
                          src="https://i.ibb.co/K2N8PVv/star-14442199-removebg-preview.png"
                          className="w-4 h-4"
                        />
                        <img
                          src="https://i.ibb.co/K2N8PVv/star-14442199-removebg-preview.png"
                          className="w-4 h-4"
                        />
                        <img
                          src="https://i.ibb.co/K2N8PVv/star-14442199-removebg-preview.png"
                          className="w-4 h-4"
                        />
                        <img
                          src="https://i.ibb.co/K2N8PVv/star-14442199-removebg-preview.png"
                          className="w-4 h-4"
                        />
                      </div>
                    </div>

                    <p className="text-gray pt-4 text-justify text-left xl:text-base1 font-medium text-base headlineheight banner-font">
                      "Working with Ram was an exceptional experience. Their
                      expertise and creativity turned my vision into a stunning
                      website. They were attentive to my needs, responsive to my
                      feedback, and delivered a website that exceeded my
                      expectations. I appreciate their professionalism and
                      dedication to making my online presence a success. I
                      highly recommend Elegant Carve for anyone seeking
                      top-notch web design services."
                    </p>
                    <h1 className=" banner-font font-semibold xl:text-lg pt-4">
                      Kirana Tm
                    </h1>
                    <p className=" banner-font ">-----------------</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="xl:h-380  grid bg-light-green xl:grid-cols-1 xl:gap-4 md:grid-cols-1 bg-light-green xl:p-2 md:p-6 mb-8 xl:mb-0 p-2 rounded-xl ">
            <div className="cursor-pointer xl:p-12 xl:pt-12">
              <div className="xl:flex lg:flex md:flex flex-none w-12/12 ">
                <div className="w-3/12 xl:pt-2">
                  <img
                    src="https://i.ibb.co/BBwJknR/fashion-men-business-mens-fashion.jpg"
                    className="rounded-md xl:w-40"
                  />
                </div>
                <div className="md:w-9/12 w-12/12 text-gray text-left xl:pl-12 lg:pl-8 pl-0 md:pt-0 pt-4">
                  <div className="flex justify-between">
                    <div>
                      <img
                        src="https://i.ibb.co/jMYQPyQ/quote-7350737-removebg-preview.png"
                        className="xl:w-12 lg:w-12 md:w-16 w-12"
                      />
                    </div>
                    <div className="flex gap-1 pt-2">
                      <img
                        src="https://i.ibb.co/K2N8PVv/star-14442199-removebg-preview.png"
                        className="w-4 h-4"
                      />
                      <img
                        src="https://i.ibb.co/K2N8PVv/star-14442199-removebg-preview.png"
                        className="w-4 h-4"
                      />
                      <img
                        src="https://i.ibb.co/K2N8PVv/star-14442199-removebg-preview.png"
                        className="w-4 h-4"
                      />
                      <img
                        src="https://i.ibb.co/K2N8PVv/star-14442199-removebg-preview.png"
                        className="w-4 h-4"
                      />
                      <img
                        src="https://i.ibb.co/K2N8PVv/star-14442199-removebg-preview.png"
                        className="w-4 h-4"
                      />
                    </div>
                  </div>

                  <p className="text-gray pt-4 text-left text-justify xl:text-base1 font-medium text-base headlineheight banner-font">
                    Nice company to deal with I provided everything which I
                    needed from NewZealand Ram made a tailor made solution and
                    helped me through the process in making my website
                  </p>
                  <h1 className="banner-font font-semibold xl:text-lg mt-4">
                    shashidhar pola
                  </h1>
                  <p className="banner-font ">----------</p>
                </div>
              </div>
              <div className="xl:pl-56 flex justify-between xl:-mt-4"></div>
            </div>
          </div>
        </Slider>
      </div>
    </section>
  );
};

export default SliderPage;
