import React, { useState } from "react";
import EClogowhite from "assets/img/EClogowhite.png";
import eclogohead from "assets/img/eclogohead.png";
import APPDEVELOPMENT from "assets/img/imgs/APPDEVELOPMENT.png";
import ECOMMERCE from "assets/img/imgs/ECOMMERCE.png";
import MARKETING from "assets/img/imgs/MARKETING.png";
import WEBDESIGN from "assets/img/imgs/WEBDESIGN.png";
import WEBDEVELOPMENT from "assets/img/imgs/WEBDEVELOPMENT.png";
import TESTIMONIALS from "assets/img/imgs/TESTIMONIALS.png";
import ABOUTUS from "assets/img/imgs/ABOUTUS.png";
import TEAMS from "assets/img/imgs/TEAMS.png";
import PORTFOLIO from "assets/img/imgs/PORTFOLIO.png";

const NavMobile = () => {
  const [showFirstStep, setShowFirstStep] = useState(false);
  const [showSecondStep, setShowSecondStep] = useState(false);
  const [showGroupTourStep, setShowGroupTourStep] = useState(false);

  const renderItem = () => {
    return (
      <div className="banner-font font-semibold text-blue mb-20">
        <ul>
          <li onClick={onClickDestinations} className="flex justify-between py-6 border-b">
            <span onClick={onClickDestinations}>Services</span>
            <img
              onClick={onClickDestinations}
              src="https://i.ibb.co/HxpPBbz/chevron-10009171-removebg-preview.png"
              className="mt-2 w-3 h-3"
            />
          </li>
          <li onClick={onClickGroupTour} className="flex justify-between py-6 border-b">
            <span onClick={onClickGroupTour}>About Us</span>
            <img
              onClick={onClickGroupTour}
              src="https://i.ibb.co/HxpPBbz/chevron-10009171-removebg-preview.png"
              className="mt-2 w-3 h-3"
            />
          </li>
          <li className="py-6 border-b">
            <a href="/portfolio">Portfolio</a>
          </li>
          <li className="py-6 border-b">
            <a href="/blog">Blog</a>
          </li>
          <li className="py-6 border-b">
            <a href="/contact-us">Contact Us</a>
          </li>
        </ul>
      </div>
    );
  };

  const onClickDestinations = () => {
    setShowFirstStep(false);
    setShowSecondStep(true);
    setShowGroupTourStep(false);
  };

  const onClickGroupTour = () => {
    setShowFirstStep(false);
    setShowSecondStep(false);
    setShowGroupTourStep(true);
  };

  const onClickBackButton = () => {
    setShowFirstStep(true);
    setShowSecondStep(false);
    setShowGroupTourStep(false);
  };

  const onClickClose = () => {
    setShowFirstStep(false);
    setShowSecondStep(false);
    setShowGroupTourStep(false);
    document.body.style.backgroundColor = "";
  };

  return (
    <nav className="top-0 fixed z-10 w-full flex flex-wrap items-center justify-between navbar-expand-lg bg-white lg:hidden md:block block ">
      <div className="bg-elegantcarve overflow-hidden container pr-4 flex flex-wrap items-center justify-between py-2">
        <div className="w-full relative items-center flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
          <a href="/homepage">
            <img
              className="w-6/12 md:w-22/12"
              src={EClogowhite}
              alt="Bangalore webdevelopment company"
            />
          </a>
          <div className="text-white" onClick={() => setShowFirstStep(true)}>
            ☰
          </div>
        </div>
      </div>
      {showFirstStep && (
        <div
          className="z-50 -mt-20 animate-slide-left"
          style={{
            backgroundColor:
              showFirstStep || showSecondStep || showGroupTourStep
                ? ""
                : "bg-white ",
          }}
        >
          <div className="bg-white py-6 px-6">
            <div className="flex justify-between">
              <div></div>
              <div onClick={onClickClose}>✖</div>
            </div>
            <div className=" text-blue w-full md:w-4/12 banner-font text-base dark:text-neutral-300">
              <img src={eclogohead} className="w-12 mb-2" />
              <span>
                <b>Elegant Carve Infotech </b> is a trusted IT consultancy in
                Bangalore, offering top-notch services for businesses.
              </span>
            </div>
          </div>
          <ul className="flex flex-col py-2 md:py-0 px-6 space-y-1">{renderItem()}</ul>
          <div className="flex justify-between items-center mt-4 mx-4"></div>
        </div>
      )}

      {showSecondStep && (
        <>
          <div className="z-51 overflow-y-auto w-full bg-white dark:bg-neutral-900 animate-slide-left -mt-20">
            <div className="py-8 px-4 flex items-center bg-gray-100 ">
              <div
                className="ml-2 h-6 w-6 text-neutral-500 cursor-pointer top-2"
                aria-hidden="true"
                onClick={onClickBackButton}
              >
                <span className="font-extrabold">〱</span>
              </div>
              <h1 className="font1 w-full px-4 font-semibold uppercase tracking-wide text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg">
                Services
              </h1>
            </div>
          </div>
          <div className="pb-32 px-6 mb-16 animate-slide-left">
            <div className="flex">
              <div className="lg:w-3/12 md:w-00/12 w-3/12">
                <img className="w-20" src={WEBDESIGN} alt="..." />
              </div>
              <div className="lg:w-7/12 md:w-7/12 pt-2">
                <a href="/service">
                  <h1 className="font-semibold text-smbanner-font text-blue">
                    Web Design
                  </h1>
                  <p className="text-xs text-charcoal400 head1 pt-2">
                    We are deliver a high quality services and success for any
                    business.
                  </p>
                </a>
              </div>
            </div>
            <div className="flex pt-6">
              <div className="lg:w-3/12 md:w-00/12 w-3/12  ">
                <img className=" w-20" src={WEBDEVELOPMENT} alt="..." />
              </div>
              <div className="lg:w-7/12 md:w-7/12 pt-2">
                <a href="/service">
                  <h1 className="font-semibold text-smbanner-font text-blue">
                    Web Development
                  </h1>
                  <p className="text-xs text-charcoal400 pt-2 head1">
                    We Develop your website with the latest trends based on your
                    needs.
                  </p>
                </a>
              </div>
            </div>
            <div className="flex  pt-6 ">
              <div className="lg:w-3/12 md:w-00/12 w-3/12  ">
                <img className="w-20" src={MARKETING} alt="..." />
              </div>
              <div className="lg:w-7/12 md:w-7/12 pt-2">
                <a href="/service">
                  <h1 className="font-semibold text-smbanner-font text-blue">
                    Marketing
                  </h1>
                  <p className="text-xs text-charcoal400 pt-2">
                    Our team understands the need of our clients to generate
                    better results.
                  </p>
                </a>
              </div>
            </div>
            <div className="flex pt-6">
              <div className="lg:w-3/12 md:w-00/12 w-3/12  ">
                <img className="w-20" src={APPDEVELOPMENT} alt="..." />
              </div>
              <div className="lg:w-7/12 md:w-7/12 pt-2">
                <a href="/service">
                  <h1 className="font-semibold text-smbanner-font text-blue">
                    App Development
                  </h1>
                  <p className="text-xs text-charcoal400 pt-2">
                    This is a functional piece of software driven by dynamic and
                    interactive.
                  </p>
                </a>
              </div>
            </div>
            <div className="flex pt-6">
              <div className="lg:w-3/12 md:w-00/12 w-3/12  ">
                <img className="w-20" src={ECOMMERCE} alt="..." />
              </div>
              <div className="lg:w-7/12 md:w-7/12 pt-2">
                <a href="/service">
                  <h1 className="font-semibold text-smbanner-font text-blue">
                    E-Commerce
                  </h1>
                  <p className="text-xs text-charcoal400 pt-2 hover:text-lightBlue-600:hover">
                    We will technology establish effective connect with your
                    target audience.
                  </p>
                </a>
              </div>
            </div>
          </div>
        </>
      )}

      {showGroupTourStep && (
        <>
          <div className="overflow-y-auto w-full bg-white dark:bg-neutral-900 animate-slide-left -mt-20">
            <div className="py-8 px-4 flex items-center bg-gray-100 ">
              <div
                className="ml-2 h-6 w-6 text-neutral-500 cursor-pointer top-2"
                aria-hidden="true"
                onClick={onClickBackButton}
              >
                <span className="font-extrabold">〱</span>
              </div>
              <h1 className="font1 w-full px-4 font-semibold uppercase tracking-wide text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg">
                About Us
              </h1>
            </div>
          </div>
          <div className="pb-48 px-6 mb-16 animate-slide-left">
            <div className="flex">
              <div className="lg:w-3/12 md:w-00/12 w-3/12  ">
                <img className="w-24" src={ABOUTUS} alt="..." />
              </div>
              <div className="lg:w-7/12 md:w-7/12 pt-2">
                <a href="/service">
                  <h1 className="font-semibold text-smbanner-font text-blue">
                    About Us
                  </h1>
                  <p className="text-xs text-charcoal400 head1 pt-2">
                    We are most recognized and customers favored website design
                    company in bangalore.
                  </p>
                </a>
              </div>
            </div>
            <div className="flex pt-6">
              <div className="lg:w-3/12 md:w-00/12 w-3/12  ">
                <img className=" w-18" src={TEAMS} alt="..." />
              </div>
              <div className="lg:w-7/12 md:w-7/12 pt-2">
                <a href="/service">
                  <h1 className="font-semibold text-smbanner-font text-blue">
                    Team
                  </h1>
                  <p className="text-xs text-charcoal400 pt-2 head1">
                    Our team will do each task better and faster to complete
                    your work.
                  </p>
                </a>
              </div>
            </div>
            <div className="flex  pt-6 ">
              <div className="lg:w-3/12 md:w-00/12 w-3/12  ">
                <img className="w-24" src={PORTFOLIO} alt="..." />
              </div>
              <div className="lg:w-7/12 md:w-7/12 pt-2">
                <a href="/service">
                  <h1 className="font-semibold text-smbanner-font text-blue">
                    Our Team
                  </h1>
                  <p className="text-xs text-charcoal400 pt-2">
                    We could help you in different areas like website design,
                    logo design and brochure design
                  </p>
                </a>
              </div>
            </div>
            <div className="flex pt-6">
              <div className="lg:w-3/12 md:w-00/12 w-3/12  ">
                <img className="w-36" src={TESTIMONIALS} alt="..." />
              </div>
              <div className="lg:w-7/12 md:w-7/12 pt-2">
                <a href="/service">
                  <h1 className="font-semibold text-smbanner-font text-blue">
                    Testimonials
                  </h1>
                  <p className="text-xs text-charcoal400 pt-2">
                    Here is an appreciation to our work from so many happy
                    customers, we really motivated by those words from our
                    statisfied customers.
                  </p>
                </a>
              </div>
            </div>
          </div>
        </>
      )}
    </nav>
  );
};

export default NavMobile;
