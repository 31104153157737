import React, { useEffect, useRef, useState } from "react";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import elegantcarvebg from "assets/img/elegantcarvebg.jpg";
import clients1 from "assets/img/clients1.png";
import clients2 from "assets/img/clients2.png";
import clients3 from "assets/img/clients3.png";
import clients6 from "assets/img/clients6.png";
import clients7 from "assets/img/clients7.png";
import clients16 from "assets/img/clients16.png";
import SliderPage from "views/SliderPage";
import elegantbgsection from "assets/img/elegantbgsection.jpg";
import supportt from "assets/img/imgs/supportt.png";
import Footer2 from "components/Footers/Footer2";
import WorkingTool from "./WorkingTool";
import darkbluebackground from "assets/img/darkbluebackground.avif";

export default function Homepage1() {
  // counting the numbers
  const Counter = ({ end, time, delay }) => {
    const [count, setCount] = useState(0);

    useEffect(() => {
      let start = 0;
      const endNum = parseFloat(end.replace(/,/g, "")); // Remove commas
      const increment = endNum / (time / delay);

      const timer = setInterval(() => {
        start += increment;
        if (start >= endNum) {
          clearInterval(timer);
          setCount(end); // Set to the end value to handle commas and formatting
        } else {
          setCount(start.toFixed(end.includes(".") ? 2 : 0)); // Handle decimals if needed
        }
      }, delay);

      return () => clearInterval(timer); // Cleanup interval on component unmount
    }, [end, time, delay]);

    return <span>{count}</span>;
  };

  // 4 tab
  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  /* scrolldown slowly coming animation start */

  const sections = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("animate");
            observer.unobserve(entry.target); // Stop observing the section once it's animated
          }
        });
      },
      { threshold: 0.4 }
    );

    sections.current.forEach((section) => {
      if (section && section instanceof Element) {
        observer.observe(section);
      }
    });

    const handleScroll = debounce(() => {
      sections.current.forEach((section) => {
        if (section && section instanceof Element) {
          observer.observe(section);
        }
      });
    }, 100);

    window.addEventListener("scroll", handleScroll);

    return () => {
      observer.disconnect();
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function debounce(func, delay) {
    let timeout;
    return function (...args) {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(context, args), delay);
    };
  }

  /* scrolldown slowly coming animation end */

  return (
    <>
      {/* Banner every screen view in one code */}
      <IndexNavbar fixed />
      <section
        ref={(el) => sections.current.push(el)}
        style={{
          // backgroundImage: `url(${elegantcarvebg})`,
          backgroundColor: "#00244f",
          width: "100%",
          backgroundSize: "cover",
        }}
        className="xl:h-665 lg:h-510 md:h-400 h-450 animate-on-scroll xl:mt-0 lg:mt-0 md:mt-16 mt-8"
      >
        <div className="container xl:pt-48 lg:pt-40 md:pt-16 pt-0 mx-auto justify-center flex flex-wrap ">
          <div className="w-full md:w-9/12 lg:w-8/12 xl:w-88/12 ">
            <div className="pt-32 sm:pt-0">
              <h1 className="slide-from-left text-center font-extrabold xl:text-6xl lg:text-4xl md:text-3xl text-2xl text-white uppercase banner-font">
                Create Stunning Websites Without Limits
              </h1>
              <p className="slide-from-right xl:px-20  px-4 xl:mt-6 mt-2 text-center xl:text-xl text-base text-white leading-relaxed headlineheight banner-font">
                Spend more time conducting business and less managing it with
                just one tool that lets you take care of all your business
                operations
              </p>
              <div className="xl:px-52 lg:px-28 md:px-24 px-4 pt-4 grid xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-4 grid-cols-4">
                <a href="/webdesign">
                  <div className="center-items">
                    <img
                      src="https://i.ibb.co/tMmwhRk/webdesign.png"
                      className="xl:w-24 lg:w-20 md:w-20 w-16"
                    />
                    <p className="banner-font md:text-base text-sm text-white font-medium text-center">
                      Web Design
                    </p>
                  </div>
                </a>
                <a href="/webdevelop">
                  <div className="center-items">
                    <img
                      src="https://i.ibb.co/yQmkgQH/appdevelopemnt.png"
                      className="xl:w-24 lg:w-20 md:w-20 w-16"
                    />
                    <p className="banner-font md:text-base text-white text-sm font-medium text-center">
                      Developemnt
                    </p>
                  </div>
                </a>
                <a href="/appdevelop">
                  <div className="center-items">
                    <img
                      src="https://i.ibb.co/W6m08W9/Appdevelopment.png"
                      className="xl:w-24 lg:w-20 md:w-20 w-16"
                    />
                    <p className="banner-font md:text-base text-sm text-white font-medium text-center">
                      App
                    </p>
                  </div>
                </a>
                <a href="/ecommerce">
                  <div className="center-items">
                    <img
                      src="https://i.ibb.co/tPrWDKr/e-commerce.png"
                      className="xl:w-24 lg:w-20 md:w-20 w-16"
                    />
                    <p className="banner-font md:text-base text-sm text-white font-medium text-center">
                      E-Commerce
                    </p>
                  </div>
                </a>
              </div>
              <div className="center-items xl:mt-8 lg:mt-6 mt-4">
                <button className="bg-button border-white text-sm banner-font text-white xl:px-10 lg:px-6 px-6 md:px-6 py-2 font-medium">
                  Explore ➝
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Trusted Clients All screen View*/}

      <section
        ref={(el) => sections.current.push(el)}
        className="animate-on-scroll bg-white md:block block scroll-section"
      >
        <div className="container mx-auto xl:pt-16 lg:pt-12 md:pt-12 pt-8 pb-8 xl:pb-8">
          <h1 className="banner-font text-charcoal500 text-center text-bold md:text-2xl text-xl md:pb-4">
            Trusted by <span className="text-black font-extrabold"> 50+ </span>
            Companies Worldwide
          </h1>
          <div className="slider-container-logo flex lg:w-9/12 md:w-9/12 w-11/12 mx-auto pt-6">
            <div className="slider-item-logo lg:w-2/12 md:w-2/12 w-3/12">
              <img
                className="xl:w-32 lg:w-24 md:w-24 w-20"
                src={clients1}
                alt="top web and mobile app development companies"
              />
            </div>
            <div className="slider-item-logo lg:w-2/12 md:w-2/12 w-3/12">
              <img
                className="xl:w-32 lg:w-24 md:w-24 w-20"
                src={clients2}
                alt="best website designers near me"
              />
            </div>
            <div className="slider-item-logo lg:w-2/12 md:w-2/12 w-3/12">
              <img
                className="xl:w-32 lg:w-24 md:w-24 w-20"
                src={clients3}
                alt="software company in Bangalore"
              />
            </div>
            <div className="slider-item-logo lg:w-2/12 md:w-2/12 w-3/12">
              <img
                className="xl:w-32 lg:w-24 md:w-24 w-20"
                src={clients7}
                alt="best website designers in Bangalore"
              />
            </div>
            <div className="slider-item-logo lg:w-2/12 md:w-2/12 w-3/12">
              <img
                className="xl:w-32 lg:w-24 md:w-24 w-20"
                src={clients16}
                alt="best app development company in Bangalore"
              />
            </div>
            <div className="slider-item-logo lg:w-2/12 md:w-2/12 w-3/12">
              <img
                className="xl:w-32 lg:w-24 md:w-24 w-20"
                src={clients6}
                alt="best ecommerce web design"
              />
            </div>
            <div className="slider-item-logo lg:w-2/12 md:w-2/12 w-3/12">
              <img
                className="xl:w-32 lg:w-24 md:w-24 w-20"
                src={clients1}
                alt="top web and mobile app development companies"
              />
            </div>
            <div className="slider-item-logo lg:w-2/12 md:w-2/12 w-3/12">
              <img
                className="xl:w-32 lg:w-24 md:w-24 w-20"
                src={clients2}
                alt="best website designers near me"
              />
            </div>
            <div className="slider-item-logo lg:w-2/12 md:w-2/12 w-3/12">
              <img
                className="xl:w-32 lg:w-24 md:w-24 w-20"
                src={clients3}
                alt="software company in Bangalore"
              />
            </div>
            <div className="slider-item-logo lg:w-2/12 md:w-2/12 w-3/12">
              <img
                className="xl:w-32 lg:w-24 md:w-24 w-20"
                src={clients7}
                alt="best website designers in Bangalore"
              />
            </div>
            <div className="slider-item-logo lg:w-2/12 md:w-2/12 w-3/12">
              <img
                className="xl:w-32 lg:w-24 md:w-24 w-20"
                src={clients16}
                alt="best app development company in Bangalore"
              />
            </div>
            <div className="slider-item-logo lg:w-2/12 md:w-2/12 w-3/12">
              <img
                className="xl:w-32 lg:w-24 md:w-24 w-20"
                src={clients6}
                alt="best ecommerce web design"
              />
            </div>
          </div>
        </div>
      </section>

      {/* <section
        ref={(el) => sections.current.push(el)}
        style={{
          backgroundImage: `url(${elegantbgsection})`,
          width: "100%",
          backgroundSize: "cover",
        }}
        className="animate-on-scroll"
      >
        <div className="container mx-auto overflow-hidden px-4 xl:py-16 lg:py-8 md:py-12 py-6">
          <p className="text-center text-sm text-blue font-semibold banner-font">
            ABOUT US
          </p>
          <h2 className="xl:px-72 xl:pt-4 pt-3 text-center font-bold xl:text-4xl lg:text-2xl md:text-2xl text-xl lg:text-2xl md:text-2xl text-xl text-black banner-font">
            The Most Powerful Hosting Platform
          </h2>
          <p className="xl:px-84 lg:px-56 md:px-32 px-4 lg:px-56 md:px-40 px-4 xl:mt-3 mt-0 md:mt-1 text-center xl:text-base1 text-base  text-gray headlineheightt banner-font">
            We offer a wide range of hosting solutions, from web hosting to
            dedicated servers. Everything you need is right here.
          </p>
          <div className="xl:pt-12 pt-8 xl:gap-4 lg:gap-4 md:gap-2 gap-2 xl:px-12 lg:px-12 md:px-0 px-8 grid xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-4 grid-cols-1">
            <a href="">
              <div className="bg-white rounded-md shadow-xl center-items xl:p-12 p-8">
                <img
                  src="https://i.ibb.co/QC3L1LR/project-management-1087927-removebg-preview.png"
                  className="xl:w-16 lg:w-16 md:w-12 w-12 rounded-full xl:p-4 lg:p-3 md:p-3 p-2 bg-light-bluee"
                />
                <div className="xl:w-24 lg:w-20 md:w-20 w-24 border-t-blue xl:pb-6 pb-4" />
                <p className="xl:pt-6 pt-4 xl:text-base1 banner-font font-semibold text-center header-on-hover">
                  Project manage
                </p>
                <p className="xl:pt-2 text-center xl:text-base1 text-base  text-gray leading-relaxed headlineheight banner-font">
                  Cras eu semper neque, sit amet aliquet odio.
                </p>
              </div>
            </a>
            <a href="">
              <div className="bg-white rounded-md shadow-xl center-items xl:p-12 p-8">
                <img
                  src="https://i.ibb.co/QC3L1LR/project-management-1087927-removebg-preview.png"
                  className="xl:w-16 lg:w-16 md:w-12 w-12 rounded-full xl:p-4 lg:p-3 md:p-3 p-2 bg-light-orangee"
                />
                <div className="xl:w-24 lg:w-20 md:w-20 w-24  border-t-orange xl:pb-6 pb-4" />
                <p className="xl:pt-6  pt-4 xl:text-base1 banner-font font-semibold text-center header-on-hover">
                  Responsive design
                </p>
                <p className="xl:pt-2 text-center xl:text-base1 text-base  text-gray leading-relaxed headlineheight banner-font">
                  Cras eu semper neque, sit amet aliquet odio.
                </p>
              </div>
            </a>
            <a href="">
              <div className="bg-white rounded-md shadow-xl center-items xl:p-12 p-8">
                <img
                  src="https://i.ibb.co/QC3L1LR/project-management-1087927-removebg-preview.png"
                  className="xl:w-16 lg:w-16 md:w-12 w-12 rounded-full xl:p-4 lg:p-3 md:p-3 p-2 bg-light-redd"
                />
                <div className="xl:w-24 lg:w-20 md:w-20 w-24  border-t-red xl:pb-6 pb-4" />
                <p className="xl:pt-6 pt-4  xl:text-base1 banner-font font-semibold text-center header-on-hover">
                  Easy Customize
                </p>
                <p className="xl:pt-2 text-center xl:text-base1 text-base  text-gray leading-relaxed headlineheight banner-font">
                  Cras eu semper neque, sit amet aliquet odio.
                </p>
              </div>
            </a>
            <a href="">
              <div className="bg-white rounded-md shadow-xl center-items xl:p-12 p-8">
                <img
                  src="https://i.ibb.co/QC3L1LR/project-management-1087927-removebg-preview.png"
                  className="xl:w-16 lg:w-16 md:w-12 w-12 rounded-full xl:p-4 lg:p-3 md:p-3 p-2 bg-light-green"
                />
                <div className="xl:w-24 lg:w-20 md:w-20 w-24  border-t-green xl:pb-6 pb-4" />
                <p className="xl:pt-6 pt-4 xl:text-base1 banner-font font-semibold text-center header-on-hover">
                  Dedicated Support
                </p>
                <p className="xl:pt-2 text-center xl:text-base1 text-base  text-gray leading-relaxed headlineheight banner-font">
                  Cras eu semper neque, sit amet aliquet odio.
                </p>
              </div>
            </a>
          </div>
        </div>
      </section> */}

      {/* Tab section */}
      <section
        ref={(el) => sections.current.push(el)}
        className="bg-light-gray animate-on-scroll"
      >
        <div className="container mx-auto overflow-hidden px-4 xl:py-20 lg:py-12 py-6">
          {/* <h2 className="slide-from-right xl:px-56 text-center font-medium xl:text-4xl lg:text-2xl md:text-2xl text-xl text-black banner-font">
            Take your site online with our hosting plans
          </h2>
          <p className="slide-from-left xl:px-84 lg:px-56 md:px-32 px-4 xl:mt-3 mt-1 text-center xl:text-base1 text-base  text-gray headlineheightt banner-font">
            We offer a wide range of hosting solutions, from web hosting to
            dedicated servers. Everything you need is right here.
          </p> */}
          <div className="">
            <div className="grid xl:grid-cols-4 xl:px-52 lg:px-20 md:px-12 px-0 md:grid-cols-4 grid-cols-2  border-b-c xl:gap-0 gap-2 ">
              <button
                className={`${
                  activeTab === 1
                    ? "active text-white white-on-hover font-medium bg-elegantcarve"
                    : "text-gray header-on-hover"
                } font font-medium xl:pt-2 xl:py-0 py-2`}
                onClick={() => handleTabClick(1)}
              >
                <p className="text-base xl:mb-2 ">Web Design</p>
              </button>

              <button
                className={`${
                  activeTab === 2
                    ? "active text-white white-on-hover font-medium bg-elegantcarve"
                    : "text-gray header-on-hover"
                } font font-medium xl:pt-2 xl:py-0 py-2`}
                onClick={() => handleTabClick(2)}
              >
                <p className="text-base xl:mb-2">Web Development</p>
              </button>

              <button
                className={`${
                  activeTab === 3
                    ? "active text-white white-on-hover font-medium bg-elegantcarve"
                    : "text-gray header-on-hover"
                } font font-medium xl:pt-2 xl:py-0 py-2`}
                onClick={() => handleTabClick(3)}
              >
                <p className="text-base xl:mb-2">App Development</p>
              </button>

              <button
                className={`${
                  activeTab === 4
                    ? "active text-white white-on-hover font-medium bg-elegantcarve"
                    : "text-gray header-on-hover"
                } font font-medium xl:pt-2 xl:py-0 py-2`}
                onClick={() => handleTabClick(4)}
              >
                <p className="text-base xl:mb-2">E-Commerce</p>
              </button>
            </div>

            <div className="xl:pt-0 md:pt-0 pt-4">
              <div className="tab-content text-center">
                {activeTab === 1 && (
                  <div className="content-center xl:px-16 lg:px-16 md:px-4">
                    <div className="grid border bg-light-green rounded-xll xl:p-5 p-5 xl:grid-cols-2 md:grid-cols-2 text-lg xl:mt-8 lg:mt-8 mt-4">
                      <div className="font text-left xl:text-base1 text-sm xl:pt-12 lg:pt-12 md:pt-8 lg:pl-12 md:pl-8 pl-2 xl:pl-12 xl:pb-12 slide-from-right">
                        <h2 className="xl:pt-6 pt-4 text-left xl:text-4xl lg:text-3xl md:text-2xl text-xl text-black banner-font">
                          Let us do it for you – professionals
                        </h2>
                        <p className="mt-3 ext-left xl:text-base1 text-base  text-gray leading-relaxed headlineheightt banner-font">
                          We offer a wide range of hosting solutions, from web
                          hosting to dedicated servers. Everything you need is
                          right here.
                        </p>
                        <button className="xl:mb-0 lg:mb-8 md:mb-8 mb-0 bg-button border-white text-white xl:text-sm xl:px-8 xl:mt-4 mt-4 lg:px-6 md:px-6 px-4 py-2 font-medium">
                          Get Started ➻
                        </button>
                      </div>
                      <div className="xl:p-5 p-5 center-items xl:pt-8 slmd:pt-8 pt-12 ide-from-left">
                        <img
                          src="https://i.ibb.co/WFJ7wfM/rfvf.png"
                          alt="Your browser does not support this content"
                          className="rounded-xl xl:w-60 lg:w-56 md:w-44 w-40"
                        />
                      </div>
                    </div>
                  </div>
                )}
                {activeTab === 2 && (
                  <div className="content-center xl:px-16 lg:px-16 md:px-4">
                    <div className="grid border bg-light-green rounded-xll  xl:p-5 p-5 xl:grid-cols-2 md:grid-cols-2 text-lg xl:mt-8 lg:mt-8 mt-4">
                      <div className="font text-left xl:text-base1 text-sm xl:pt-12 lg:pt-12 md:pt-8 lg:pl-12 md:pl-8 pl-2 xl:pl-12 xl:pb-12 slide-to-right">
                        <h2 className="xl:pt-6 pt-4 text-left xl:text-4xl lg:text-3xl md:text-2xl text-xl text-black banner-font">
                          Invite the world to belive in you
                        </h2>
                        <p className=" mt-3 text-left xl:text-base1 text-base  text-gray leading-relaxed headlineheightt banner-font">
                          We offer a wide range of hosting solutions, from web
                          hosting to dedicated servers. Everything you need is
                          right here.
                        </p>
                        <button className="xl:mb-0 lg:mb-8 md:mb-8 mb-0 bg-button border-white text-white xl:text-sm xl:px-8 xl:mt-4 mt-4 lg:px-6 md:px-6 px-4 py-2 font-medium">
                          Get Started ➻
                        </button>
                      </div>
                      <div className="xl:p-5 p-5 center-items xl:pt-8 md:pt-8 pt-12 slide-to-left">
                        <img
                          src="https://i.ibb.co/BNXkyQk/4f4f.png"
                          alt="Your browser does not support this content"
                          className="rounded-xl xl:w-60 lg:w-56 md:w-44 w-40"
                        />
                      </div>
                    </div>
                  </div>
                )}
                {activeTab === 3 && (
                  <div className="content-center xl:px-16 lg:px-16 md:px-4">
                    <div className="grid border bg-light-green rounded-xll  xl:p-5 p-5 xl:grid-cols-2 md:grid-cols-2 text-lg xl:mt-8 lg:mt-8 mt-4">
                      <div className="font text-left xl:text-base1 text-sm xl:pt-12 lg:pt-12 md:pt-8 lg:pl-12 md:pl-8 pl-2 xl:pl-12 xl:pb-12 slide-from-right">
                        <h2 className="xl:pt-6 pt-4 text-left xl:text-4xl lg:text-3xl md:text-2xl text-xl text-black banner-font">
                          The best solution to get online.
                        </h2>
                        <p className=" mt-3 text-left xl:text-base1 text-base  text-gray leading-relaxed headlineheightt banner-font">
                          The process for launching your website is as easy as
                          clicking a button. We provide all the information you
                          need in a clear manner.
                        </p>
                        <button className="xl:mb-0 lg:mb-8 md:mb-8 mb-0 bg-button border-white text-white xl:text-sm xl:px-8 xl:mt-4 mt-4 lg:px-6 md:px-6 px-4 py-2 font-medium">
                          Get Started ➻
                        </button>
                      </div>
                      <div className="xl:p-5 p-5 center-items xl:pt-8 slmd:pt-8 pt-12 ide-from-left">
                        <img
                          src="https://i.ibb.co/RSnL0NC/g5g-1190x1536.png"
                          alt="Your browser does not support this content"
                          className="rounded-xl xl:w-60 lg:w-56 md:w-44 w-40"
                        />
                      </div>
                    </div>
                  </div>
                )}
                {activeTab === 4 && (
                  <div className="content-center xl:px-16 lg:px-16 md:px-4">
                    <div className="grid border rounded-xll bg-light-green  xl:p-5 p-5 xl:grid-cols-2 md:grid-cols-2 text-lg xl:mt-8 lg:mt-8 mt-4">
                      <div className="font text-left xl:pt-12 lg:pt-12 md:pt-8 lg:pl-12 md:pl-8 pl-2 xl:pl-12 xl:pb-12 slide-to-right">
                        <h2 className="xl:pt-6 pt-4 text-left xl:text-4xl lg:text-3xl md:text-2xl text-xl text-black banner-font">
                          Take your site online with our hosting
                        </h2>
                        <p className="mt-3 text-left xl:text-base1 text-base  text-gray leading-relaxed headlineheightt banner-font">
                          We offer a wide range of hosting solutions, from web
                          hosting to dedicated servers. Everything you need is
                          right here.
                        </p>
                        <button className="xl:mb-0 lg:mb-8 md:mb-8 mb-0 bg-button border-white text-white xl:text-sm xl:px-8 xl:mt-4 mt-4 lg:px-6 md:px-6 px-4 py-2 font-medium">
                          Get Started ➻
                        </button>
                      </div>
                      <div className="xl:p-5 p-5 center-items xl:pt-8 md:pt-8 pt-12 slide-to-left">
                        <img
                          src="https://i.ibb.co/WFJ7wfM/rfvf.png"
                          alt="Your browser does not support this content"
                          className="rounded-xl xl:w-60 lg:w-56 md:w-44 w-40"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* counting section */}

      <section
        ref={(el) => sections.current.push(el)}
        className="animate-on-scroll bg xl:pt-20 lg:pt-12 md:pt-12 pt-8"
      >
        <div className="container mx-auto xl:px-16 lg:px-16 md:px-0">
          <div className="grid md:grid-cols-2 grid-cols-1">
            <div className="pl-10">
              <h2 className="text-medium xl:text-4xl lg:text-2xl md:text-2xl text-xl text-white banner-font">
                Made in India.
              </h2>
              <h2 className="text-medium xl:text-4xl lg:text-2xl md:text-2xl text-xl text-white banner-font">
                Made for the World.
              </h2>
              <div>
                <div className="grid grid-cols-2 xl:mt-12 md:mt-8 mt-8">
                  <div className="grid grid-cols-2">
                    <div className="col-md-2 col-md-offset-2">
                      <h1 className="text-extrabold xl:text-4xl text-white text-2xl">
                        <Counter end="100M+" time={1000} delay={10} />
                      </h1>
                    </div>
                    <p className="text-white text-sm banner-font font-medium border-b pb-2 mr-12">
                      Users <br></br>Globally
                    </p>
                  </div>
                  <div className="grid grid-cols-2">
                    <div className="col-md-2 col-md-offset-2">
                      <h1 className="text-extrabold xl:text-4xl text-white text-2xl">
                        <Counter end="150+" time={1000} delay={10} />
                      </h1>
                    </div>
                    <p className="text-white text-sm banner-font font-medium -ml-6 border-b pb-2 mr-12">
                      Countries <br></br>Served
                    </p>
                  </div>
                </div>
                <div className="grid grid-cols-2 mt-8">
                  <div className="grid grid-cols-2">
                    <div className="col-md-2 col-md-offset-2">
                      <h1 className="text-extrabold xl:text-4xl text-white text-2xl">
                        <Counter end="15k+" time={1000} delay={10} />
                      </h1>
                    </div>
                    <p className="text-white text-sm banner-font font-medium border-b pb-2 mr-12">
                      Employees <br></br>Worldwide
                    </p>
                  </div>
                  <div className="grid grid-cols-2">
                    <div className="col-md-2 col-md-offset-2">
                      <h1 className="text-extrabold xl:text-4xl text-white text-2xl">
                        <Counter end="25+" time={1000} delay={10} />
                      </h1>
                    </div>
                    <p className="text-white text-sm banner-font font-medium -ml-6 border-b pb-2 mr-12">
                      Years In<br></br> Business
                    </p>
                  </div>
                </div>
                <div className="grid grid-cols-2 mt-8">
                  <div className="grid grid-cols-2">
                    <div className="col-md-2 col-md-offset-2">
                      <h1 className="text-extrabold xl:text-4xl text-white text-2xl">
                        <Counter end="55+" time={1000} delay={10} />
                      </h1>
                    </div>
                    <p className="text-white text-sm banner-font font-medium border-b pb-2 mr-12">
                      Products
                    </p>
                  </div>
                  <div className="pt-2">
                    <button className="text-sm text-white bg-button xl:px-8 py-2 px-6">
                      More About ➜
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <img
                src="https://i.ibb.co/DQdsG2c/made-au-bg.png"
                className="xl:w-700 xl:-mt-16 lg:-mt-8"
              />
            </div>
          </div>
        </div>
      </section>

      <WorkingTool />

      {/* Announcement Desktop View */}
      <div
        ref={(el) => sections.current.push(el)}
        className="animate-on-scroll bg-white"
      >
        <section className="container mx-auto md:block hidden xl:px-16 lg:px-16 md:px-12">
          <div className="w-full lg:w-12/12 md:w-12/12">
            <div className="flex flex-wrap">
              <div className="lg:w-6/12 md:w-6/12 pl-10">
                <img
                  className="w-8/12 xl:mt-0 xl:ml-8 lg:mt-12 lg:ml-12"
                  src={supportt}
                  alt="..."
                />
                <h1 className="texthome md:-mt-16 lg:-mt-12 xl:-mt-12 xl:-mt-8 lg:pl-10 xl:pl-4 font-bold xl:text-5xl lg:text-4xl md:text-3xl text-black">
                  Need<span className="text-green pl-2">Support </span>?
                </h1>
                <p className="head1 text-gray text-2xl pt-2 xl:-ml-2 lg:-ml-6 font-medium">
                  Our Elegant Carve Team Will Help You
                </p>
              </div>
              <div className="xl:pl-44 md:pl-16 lg:pl-12 lg:w-6/12 md:w-6/12">
                <img
                  className=""
                  src={require("assets/img/ECannounce.svg").default}
                  alt="..."
                />
              </div>
            </div>
          </div>
        </section>
      </div>

      <section
        ref={(el) => sections.current.push(el)}
        className="bg-white animate-on-scroll"
      >
        <div className="rounded-xll">
          <div className="container-fluid">
            <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
              <div
                style={{
                  // backgroundImage: `url(${darkbluebackground})`,
                  backgroundColor: "#00244f",
                  width: "100%",
                  backgroundSize: "cover",
                }}
                className=""
              >
                <div className="container mx-auto overflow-hidden px-4 xl:py-20 xl:px-12 lg:py-8 py-6">
                  <h2 className="xl:px-60 text-center font-medium xl:text-4xl lg:text-2xl md:text-2xl text-xl text-white banner-font">
                    Design Professional
                    <span className="text-sky-blue px-2">WooCommerce</span>
                    Websites
                  </h2>

                  <div className="xl:pt-16 pt-8 xl:gap-16 gap-4 lg:gap-8 xl:px-8 lg:px-16 md:px-8 grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 grid-cols-1">
                    <div className="">
                      <button className="blue-gradient px-6 py-1 text-xs text-white banner-font font-semibold">
                        New
                      </button>
                      <p className="xl:pt-8 pt-4 lg:text-2xl text-lg banner-font text-white font-medium text-left">
                        20+ WooCommerce widgets
                      </p>
                      <p className="xl:pt-3 pt-1 text-left text-white xl:text-base1 text-base leading-relaxed headlineheight banner-font">
                        You have every WooCommerce widget at your disposal to
                        fully customize shop pages from head to toe.
                      </p>
                    </div>

                    <div className="md:pt-0 pt-8">
                      <button className="blue-gradient px-6 py-1 text-xs text-white banner-font font-semibold">
                        New
                      </button>
                      <p className="xl:pt-8 pt-4 lg:text-2xl text-lg banner-font text-white font-medium text-left">
                        20+ WooCommerce widgets
                      </p>
                      <p className="xl:pt-3 pt-1 text-left text-white xl:text-base1 text-base leading-relaxed headlineheight banner-font">
                        You have every WooCommerce widget at your disposal to
                        fully customize shop pages from head to toe.
                      </p>
                    </div>

                    <div className="md:pt-0 pt-8">
                      <button className="blue-gradient px-6 py-1 text-xs text-white banner-font font-semibold">
                        New
                      </button>
                      <p className="xl:pt-8 pt-4 lg:text-2xl text-lg banner-font text-white font-medium text-left">
                        20+ WooCommerce widgets
                      </p>
                      <p className="xl:pt-3 pt-1 text-left text-white xl:text-base1 text-base leading-relaxed headlineheight banner-font">
                        You have every WooCommerce widget at your disposal to
                        fully customize shop pages from head to toe.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Announcement Mobile View */}
      <section className="container mx-auto md:hidden block">
        <div className="w-full lg:w-12/12 md:w-12/12">
          <div className="flex flex-wrap">
            <div className="lg:w-8/12 md:w-8/12 pl-10">
              <img className="w-8/12" src={supportt} alt="..." />
              <h1 className="texthome -mt-16 font-bold text-4xl text-black">
                Need<span className="text-green pl-2">Support </span>?
              </h1>
              <p className="head1 text-charcoal400 text-base pt-2 font-semibold">
                Our Elegant Carve Team Will Help You
              </p>
            </div>
            <div className="lg:w-4/12 md:w-4/12 pt-12">
              <img
                className=""
                src={require("assets/img/ECannounce.svg").default}
                alt="..."
              />
            </div>
          </div>
        </div>
      </section>
      <SliderPage />
      <Footer2 />
    </>
  );
}
