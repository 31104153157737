import React, { useState } from "react";
import elegantbgsection from "assets/img/elegantbgsection.jpg";
import IndexNavbar2 from "components/Navbars/IndexNavbar2";
import Footer2 from "components/Footers/Footer2";

export default function Index() {

  return (
    <>
      <IndexNavbar2 fixed />
      <section
        style={{
          // backgroundImage: `url(${elegantbgsection})`,
          backgroundColor: "#f8f9fb",
          width: "100%",
          backgroundSize: "cover",
        }}
      >
        <div className="container xl:pt-40 lg:pt-32 md:pt-28 pt-28 mx-auto overflow-hidden px-4 xl:py-16 lg:py-12 py-6">
         
          <h2 className="xl:px-72 xl:pt-3 pt-3 text-center font-medium xl:text-4xl lg:text-2xl md:text-2xl text-xl lg:text-3xl md:text-2xl text-xl text-black banner-font">
            We would love to partner with you
          </h2>

          <p className="xl:px-84 lg:px-56 md:px-32 px-4 lg:px-48 md:px-32 xl:mt-2 md:mt-2 mt-1 text-center xl:text-lg text-base  text-gray headlineheightt banner-font">
            We offer a wide range of hosting solutions, from web hosting to
            dedicated servers. Everything you need is right here.
          </p>
          <form>
            <div className="xl:pt-20 lg:pt-8 md:pt-8 pt-8 gap-4 xl:gap-4 xl:px-20 grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 grid-cols-1">
              <div className="center-items">
                <img
                  src="https://i.ibb.co/mvKwvwc/groupphoto.png"
                  className="rounded-xll shadow-xl"
                />
              </div>

              <div className="bg-white rounded-xll shadow-xl xl:p-12 lg:p-8 md:p-8 p-8">
                <p className="xl:pt-2 lg:pt-0 xl:text-base1 text-gray lg:text-xl md:text-lg text-lg banner-font font-medium">
                  Send us a message
                </p>
                <p className="xl:pt-6 lg:pt-8 pt-4 xl:text-base1 text-base  text-gray leading-relaxed headlineheight banner-font">
                  Your Name
                </p>
                <input
                  type="text"
                  className="w-full rounded-md mt-2 border-box"
                />
                <p className="xl:pt-6 lg:pt-8 pt-4 xl:text-base1 text-base  text-gray leading-relaxed headlineheight banner-font">
                  Your email
                </p>
                <input
                  type="text"
                  className="w-full rounded-md mt-2 border-box"
                />
                <p className="xl:pt-6 lg:pt-8 pt-4 xl:text-base1 text-base  text-gray leading-relaxed headlineheight banner-font">
                  Your message
                </p>
                <textarea className="w-full rounded-md mt-2 border-box xl:h-52" />
                <button className="xl:mt-4 lg:mt-8 mt-8 bg-button text-white text-sm banner-font xl:px-10 px-6 py-2 font-medium">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>

      {/* Second section */}

      <div className="bg-elegantcarve">
        <section className="container mx-auto overflow-x-auto xl:px-28 px-4 xl:py-16 lg:py-16 md:py-12 py-8">
          <h2 className="xl:px-52 text-center font-medium xl:text-4xl lg:text-2xl md:text-2xl text-xl lg:text-3xl md:text-2xl text-xl text-white banner-font">
            We are global growing company
          </h2>

          <p className="xl:px-64 lg:px-44 md:px-32 xl:mt-3 mt-3 text-center text-white xl:text-base1 text-base banner-font">
            We offer a wide range of hosting solutions, from web hosting to
            dedicated servers. Everything you need is right here.
          </p>

          <div class="slider-container-logo flex w-full lg:w-9/12 md:w-10/12 w-12/12 mx-auto pt-12">
            <div className="slider-item-logo lg:w-2/12 md:w-2/12 w-3/12">
              <img
                class="xl:w-20 lg:w-16 md:w-16 w-12"
                src="https://i.ibb.co/jbKSbJQ/usa-11254995.png"
                alt="top web and mobile app development companies"
              />
            </div>
            <div className="slider-item-logo lg:w-2/12 md:w-2/12 w-3/12">
              <img
                class="xl:w-20 lg:w-16 md:w-16 w-12"
                src="https://i.ibb.co/xL5xL1b/canada-9906451.png"
                alt="best website designers near me"
              />
            </div>
            <div className="slider-item-logo lg:w-2/12 md:w-2/12 w-3/12">
              <img
                className="xl:w-20 lg:w-16 md:w-16 w-12"
                src="https://i.ibb.co/871hQ14/circle-11849301.png"
                alt="software company in Bangalore"
              />
            </div>
            <div className="slider-item-logo lg:w-2/12 md:w-2/12 w-3/12">
              <img
                className="xl:w-20 lg:w-16 md:w-16 w-12"
                src="https://i.ibb.co/z2BfX9y/mayalsia.png"
                alt="best website designers in Bangalore"
              />
            </div>
            <div className="slider-item-logo lg:w-2/12 md:w-2/12 w-3/12">
              <img
                className="xl:w-20 lg:w-16 md:w-16 w-12"
                src="https://i.ibb.co/4Y842DM/australia-323367-removebg-preview.png"
                alt="best app development company in Bangalore"
              />
            </div>
            <div className="slider-item-logo lg:w-2/12 md:w-2/12 w-3/12">
              <img
                className="xl:w-20 lg:w-16 md:w-16 w-12"
                src="https://i.ibb.co/pbrRpqB/flag-10597864.png"
                alt="best ecommerce web design"
              />
            </div>
            <div class="slider-item-logo lg:w-2/12 md:w-2/12 w-3/12">
              <img
                class="xl:w-20 lg:w-16 md:w-16 w-12"
                src="https://i.ibb.co/jbKSbJQ/usa-11254995.png"
                alt="top web and mobile app development companies"
              />
            </div>
            <div class="slider-item-logo lg:w-2/12 md:w-2/12 w-3/12">
              <img
                class="xl:w-20 lg:w-16 md:w-16 w-12"
                src="https://i.ibb.co/xL5xL1b/canada-9906451.png"
                alt="best website designers near me"
              />
            </div>
            <div className="slider-item-logo lg:w-2/12 md:w-2/12 w-3/12">
              <img
                className="xl:w-20 lg:w-16 md:w-16 w-12"
                src="https://i.ibb.co/871hQ14/circle-11849301.png"
                alt="software company in Bangalore"
              />
            </div>
            <div className="slider-item-logo lg:w-2/12 md:w-2/12 w-3/12">
              <img
                className="xl:w-20 lg:w-16 md:w-16 w-12"
                src="https://i.ibb.co/z2BfX9y/mayalsia.png"
                alt="best website designers in Bangalore"
              />
            </div>
            <div className="slider-item-logo lg:w-2/12 md:w-2/12 w-3/12">
              <img
                className="xl:w-20 lg:w-16 md:w-16 w-12"
                src="https://i.ibb.co/4Y842DM/australia-323367-removebg-preview.png"
                alt="best app development company in Bangalore"
              />
            </div>
            <div className="slider-item-logo lg:w-2/12 md:w-2/12 w-3/12">
              <img
                className="xl:w-20 lg:w-16 md:w-16 w-12"
                src="https://i.ibb.co/pbrRpqB/flag-10597864.png"
                alt="best ecommerce web design"
              />
            </div>
          </div>
          {/* <Slider {...settings} className="xl:pt-8 xl:px-8">
            <div className="">
              <div className="grid xl:grid-cols-1 xl:gap-4 md:grid-cols-1 xl:p-2 md:p-6 mb-8 xl:mb-0 p-6 rounded-xl ">
              <div className="center-items"><img
                src="https://i.ibb.co/V349Bt7/chkm.png"
                className="rounded-full xl:w-20"
              /></div>
              </div>
            </div>

            <div className="grid  cursor-pointer xl:grid-cols-1 xl:gap-4 md:grid-cols-1 xl:p-2 md:p-4 mb-8 xl:mb-0 p-4 rounded-xl ">
            <div className="center-items"><img
                src="https://i.ibb.co/V349Bt7/chkm.png"
                className="rounded-full xl:w-20"
              /></div>
            </div>

            <div className="">
              <div className="grid  cursor-pointer xl:grid-cols-1 xl:gap-4 md:grid-cols-1 xl:p-2 md:p-4 mb-8 xl:mb-0 p-4 rounded-xl ">
              <div className="center-items"><img
                src="https://i.ibb.co/V349Bt7/chkm.png"
                className="rounded-full xl:w-20"
              /></div>
              </div>
            </div>

            <div className="grid cursor-pointer xl:grid-cols-1 xl:gap-4 md:grid-cols-1  xl:p-2 md:p-4 mb-8 xl:mb-0 p-4 rounded-xl">
              <div className="center-items"><img
                src="https://i.ibb.co/V349Bt7/chkm.png"
                className="rounded-full xl:w-20"
              /></div>
            </div>
            <div className="">
              <div className="grid  cursor-pointer xl:grid-cols-1 xl:gap-4 md:grid-cols-1 xl:p-2 md:p-4 mb-8 xl:mb-0 p-4 rounded-xl ">
              <div className="center-items"><img
                src="https://i.ibb.co/V349Bt7/chkm.png"
                className="rounded-full xl:w-20"
              /></div>
              </div>
            </div>

            <div className="grid cursor-pointer xl:grid-cols-1 xl:gap-4 md:grid-cols-1  xl:p-2 md:p-4 mb-8 xl:mb-0 p-4 rounded-xl">
              <div className="center-items"><img
                src="https://i.ibb.co/V349Bt7/chkm.png"
                className="rounded-full xl:w-20"
              /></div>
            </div>
            <div className="">
              <div className="grid  cursor-pointer xl:grid-cols-1 xl:gap-4 md:grid-cols-1 xl:p-2 md:p-4 mb-8 xl:mb-0 p-4 rounded-xl ">
              <div className="center-items"><img
                src="https://i.ibb.co/V349Bt7/chkm.png"
                className="rounded-full xl:w-20"
              /></div>
              </div>
            </div>

            <div className="grid cursor-pointer xl:grid-cols-1 xl:gap-4 md:grid-cols-1  xl:p-2 md:p-4 mb-8 xl:mb-0 p-4 rounded-xl">
              <div className="center-items"><img
                src="https://i.ibb.co/V349Bt7/chkm.png"
                className="rounded-full xl:w-20"
              /></div>
            </div>
            <div className="">
              <div className="grid  cursor-pointer xl:grid-cols-1 xl:gap-4 md:grid-cols-1 xl:p-2 md:p-4 mb-8 xl:mb-0 p-4 rounded-xl ">
              <div className="center-items"><img
                src="https://i.ibb.co/V349Bt7/chkm.png"
                className="rounded-full xl:w-20"
              /></div>
              </div>
            </div>

            <div className="grid cursor-pointer xl:grid-cols-1 xl:gap-4 md:grid-cols-1  xl:p-2 md:p-4 mb-8 xl:mb-0 p-4 rounded-xl">
              <div className="center-items"><img
                src="https://i.ibb.co/V349Bt7/chkm.png"
                className="rounded-full xl:w-20"
              /></div>
            </div>
          </Slider> */}
        </section>
      </div>

      {/* Third section */}

      <section className="bg-white">
        <div className="rounded-xll xl:mt-0 md:mt-8 lg:mt-0">
          <div class="container-fluid">
            {/* <h1 class="page-header">Linear Gradients</h1> */}

            <div className="container mx-auto overflow-hidden px-4 xl:py-20 xl:px-12 lg:py-16 py-6">
              <div className="grid xl:grid-cols-2 md:grid-cols-2 grid-cols-1 xl:px-20">
                <div>
                  <h2 className="text-left font-medium xl:text-4xl lg:text-2xl md:text-2xl text-xl lg:text-3xl md:text-2xl text-xl text-black banner-font">
                    How can we help you right now?
                  </h2>
                </div>
                <div>
                  <h2 className="text-left font-medium xl:text-base1 xl:pt-0 md:pt-0 pt-3 text-gray banner-font">
                    We offer a wide range of hosting solutions, from web hosting
                    to dedicated servers. Everything you need is right here. We
                    offer a free domain name for the first year of your web
                    hosting package. The process for launching your website is
                    as easy as clicking a button.
                  </h2>
                </div>
              </div>

              <div className="xl:pt-12 lg:pt-12 md:pt-8 lg:px-20 px-4 pt-6 gap-4 xl:px-20 grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 grid-cols-1">
                <a href="">
                  <div className="bg-white rounded-md shadow-xl center-items xl:p-12 lg:p-8 md:p-6 p-8 border">
                    <img
                      src="https://i.ibb.co/nfvvZfW/headphone.png"
                      className="xl:w-16 lg:w-16 md:w-12 w-16"
                    />
                    <div className="xl:w-24 lg:w-20  border-t-blue xl:pb-6" />
                    <p className="xl:pt-6 pt-4 text-lg text-black banner-font font-medium text-center header-on-hover">
                      Contact support
                    </p>
                    <p className="pt-1 text-center xl:text-base1 text-base  text-gray leading-relaxed headlineheight head1">
                      Contact our support service via Telegram, our team respond
                      in 3 min.
                    </p>
                  </div>
                </a>
                <a href="">
                  <div className="bg-white rounded-md shadow-xl center-items xl:p-12 lg:p-8 md:p-6 p-8 border">
                    <img
                      src="https://i.ibb.co/ctTZgLV/call.png"
                      className="xl:w-16 lg:w-16 md:w-12 w-16"
                    />
                    <div className="xl:w-24 lg:w-20  border-t-orange xl:pb-6" />
                    <p className="xl:pt-6 pt-4 text-lg text-black banner-font font-medium text-center header-on-hover">
                      Phone us
                    </p>
                    <p className="pt-1 text-center xl:text-base1 text-base  text-gray leading-relaxed headlineheight head1">
                      Call our head office 800.275.8777 our team respond in 5
                      min.
                    </p>
                  </div>
                </a>
                <a href="">
                  <div className="bg-white rounded-md shadow-xl center-items xl:p-12 lg:p-8 md:p-6 p-8 border">
                    <img
                      src="https://i.ibb.co/vY02bDV/home.png"
                      className="xl:w-16 lg:w-16 md:w-12 w-16"
                    />
                    <div className="xl:w-24 lg:w-20  border-t-red xl:pb-6" />
                    <p className="xl:pt-6 pt-4 text-lg text-black banner-font font-medium text-center header-on-hover">
                      Head office
                    </p>
                    <p className="pt-1 text-center xl:text-base1 text-base  text-gray leading-relaxed headlineheight head1">
                      202 Helga Springs Rd, Crawford, TN 38554, our team respond
                      in 3 min.
                    </p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <div className="bg-white">
        <section className="container mx-auto overflow-hidden px-4 xl:pb-24 xl:px-12 py-6">
          <div className="xl:py-12 lg:py-8 md:py-8 py-8 blue-gradient rounded-md xl:mx-20 xl:mb-0 lg:mb-8 lg:mx-12">
            <div className="xl:flex lg:flwx md:flex flex-none xl:w-12/12 lg:w-12/12 md:w-12/12">
              <div className="xl:w-9/12 lg:w-9/12 md:w-9/12">
                <p className="text-white xl:text-4xl lg:text-2xl md:text-2xl text-xl text-left md:pl-8 pl-4  xl:pl-16 lg:pl-8 xl:pr-16 md:pr-12 lg:pr-20 pr-4">
                  Want to join our team? We are happy to assist you!
                </p>
                <p className="text-white xl:text-lg lg:text-lg xl:pt-2 pt-2 font-normal text-left md:pl-8 pl-4 pr-4 xl:pl-16 lg:pl-8">
                  Contact our HR team and start your way with us.
                </p>
              </div>

              <div className="xl:w-3/12 lg:w-3/12 md:w-3/12 xl:pt-10 lg:pt-8 md:pt-6 pt-4 xl:pl-0 md:pl-0 pl-4">
                <button className="bg-white banner-font border-blue bg-button xl:px-10 px-6 py-2 text-white border-white">
                  Join Now ➝
                </button>
              </div>
            </div>
          </div>
        </section>
      </div> */}

      {/* Google map */}

      <div className="">
        <div className="xl:mt-0 lg:mt-64 md:mt-76 mt-100">
          <iframe
            className="w-full rounded-md xl:h-400 lg:h-380 md:h-250 h-250"
            style={{ border: "0" }}
            loading="lazy"
            allowfullscreen
            src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d124456.03462673721!2d77.55909574209004!3d12.891570013137425!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x3bae6dd3d7ea9d39%3A0x16179999afcd9256!2s46%2F4%2C%20Hosur%20Rd%2C%20Kudlu%20Gate%2C%20Krishna%20Reddy%20Industrial%20Area%2C%20Hosapalaya%2C%20HSR%20Extension%2C%20Bengaluru%2C%20Karnataka%20560068!3m2!1d12.8915829!2d77.6414976!5e0!3m2!1sen!2sin!4v1714459166379!5m2!1sen!2sin"
          ></iframe>
        </div>
      </div>
      <Footer2 />
    </>
  );
}
