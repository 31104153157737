import React, { useState } from "react";
import EClogo from "assets/img/EClogo.png";
import EClogowhite from "assets/img/EClogowhite.png";
import eclogohead from "assets/img/eclogohead.png";

export default function Footer2() {
  const handleButtonClick = () => {
    window.location.href = "tel:+919036323422";
  };

  const handleButtonClickMail = () => {
    window.location.href = "mailto:info@elegantcarve.com";
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const [isOpenServices, setIsOpenServices] = useState(false);

  const toggleMenuServices = () => {
    setIsOpenServices(!isOpenServices);
  };

  return (
    <>
      <footer className="relative bottom-0">
        <section className="bg-elegantcarve md:pb-12 md:block hidden">
          {/* <div className="bg-light-green xl:h-100px md:h-120"></div>
          <div className="xl:py-8 md:py-8 bg-blue rounded-xl xl:mx-60 md:mx-10 z-50 xl:-mt-20 md:-mt-20">
            <div className="center-items">
              <div>
                <div className="text-white xl:text-4xl lg:text-2xl md:text-2xl text-xl lg:text-3xl md:text-2xl">
                  Contact Our Support
                </div>
                <div className="flex text-left xl:pt-2 pt-1">
                  <div className="flex pt-3">
                    <a
                      href="https://www.facebook.com/elegantcarve2022"
                      target="_blank"
                    >
                      <i className="text-white elegant-text-on-hover fab fa-facebook text-xl leading-lg px-3" />
                    </a>
                    <a
                      href=" https://twitter.com/elegantcarve?lang=en"
                      target="_blank"
                    >
                      <i className="text-white elegant-text-on-hover fab fa-twitter text-xl leading-lg px-3" />
                    </a>
                    <a
                      href=" https://instagram.com/elegantcarvetechnologies?igshid=YmMyMTA2M2Y="
                      target="_blank"
                    >
                      <i className="text-white elegant-text-on-hover fab fa-instagram-square text-xl leading-lg px-3" />
                    </a>
                    <a
                      href=" https://in.linkedin.com/company/elegantcarve"
                      target="_blank"
                    >
                      <i className="text-white elegant-text-on-hover fab fa-linkedin text-xl leading-lg px-3" />
                    </a>
                    <a
                      href=" https://in.pinterest.com/elegantcarve/"
                      target="_blank"
                    >
                      <i className="text-white elegant-text-on-hover fab fa-pinterest text-xl leading-lg px-3" />
                    </a>
                  </div>
                  <div className="flex pl-4">
                    <div className="rounded-full bg-white w-8 p-2 h-8 mt-2">
                      <img src="https://i.ibb.co/JHv30WF/viber-152851.png" />
                    </div>
                    <div className="pl-2 pt-3">
                      <button
                        onClick={handleButtonClick}
                        className="text-white font-semibold text-lg banner-font"
                      >
                        +91 9036323422
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className="container mx-auto pt-12">
            <div className="w-full lg:w-12/12 md:w-12/12">
              <div className="flex flex-wrap pl-10 ">
                <div className="lg:w-3/12 md:w-3/12">
                  <h1 className="head1 text-white text-lg font-bold">
                    Services
                  </h1>
                  <p className="head1 text-sm text-light-blue pt-1">
                    Web Design
                  </p>
                  <p className="head1 text-sm text-light-blue pt-1">
                    Web Development
                  </p>
                  <p className="head1 text-sm text-light-blue pt-1">
                    Marketing
                  </p>
                  <p className="head1 text-sm text-light-blue pt-1">
                    App Development
                  </p>
                  <p className="head1 text-sm text-light-blue pt-1">Branding</p>
                  <p className="head1 text-sm text-light-blue pt-1">
                    E-Commerce
                  </p>

                  <h1 className="head1 text-white text-lg font-bold pt-6">
                    About
                  </h1>
                  <p className="head1 text-sm text-light-blue pt-1">About Us</p>
                  <p className="head1 text-sm text-light-blue pt-1">Team</p>
                  <p className="head1 text-sm text-light-blue pt-1">
                    Portfolio
                  </p>
                  <p className="head1 text-sm text-light-blue pt-1">
                    Testimonials
                  </p>
                </div>
                <div className="lg:w-3/12 md:w-3/12 ">
                  <h1 className="head1 text-white text-lg font-bold">
                    Industries
                  </h1>
                  <p className="head1 text-sm text-light-blue pt-1">
                    Education
                  </p>
                  <p className="head1 text-sm text-light-blue pt-1">
                    Automobile
                  </p>
                  <p className="head1 text-sm text-light-blue pt-1">
                    Recruitment
                  </p>
                  <p className="head1 text-sm text-light-blue pt-1">Oil</p>
                  <p className="head1 text-sm text-light-blue pt-1">
                    E-Commerce
                  </p>
                  <p className="head1 text-sm text-light-blue pt-1">
                    Communications
                  </p>

                  <p className="head1 text-sm text-light-blue pt-12">
                    Accounting
                  </p>
                  <p className="head1 text-sm text-light-blue pt-1">Banking</p>
                  <p className="head1 text-sm text-light-blue pt-1">Medical</p>
                  <p className="head1 text-sm text-light-blue pt-1">
                    Home Care
                  </p>
                  <p className="head1 text-sm text-light-blue pt-1">
                    Consumer Goods
                  </p>
                </div>
                <div className="lg:w-3/12 md:w-3/12 pt-8">
                  <h1 className="head1 text-white text-xl font-bold"></h1>
                  <p className="head1 text-sm text-light-blue">Organization</p>
                  <p className="head1 text-sm text-light-blue pt-1">Health</p>
                  <p className="head1 text-sm text-light-blue pt-1">
                    Insurances
                  </p>
                  <p className="head1 text-sm text-light-blue pt-1">Garments</p>
                  <p className="head1 text-sm text-light-blue pt-1">
                    Agriculture
                  </p>
                  <p className="head1 text-sm text-light-blue pt-1">
                    Transport & Logistics
                  </p>

                  <p className="head1 text-sm text-light-blue pt-12">
                    Gift Shop
                  </p>
                  <p className="head1 text-sm text-light-blue pt-1">Cakes</p>
                  <p className="head1 text-sm text-light-blue pt-1">Painting</p>
                  <p className="head1 text-sm text-light-blue pt-1">
                    Fragrance
                  </p>
                  <p className="head1 text-sm text-light-blue pt-1">
                    Consultancy
                  </p>
                </div>

                <div className="lg:w-3/12 md:w-3/12">
                  <img
                    className="w-52 xl:-ml-3"
                    src={EClogowhite}
                    alt="App Devlopment Company in Bangalore"
                  ></img>
                  <p className="figtree text-sm text-white pt-2">
                    The world’s first and largest digital marketplace for crypto
                    collectibles and non-fungible tokens (NFTs). Buy
                  </p>

                  <div className="w-12/12 flex xl:pt-6 md:pt-4">
                    <div className="w-2/12">
                      <img
                        src="https://i.ibb.co/BPqmYWb/location.png"
                        className="xl:w-6 md:w-8 mt-1"
                      />
                    </div>
                    <div className="w-9/12 ml-2 figtree xl:text-sm text-white">
                      Novel Tech Park, #46/4, Kudlu Gate, Hosur Rd, HSR
                      Extension, Bengaluru, Karnataka 560068.
                    </div>
                  </div>

                  <div className="w-12/12 flex xl:pt-6 md:pt-4">
                    <div className="w-2/12">
                      <img
                        src="https://i.ibb.co/CnkczC3/message.png"
                        className="xl:w-6 md:w-8 mt-1"
                      />
                    </div>
                    <div className="w-9/12 ml-2 figtree xl:text-sm text-white">
                      info@elegantcarve.com<br></br>
                      +91 9036323422
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="xl:block lg:block md:block hidden">
          <section className="bg-elegantcarve">
            <h1 className="px-2 text-center text-white py-4">
              ©2024 <span className="font-bold">Elegant Carve</span>, All Rights
              Reserved
            </h1>
          </section>
        </section>

        {/* Mobile View */}
        <section className="bg-elegantcarve md:hidden block">
          <div className="container w-full mx-auto lg:w-12/12 md:w-12/12">
            <div className="pt-6">
              {/* <div className="flex justify-between">
                <p
                  className="text-gray-light text-base banner-font text-left pl-4 py-2 w-full cursor-pointer"
                  onClick={toggleMenuServices}
                >
                  Services
                </p>
                <p className="font-semibold text-gray-light pr-6 pt-1">⌵</p>
              </div>
              {isOpenServices && (
                <div className="">
                  <a href="">
                    <p className="banner-font text-base text-gray-light text-left pl-8 pt-2">
                      Web Design
                    </p>
                  </a>
                  <a href="">
                    <p className="banner-font text-base text-gray-light text-left pl-8 pt-2">
                      Web Development
                    </p>
                  </a>
                  <a href="">
                    <p className="banner-font text-base text-gray-light text-left pl-8 pt-2">
                      Marketing
                    </p>
                  </a>
                  <a href="">
                    <p className="banner-font text-base text-gray-light text-left pl-8 pt-2">
                      App Development
                    </p>
                  </a>
                  <a href="">
                    <p className="banner-font text-base text-gray-light text-left pl-8 py-2">
                      E-Commerce
                    </p>
                  </a>
                </div>
              )} */}
            </div>
            <div className="bg-elegantcarve pl-4 pr-4 pb-2">
              {/* <div className="flex justify-between">
                <p
                  className="text-gray-light text-base banner-font text-left py-2 w-full cursor-pointer"
                  onClick={toggleMenu}
                >
                  Quick Menu
                </p>
                <p className="font-semibold text-gray-light pr-2 pt-1">⌵</p>
              </div>
              {isOpen && (
                <div className="">
                  <a href="">
                    <p className="banner-font text-base text-gray-light text-left pl-4 pt-2">
                      Portfolio
                    </p>
                  </a>
                  <a href="">
                    <p className="banner-font text-base text-gray-light text-left pl-4 pt-2">
                      Blog
                    </p>
                  </a>
                  <a href="">
                    <p className="banner-font text-base text-gray-light text-left pl-4 py-2">
                      Contact Us
                    </p>
                  </a>
                </div>
              )} */}
              <div className="w-12/12 center-items">
                <img src={EClogowhite} className="w-5/12" />
                <div className="w-9/12 head1 xl:text-sm text-gray-light">
                  Novel Tech Park, #46/4, Kudlu Gate, Hosur Rd, HSR Extension,
                  Bengaluru, Karnataka 560068.
                </div>
                <a href="/contact-us">
                  <p className="font-semibold text-sky-blue head1 text-xs pt-2 uppercase">
                    Contact us ➝{" "}
                  </p>
                </a>
              </div>
              <div className="grid grid-cols-3 pt-6 px-16">
                <a href="" className="center-items">
                  <p className="banner-font center-item text-sm text-gray-light text-left pt-2">
                    Home
                  </p>
                </a>
                <a href="" className="center-items">
                  <p className="banner-font center-item text-sm text-gray-light text-left pt-2">
                    Portfolio
                  </p>
                </a>
                <a href="" className="center-items">
                  <p className="banner-font center-item text-sm text-gray-light text-left pt-2">
                    Blog
                  </p>
                </a>
              </div>
              <div className="grid grid-cols-3 pt-1">
                <a href="" className="center-items">
                  <p className="banner-font center-item text-sm text-gray-light text-left pt-2">
                    Web Design
                  </p>
                </a>
                <a href="" className="center-items">
                  <p className="banner-font center-item text-sm text-gray-light text-left pt-2">
                    Web Development
                  </p>
                </a>
                <a href="" className="center-items">
                  <p className="banner-font center-item text-sm text-gray-light text-left pt-2">
                    Marketing
                  </p>
                </a>
              </div>
              <div className="grid grid-cols-2 px-12 pt-1">
                <a href="" className="center-items">
                  <p className="banner-font center-item text-sm text-gray-light text-left pt-2">
                    App Development
                  </p>
                </a>
                <a href="" className="center-items">
                  <p className="banner-font center-item text-base text-gray-light text-left py-2">
                    E-Commerce
                  </p>
                </a>
              </div>
              <div className="pt-8 center-items">
                <div className="flex">
                  <a
                    href="https://www.facebook.com/elegantcarve2022"
                    target="_blank"
                  >
                    <i className="text-white fab fa-facebook text-xl leading-lg pr-2" />
                  </a>
                  <a
                    href="https://twitter.com/elegantcarve?lang=en"
                    target="_blank"
                  >
                    <i className="text-white fab fa-twitter text-xl leading-lg px-2" />
                  </a>
                  <a
                    href=" https://instagram.com/elegantcarvetechnologies?igshid=YmMyMTA2M2Y="
                    target="_blank"
                  >
                    <i className="text-white fab fa-instagram-square text-xl leading-lg px-2" />
                  </a>
                  <a
                    href="https://in.linkedin.com/company/elegantcarve"
                    target="_blank"
                  >
                    <i className="text-white fab fa-linkedin text-xl leading-lg px-2" />
                  </a>
                  <a
                    href=" https://in.pinterest.com/elegantcarve/"
                    target="_blank"
                  >
                    <i className="text-white fab fa-pinterest text-xl leading-lg px-2" />
                  </a>
                </div>
                <div className="lg:w-1/12 md:w-1/12"></div>
              </div>
              <div className="lg:w-7/12 md:w-7/12">
                <h1 className="head1 text-white text-sm pt-10 text-center">
                  Sitemap
                  <span className="px-4">Terms</span>
                  <span className="px-2">Privacy</span>
                  <span className="px-2">Cookie</span>
                </h1>
              </div>
            </div>
          </div>
        </section>
        <section className="xl:hidden lg:hidden md:hidden block">
          <section className="bg-elegantcarve">
            <h1 className="px-2 text-center text-white py-2">
              ©2024 Elegant Carve, All Rights Reserved
            </h1>
          </section>
        </section>
      </footer>
    </>
  );
}
