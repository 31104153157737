import React, { useState } from "react";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer2 from "components/Footers/Footer2";
import elegantservicebackground from "assets/img/elegantservicebackground.png";
import trawelmart from "assets/img/portfolio/trawelmart.png";
import unicorn from "assets/img/portfolio/unicorn.png";
import dreambig from "assets/img/portfolio/dreambig.png";
import marslab from "assets/img/portfolio/marslab.png";
import ibusiness from "assets/img/portfolio/ibusiness.png";
import pruthvi from "assets/img/portfolio/pruthvi.png";
import palacepainters from "assets/img/portfolio/palacepainters.png";
import bargainmarts from "assets/img/portfolio/bargainmarts.png";
import kiwi from "assets/img/portfolio/kiwi.png";
import people from "assets/img/portfolio/people.png";
import rightdeals from "assets/img/portfolio/rightdeals.png";
import aroma from "assets/img/portfolio/aroma.png";
import tech from "assets/img/portfolio/tech.png";
import iksofttech from "assets/img/portfolio/iksofttech.png";
import agrizy from "assets/img/portfolio/agrizy.png";
import by7 from "assets/img/portfolio/by7.png";
import bglast from "assets/img/bglast.png";
import ourworkbg from "assets/img/ourworkbg.jpg";
import whitebackgroundelegantcarve from "assets/img/whitebackgroundelegantcarve.jpg";
import IndexNavbar2 from "components/Navbars/IndexNavbar2";

export default function Portfolio() {
  const [showBottomSection, setShowBottomSection] = useState(false);

  const toggleBottomSection = () => {
    setShowBottomSection(!showBottomSection);
  };

  return (
    <>
      <IndexNavbar2 fixed />
      <section className="block md:block">
        <div className="header relative  items-center">
          <section
            style={{
              // backgroundImage: `url(${elegantservicebackground})`,
              backgroundColor: "#ffff",
              width: "100%",
              backgroundSize: "cover",
            }}
          >
            <div className="container xl:pt-44 lg:pt-40 md:pt-32 pt-28 mx-auto text-center justify-center flex-wrap ">
              <h1 className="xl:pt-2 font-medium xl:text-4xl lg:text-2xl md:text-2xl text-xl text-black banner-font">
                Select your HomePage
              </h1>
              <p className="xl:px-72 lg:px-52 px-8 md:px-40 mt-3 xl:text-base1 text-base text-gray leading-relaxed headlineheightt banner-font">
                Zento is a super modern blog, highly focused in Speed and
                Flexibility. On every Layout you can add an Intro Text, Popular
                Categories or Post lists as needed.
              </p>
              <div className="xl:block md:block hidden ">
                <div className="grid rounded-md xl:grid-cols-2 lg:grid-cols-2 pt-4 lg:px-12 pb-8  md:pt-8 md:px-4 md:grid-cols-2 md:pb-10 lg:pt-12 xl:pt-16 lg:pb-10 xl:pb-20">
                  <div className="xl:px-12  xl:py-8 grey">
                    <div className="center-items xl:pt-0 lg:pt-8 md:pt-4 pt-4 font-semibold text-gray-dark banner-font xl:text-4xl">
                      <div className="flex">
                        <img
                          src="https://i.ibb.co/Cskc17h/react-removebg-preview.png"
                          className="xl:w-12 lg:w-12 md:w-16 w-8"
                        />
                        <p className="banner-font xl:pt-2 lg:pt-3 md:pt-4 xl:pl-2 font-normal xl:text-xl">
                          React Websites
                        </p>
                      </div>
                    </div>
                    <div className="grid xl:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-4 xl:gap-4 lg:pt-8 md:pt-8 xl:pt-0">
                      <div className="xl:mt-8 lg:mt-8 mt-4">
                        <h1 className="banner-font xl:text-base1  text-gray text-center">
                          Trawel Mart
                        </h1>
                        <div class="xl:window lg:window md:window">
                          <img src={trawelmart} />
                        </div>
                        <div className="center-items">
                          <a href="https://trawelmart.com/" target="_blank">
                            <button className="bg-button text-sm banner-font text-white xl:px-8 xl:mt-8 lg:mt-4 lg:ml-2 py-2  px-6 font-medium px-6 mt-2">
                              View More
                            </button>
                          </a>
                        </div>
                      </div>
                      <div className="xl:mt-8 lg:mt-8">
                        <h1 className="banner-font xl:text-base1  text-gray text-center">
                          Marslab Intelligence
                        </h1>
                        <div class="xl:window lg:window md:window">
                          <img src={marslab} />
                        </div>
                        <div className="center-items">
                          <a href="https://marslab.ai/" target="_blank">
                            <button className="bg-button text-sm banner-font text-white xl:px-8 xl:mt-8 lg:mt-4 lg:ml-2 py-2  px-6 font-medium px-6 mt-2">
                              View More
                            </button>
                          </a>
                        </div>
                      </div>

                      <div className="xl:mt-12 lg:mt-8 md:mt-4">
                        <h1 className="banner-font xl:text-base1  text-gray text-center">
                          Dream Big Celebration
                        </h1>
                        <div class="xl:window lg:window md:window">
                          <img src={dreambig} />
                        </div>
                        <div className="center-items">
                          <a
                            href="https://www.dreambigcelebration.com/"
                            target="_blank"
                          >
                            <button className="bg-button text-sm banner-font text-white xl:px-8 xl:mt-8 lg:mt-4 lg:ml-2 py-2  px-6 font-medium px-6 mt-2">
                              View More
                            </button>
                          </a>
                        </div>
                      </div>

                      <div className="xl:mt-12 lg:mt-8 md:mt-4">
                        <h1 className="banner-font xl:text-base1  text-gray text-center">
                          Agrizy
                        </h1>
                        <div class="xl:window lg:window md:window">
                          <img src={agrizy} />
                        </div>
                        <div className="center-items">
                          <a href="https://agrizy.in/" target="_blank">
                            <button className="bg-button text-sm banner-font text-white xl:px-8 xl:mt-8 lg:mt-4 lg:ml-2 py-2  px-6 font-medium px-6 mt-2">
                              View More
                            </button>
                          </a>
                        </div>
                      </div>

                      <div className="xl:mt-12 lg:mt-8">
                        <h1 className="banner-font xl:text-base1  text-gray text-center">
                          Unicorn Global Solutions
                        </h1>
                        <div class="xl:window lg:window md:window">
                          <img src={unicorn} />
                        </div>
                        <div className="center-items">
                          <a
                            href="https://unicornglobalsolutions.ae/"
                            target="_blank"
                          >
                            <button className="bg-button text-sm banner-font text-white xl:px-8 xl:mt-8 lg:mt-4 lg:ml-2 py-2  px-6 font-medium px-6 mt-2">
                              View More
                            </button>
                          </a>
                        </div>
                      </div>

                      <div className="xl:mt-12 lg:mt-8 md:mt-6">
                        <h1 className="banner-font xl:text-base1  text-gray text-center">
                          iBusiness Consulting
                        </h1>
                        <div class="xl:window lg:window md:window">
                          <img src={ibusiness} />
                        </div>
                        <div className="center-items">
                          <a
                            href="https://ibusinessconsulting.ae/services"
                            target="_blank"
                          >
                            <button className="bg-button text-sm banner-font text-white xl:px-8 xl:mt-8 lg:mt-4 lg:ml-2 py-2  px-6 font-medium px-6 mt-2">
                              View More
                            </button>
                          </a>
                        </div>
                      </div>
                      <div className="xl:mt-12 lg:mt-8 md:mt-8 md:pb-10">
                        <h1 className="banner-font xl:text-base1  text-gray text-center">
                          Pruthvi Projects
                        </h1>
                        <div class="xl:window lg:window md:window">
                          <img src={pruthvi} />
                        </div>
                        <div className="center-items">
                          <a href="http://pruthviprojects.in/" target="_blank">
                            <button className="bg-button text-sm banner-font text-white xl:px-8 xl:mt-8 lg:mt-4 lg:ml-2 py-2  px-6 font-medium px-6 mt-2">
                              View More
                            </button>
                          </a>
                        </div>
                      </div>

                      <div className="xl:mt-12 lg:mt-8 md:mt-8 pb-4">
                        <h1 className="banner-font xl:text-base1  text-gray text-center">
                          24by7 Rescue Link
                        </h1>
                        <div class="xl:window lg:window md:window">
                          <img src={by7} />
                        </div>
                        <div className="center-items">
                          <a
                            href="https://24by7rescuelink.com/"
                            target="_blank"
                          >
                            <button className="bg-button text-sm banner-font text-white xl:px-8 xl:mt-8 lg:mt-4 lg:ml-2 py-2  px-6 font-medium px-6 mt-2">
                              View More
                            </button>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="xl:px-12 xl:py-8 pinku">
                    <div className="center-items xl:pt-0 lg:pt-4 md:pt-4 font-semibold text-gray-dark banner-font">
                      <div className="flex">
                        <img
                          src="https://i.ibb.co/tXLHbFF/ecommerce-removebg-preview.png"
                          className="xl:w-16 lg:w-20 md:w-20 w-16 mt-6 xl:mt-0 lg:mt-4"
                        />
                        <p className="banner-font xl:pt-2 md:pt-4 lg:pt-8 pt-8 xl:pl-2 font-normal xl:text-xl">
                          E-Commerce Websites
                        </p>
                      </div>
                    </div>
                    <div className="grid xl:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-0 xl:gap-4 lg:pt-4 md:pt-8 xl:pt-0">
                      <div className="xl:mt-8 lg:mt-9">
                        <h1 className="banner-font xl:text-base1  text-gray text-center">
                          Bargainmart
                        </h1>
                        <div class="xl:window lg:window md:window">
                          <img src={bargainmarts} />
                        </div>
                        <div className="center-items">
                          <a href="https://bargainmart.co.nz/" target="_blank">
                            <button className="bg-button text-sm banner-font text-white xl:px-8 xl:mt-8 lg:mt-4 lg:ml-2 py-2  px-6 font-medium px-6 mt-2">
                              View More
                            </button>
                          </a>
                        </div>
                      </div>
                      <div className="xl:mt-8 lg:mt-9 mt-4">
                        <h1 className="banner-font xl:text-base1  text-gray text-center">
                          Right Deals
                        </h1>
                        <div class="xl:window lg:window md:window">
                          <img src={rightdeals} />
                        </div>
                        <div className="center-items">
                          <a href="https://rightdeals.co.nz/" target="_blank">
                            <button className="bg-button text-sm banner-font text-white xl:px-8 xl:mt-8 lg:mt-4 lg:ml-2 py-2  px-6 font-medium px-6 mt-2">
                              View More
                            </button>
                          </a>
                        </div>
                      </div>

                      <div className="xl:mt-12 lg:mt-12 md:mt-8 mt-4">
                        <h1 className="banner-font xl:text-base1  text-gray text-center">
                          Kiwi Fix It
                        </h1>
                        <div class="xl:window lg:window md:window">
                          <img src={kiwi} />
                        </div>
                        <div className="center-items">
                          <a href="https://kiwifixit.co.nz/" target="_blank">
                            <button className="bg-button text-sm banner-font text-white xl:px-8 xl:mt-8 lg:mt-4 lg:ml-2 py-2  px-6 font-medium px-6 mt-2">
                              View More
                            </button>
                          </a>
                        </div>
                      </div>
                      <div className="xl:mt-12 lg:mt-12 md:mt-8 mt-4">
                        <h1 className="banner-font xl:text-base1  text-gray text-center">
                          Aroma Infini
                        </h1>
                        <div class="xl:window lg:window md:window">
                          <img src={aroma} />
                        </div>
                        <div className="center-items">
                          <a href="https://aromainfini.com/" target="_blank">
                            <button className="bg-button text-sm banner-font text-white xl:px-8 xl:mt-8 lg:mt-4 lg:ml-2 py-2  px-6 font-medium px-6 mt-2">
                              View More
                            </button>
                          </a>
                        </div>
                      </div>

                      <div className="xl:mt-12 lg:mt-12 md:mt-10 mt-4">
                        <h1 className="banner-font xl:text-base1  text-gray text-center">
                          Techtrinities
                        </h1>
                        <div class="xl:window lg:window md:window">
                          <img src={tech} />
                        </div>
                        <div className="center-items">
                          <a href="https://techtrinities.com/" target="_blank">
                            <button className="bg-button text-sm banner-font text-white xl:px-8 xl:mt-8 lg:mt-4 lg:ml-2 py-2  px-6 font-medium px-6 mt-2">
                              View More
                            </button>
                          </a>
                        </div>
                      </div>
                      <div className="xl:mt-12 lg:mt-12 md:mt-10 mt-4">
                        <h1 className="banner-font xl:text-base1  text-gray text-center">
                          IK Softtech
                        </h1>
                        <div class="xl:window lg:window md:window">
                          <img src={iksofttech} />
                        </div>
                        <div className="center-items">
                          <a href="https://iksoftec.com/" target="_blank">
                            <button className="bg-button text-sm banner-font text-white xl:px-8 xl:mt-8 lg:mt-4 lg:ml-2 py-2  px-6 font-medium px-6 mt-2">
                              View More
                            </button>
                          </a>
                        </div>
                      </div>

                      <div className="xl:mt-12 lg:mt-12 md:mt-12 lg:pb-10 md:pb-10 mt-4">
                        <h1 className="banner-font xl:text-base1  text-gray text-center">
                          People Tree
                        </h1>
                        <div class="xl:window lg:window md:window">
                          <img src={people} />
                        </div>
                        <div className="center-items">
                          <a
                            href="https://peopletreeoutsource.com/"
                            target="_blank"
                          >
                            <button className="bg-button text-sm banner-font text-white xl:px-8 xl:mt-8 lg:mt-4 lg:ml-2 py-2  px-6 font-medium px-6 mt-2">
                              View More
                            </button>
                          </a>
                        </div>
                      </div>
                      <div className="xl:mt-12 lg:mt-12 md:mt-12 lg:pb-10 mt-4 mb-8">
                        <h1 className="banner-font xl:text-base1  text-gray text-center">
                          Palace Painters
                        </h1>
                        <div class="xl:window lg:window md:window">
                          <img src={palacepainters} />
                        </div>
                        <div className="center-items">
                          <a href="https://palacepainters.in/" target="_blank">
                            <button className="bg-button text-sm banner-font text-white xl:px-8 xl:mt-8 lg:mt-4 lg:ml-2 py-2  px-6 font-medium px-6 mt-2">
                              View More
                            </button>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>

      <section
        style={{
          // backgroundImage: `url(${whitebackgroundelegantcarve})`,
          backgroundColor: "#f8f9fb",
          width: "100%",
          backgroundSize: "cover",
        }}
        className="xl:pt-16"
      >
        <div className="center-items">
          <h1 className="text-center font-medium xl:text-4xl lg:text-2xl md:text-2xl text-xl text-black banner-font">
            Our Works
          </h1>
          <p className="xl:pb-4 pt-1 lg:px-52 px-8 md:px-20 xl:px-max text-center xl:text-base1 text-base text-gray headlineheightt banner-font">
            We could help you in different areas like website design, logo
            design and brochure design as your expectations and its an immune
            pleasure to make your work unique and creative
          </p>
        </div>
        <div className="container mx-auto lg:px-4 md:px-4 px-4 xl:pb-4 lg:pb-12 md:pb-12 pb-8">
          <h1 className="font-medium text-blue banner-font xl:text-xl text-left lg:pt-8 pt-4 pb-4 md:pt-8 md:pb-4 lg:pb-4 xl:pl-4 xl:pb-4 xl:pt-4">
            USA & CANADA
          </h1>
          <div className="grid rounded-md xl:gap-2 xl:grid-cols-4 lg:gap-3 lg:grid-cols-4 md:grid-cols-3 md:gap-3 grid-cols-1 gap-4 xl:px-4 xl:pb-8">
            <a href="https://recrutementplus.ca/employer-fr/" target="_blank">
              <div className="flex xl:w-12/12 border rounded-md bg-white xl:p-4 md:p-2 lg:p-3 p-2">
                <div className="xl:w-3/12">
                  <img
                    src="https://i.ibb.co/D97pCQc/redlogo.png"
                    className="lg:w-16 xl:w-20 md:w-16 w-16"
                  />
                </div>
                <div className="xl:w-9/12 xl:ml-4 xl:mt-0 md:pt-2 pt-2">
                  <h3 className="font-semibold text-black banner-font xl:text-base1">
                    Recrutment Plus
                  </h3>
                  <p className="font-medium text-black banner-font xl:text-base1">
                    Learn more ➝
                  </p>
                </div>
              </div>
            </a>

            <a href="https://techtrinities.com/" target="_blank">
              <div className="flex xl:w-12/12 border rounded-md bg-white xl:p-4 md:p-2 lg:p-3 p-2">
                <div className="xl:w-3/12">
                  <img
                    src="https://i.ibb.co/D97pCQc/redlogo.png"
                    className="lg:w-16 xl:w-20 md:w-16 w-16"
                  />
                </div>
                <div className="xl:w-9/12 xl:ml-4 xl:mt-0 md:pt-2 pt-2">
                  <h3 className="font-semibold text-black banner-font xl:text-base1">
                    Techtrinities
                  </h3>
                  <p className="font-medium text-black banner-font xl:text-base1">
                    Learn more ➝
                  </p>
                </div>
              </div>
            </a>

            <a href="https://www.gifrinc.org/" target="_blank">
              <div className="flex xl:w-12/12 border rounded-md bg-white xl:p-4 md:p-2 lg:p-3 p-2">
                <div className="xl:w-3/12">
                  <img
                    src="https://i.ibb.co/D97pCQc/redlogo.png"
                    className="lg:w-16 xl:w-20 md:w-16 w-16"
                  />
                </div>
                <div className="xl:w-9/12 xl:ml-4 xl:mt-0 md:pt-2 pt-2">
                  <h3 className="font-semibold text-black banner-font xl:text-base1">
                    Gifrinc
                  </h3>
                  <p className="font-medium text-black banner-font xl:text-base1">
                    Learn more ➝
                  </p>
                </div>
              </div>
            </a>

            <a href="https://therevivehealth.ca/" target="_blank">
              <div className="flex xl:w-12/12 border rounded-md bg-white xl:p-4 md:p-2 lg:p-3 p-2">
                <div className="xl:w-3/12">
                  <img
                    src="https://i.ibb.co/D97pCQc/redlogo.png"
                    className="lg:w-16 xl:w-20 md:w-16 w-16"
                  />
                </div>
                <div className="xl:w-9/12 xl:ml-4 xl:mt-0 md:pt-2 pt-2">
                  <h3 className="font-semibold text-black banner-font xl:text-base1">
                    Revive Health
                  </h3>
                  <p className="font-medium text-black banner-font xl:text-base1">
                    Learn more ➝
                  </p>
                </div>
              </div>
            </a>

            <a href="https://incomeforfamily.com/" target="_blank">
              <div className="flex xl:w-12/12 border rounded-md bg-white xl:p-4 md:p-2 lg:p-3 p-2">
                <div className="xl:w-3/12">
                  <img
                    src="https://i.ibb.co/D97pCQc/redlogo.png"
                    className="lg:w-16 xl:w-20 md:w-16 w-16"
                  />
                </div>
                <div className="xl:w-9/12 xl:ml-4 xl:mt-0 md:pt-2 pt-2">
                  <h3 className="font-semibold text-black banner-font xl:text-base1">
                    Income For Family
                  </h3>
                  <p className="font-medium text-black banner-font xl:text-base1">
                    Learn more ➝
                  </p>
                </div>
              </div>
            </a>

            <a href="https://vcarephysiorehab.ca/" target="_blank">
              <div className="flex xl:w-12/12 border rounded-md bg-white xl:p-4 md:p-2 lg:p-3 p-2">
                <div className="xl:w-3/12">
                  <img
                    src="https://i.ibb.co/D97pCQc/redlogo.png"
                    className="lg:w-16 xl:w-20 md:w-16 w-16"
                  />
                </div>
                <div className="xl:w-9/12 xl:ml-4 xl:mt-0 md:pt-2 pt-2">
                  <h3 className="font-semibold text-black banner-font xl:text-base1">
                    Vcarephysio Rehab
                  </h3>
                  <p className="font-medium text-black banner-font xl:text-base1">
                    Learn more ➝
                  </p>
                </div>
              </div>
            </a>

            <a href="https://ibusinessconsulting.ca/" target="_blank">
              <div className="flex xl:w-12/12 border rounded-md bg-white xl:p-4 md:p-2 p-2">
                <div className="xl:w-3/12">
                  <img
                    src="https://i.ibb.co/D97pCQc/redlogo.png"
                    className="lg:w-16 xl:w-20 md:w-16 w-16"
                  />
                </div>
                <div className="xl:w-9/12 xl:ml-4 xl:mt-0 md:pt-2 pt-2">
                  <h3 className="font-semibold text-black banner-font xl:text-base1">
                    iBusiness Consulting
                  </h3>
                  <p className="font-medium text-black banner-font xl:text-base1">
                    Learn more ➝
                  </p>
                </div>
              </div>
            </a>
          </div>

          <h1 className="font-medium text-blue banner-font xl:text-xl text-left xl:pt-4 lg:pt-8 lg:pb-4 xl:pl-4 pt-4 pb-4 md:pt-8 md:pb-4 xl:pb-4">
            UAE & MALASIYA
          </h1>
          <div className="grid rounded-md xl:gap-2 xl:grid-cols-4 lg:grid-cols-4 lg:gap-3 md:grid-cols-3 md:gap-3 grid-cols-1 gap-4 xl:px-4 xl:pb-8">
            <a href="https://24by7rescuelink.com/" target="_blank">
              <div className="flex xl:w-12/12 border rounded-md bg-white xl:p-4 md:p-2 lg:p-3 p-2">
                <div className="xl:w-3/12">
                  <img
                    src="https://i.ibb.co/D97pCQc/redlogo.png"
                    className="lg:w-16 xl:w-20 md:w-16 w-16"
                  />
                </div>
                <div className="xl:w-9/12 xl:ml-4 xl:mt-0 md:pt-2 pt-2">
                  <h3 className="font-semibold text-black banner-font xl:text-base1">
                    24By7Rescue
                  </h3>
                  <p className="font-medium text-black banner-font xl:text-base1">
                    Learn more ➝
                  </p>
                </div>
              </div>
            </a>

            <a href="about:blank" target="_blank">
              <div className="flex xl:w-12/12 border rounded-md bg-white xl:p-4 md:p-2 lg:p-3 p-2">
                <div className="xl:w-3/12">
                  <img
                    src="https://i.ibb.co/D97pCQc/redlogo.png"
                    className="lg:w-16 xl:w-20 md:w-16 w-16"
                  />
                </div>
                <div className="xl:w-9/12 xl:ml-4 xl:mt-0 md:pt-2 pt-2">
                  <h3 className="font-semibold text-black banner-font xl:text-base1">
                    Aroma
                  </h3>
                  <p className="font-medium text-black banner-font xl:text-base1">
                    Learn more ➝
                  </p>
                </div>
              </div>
            </a>

            {/* <a href="https://www.clouddimensionstechnology.com/">
                    <div className="flex xl:w-12/12 border rounded-md bg-white xl:p-4 md:p-2 lg:p-3 p-2">
                      <div className="xl:w-3/12">
                        <img src="https://i.ibb.co/D97pCQc/redlogo.png" className="lg:w-16 xl:w-20 md:w-16 w-16"/>
                      </div>
                      <div className="xl:w-9/12 xl:ml-4 xl:mt-0 md:pt-2 pt-2">
                        <h3 className="font-semibold text-black banner-font xl:text-base1">
                          Rjs Quality Cleaning Services
                        </h3>
                        <p className="font-medium text-black banner-font xl:text-base1">
                          Learn more ➝
                        </p>
                      </div>
                    </div>
                  </a>

                  <a href="https://www.clouddimensionstechnology.com/">
                    <div className="flex xl:w-12/12 border rounded-md bg-white xl:p-4 md:p-2 lg:p-3 p-2">
                      <div className="xl:w-3/12">
                        <img src="https://i.ibb.co/D97pCQc/redlogo.png" className="lg:w-16 xl:w-20 md:w-16 w-16"/>
                      </div>
                      <div className="xl:w-9/12 xl:ml-4 xl:mt-0 md:pt-2 pt-2">
                        <h3 className="font-semibold text-black banner-font xl:text-base1">
                          Cloud Dimension Technology
                        </h3>
                        <p className="font-medium text-black banner-font xl:text-base1">
                          Learn more ➝
                        </p>
                      </div>
                    </div>
                  </a> */}
          </div>

          <h1 className="font-medium text-blue banner-font xl:text-xl text-left xl:pt-4 lg:pt-8 lg:pb-4 xl:pl-4 xl:pb-4 pt-4 pb-4 md:pt-8 md:pb-4 ">
          NEWZEALAND
          </h1>
          <div className="grid rounded-md xl:gap-2 xl:grid-cols-4 lg:grid-cols-4 lg:gap-3 md:grid-cols-3 md:gap-3 grid-cols-1 gap-4 xl:px-4 xl:pb-8">
            <a href="" target="_blank">
              <div className="flex xl:w-12/12 border rounded-md bg-white xl:p-4 md:p-2 lg:p-3 p-2">
                <div className="xl:w-3/12">
                  <img
                    src="https://i.ibb.co/D97pCQc/redlogo.png"
                    className="lg:w-16 xl:w-20 md:w-16 w-16"
                  />
                </div>
                <div className="xl:w-9/12 xl:ml-4 xl:mt-0 md:pt-2 pt-2">
                  <h3 className="font-semibold text-black banner-font xl:text-base1">
                    Arasan Group
                  </h3>
                  <p className="font-medium text-black banner-font xl:text-base1">
                    Learn more ➝
                  </p>
                </div>
              </div>
            </a>

            <a href="" target="_blank">
              <div className="flex xl:w-12/12 border rounded-md bg-white xl:p-4 md:p-2 lg:p-3 p-2">
                <div className="xl:w-3/12">
                  <img
                    src="https://i.ibb.co/D97pCQc/redlogo.png"
                    className="lg:w-16 xl:w-20 md:w-16 w-16"
                  />
                </div>
                <div className="xl:w-9/12 xl:ml-4 xl:mt-0 md:pt-2 pt-2">
                  <h3 className="font-semibold text-black banner-font xl:text-base1">
                    Arasan Radio
                  </h3>
                  <p className="font-medium text-black banner-font xl:text-base1">
                    Learn more ➝
                  </p>
                </div>
              </div>
            </a>

            <a href="" target="_blank">
              <div className="flex xl:w-12/12 border rounded-md bg-white xl:p-4 md:p-2 lg:p-3 p-2">
                <div className="xl:w-3/12">
                  <img
                    src="https://i.ibb.co/D97pCQc/redlogo.png"
                    className="lg:w-16 xl:w-20 md:w-16 w-16"
                  />
                </div>
                <div className="xl:w-9/12 xl:ml-4 xl:mt-0 md:pt-2 pt-2">
                  <h3 className="font-semibold text-black banner-font xl:text-base1">
                    Right Deals
                  </h3>
                  <p className="font-medium text-black banner-font xl:text-base1">
                    Learn more ➝
                  </p>
                </div>
              </div>
            </a>

            <a href="" target="_blank">
              <div className="flex xl:w-12/12 border rounded-md bg-white xl:p-4 md:p-2 lg:p-3 p-2">
                <div className="xl:w-3/12">
                  <img
                    src="https://i.ibb.co/D97pCQc/redlogo.png"
                    className="lg:w-16 xl:w-20 md:w-16 w-16"
                  />
                </div>
                <div className="xl:w-9/12 xl:ml-4 xl:mt-0 md:pt-2 pt-2">
                  <h3 className="font-semibold text-black banner-font xl:text-base1">
                    Bargain Mart
                  </h3>
                  <p className="font-medium text-black banner-font xl:text-base1">
                    Learn more ➝
                  </p>
                </div>
              </div>
            </a>

            <a href="" target="_blank">
              <div className="flex xl:w-12/12 border rounded-md bg-white xl:p-4 md:p-2 lg:p-3 p-2">
                <div className="xl:w-3/12">
                  <img
                    src="https://i.ibb.co/D97pCQc/redlogo.png"
                    className="lg:w-16 xl:w-20 md:w-16 w-16"
                  />
                </div>
                <div className="xl:w-9/12 xl:ml-4 xl:mt-0 md:pt-2 pt-2">
                  <h3 className="font-semibold text-black banner-font xl:text-base1">
                    KiwiFixit
                  </h3>
                  <p className="font-medium text-black banner-font xl:text-base1">
                    Learn more ➝
                  </p>
                </div>
              </div>
            </a>
          </div>

          <h1 className="font-medium text-blue banner-font xl:text-xl text-left xl:pt-4  lg:pt-8 lg:pb-4 xl:pl-4 xl:pb-4 pt-4 pb-4 md:pt-8 md:pb-4">
            INDIA
          </h1>
          <div className="grid rounded-md xl:gap-2 xl:grid-cols-4 lg:grid-cols-4 lg:gap-3 md:grid-cols-3 md:gap-3 grid-cols-1 gap-4 xl:px-4 xl:pb-8">
            <a href="" target="_blank">
              <div className="flex xl:w-12/12 border rounded-md bg-white xl:p-4 md:p-2 lg:p-3 p-2">
                <div className="xl:w-3/12">
                  <img
                    src="https://i.ibb.co/D97pCQc/redlogo.png"
                    className="lg:w-16 xl:w-20 md:w-16 w-16"
                  />
                </div>
                <div className="xl:w-9/12 xl:ml-4 xl:mt-0 md:pt-2 pt-2">
                  <h3 className="font-semibold text-black banner-font xl:text-base1">
                    IK Softech
                  </h3>
                  <p className="font-medium text-black banner-font xl:text-base1">
                    Learn more ➝
                  </p>
                </div>
              </div>
            </a>

            <a href="" target="_blank">
              <div className="flex xl:w-12/12 border rounded-md bg-white xl:p-4 md:p-2 lg:p-3 p-2">
                <div className="xl:w-3/12">
                  <img
                    src="https://i.ibb.co/D97pCQc/redlogo.png"
                    className="lg:w-16 xl:w-20 md:w-16 w-16"
                  />
                </div>
                <div className="xl:w-9/12 xl:ml-4 xl:mt-0 md:pt-2 pt-2">
                  <h3 className="font-semibold text-black banner-font xl:text-base1">
                    Millennialz
                  </h3>
                  <p className="font-medium text-black banner-font xl:text-base1">
                    Learn more ➝
                  </p>
                </div>
              </div>
            </a>

            <a href="" target="_blank">
              <div className="flex xl:w-12/12 border rounded-md bg-white xl:p-4 md:p-2 lg:p-3 p-2">
                <div className="xl:w-3/12">
                  <img
                    src="https://i.ibb.co/D97pCQc/redlogo.png"
                    className="lg:w-16 xl:w-20 md:w-16 w-16"
                  />
                </div>
                <div className="xl:w-9/12 xl:ml-4 xl:mt-0 md:pt-2 pt-2">
                  <h3 className="font-semibold text-black banner-font xl:text-base1">
                    Howexim
                  </h3>
                  <p className="font-medium text-black banner-font xl:text-base1">
                    Learn more ➝
                  </p>
                </div>
              </div>
            </a>

            <a href="" target="_blank">
              <div className="flex xl:w-12/12 border rounded-md bg-white xl:p-4 md:p-2 lg:p-3 p-2">
                <div className="xl:w-3/12">
                  <img
                    src="https://i.ibb.co/D97pCQc/redlogo.png"
                    className="lg:w-16 xl:w-20 md:w-16 w-16"
                  />
                </div>
                <div className="xl:w-9/12 xl:ml-4 xl:mt-0 md:pt-2 pt-2">
                  <h3 className="font-semibold text-black banner-font xl:text-base1">
                    Motherland
                  </h3>
                  <p className="font-medium text-black banner-font xl:text-base1">
                    Learn more ➝
                  </p>
                </div>
              </div>
            </a>

            <a href="" target="_blank">
              <div className="flex xl:w-12/12 border rounded-md bg-white xl:p-4 md:p-2 lg:p-3 p-2">
                <div className="xl:w-3/12">
                  <img
                    src="https://i.ibb.co/D97pCQc/redlogo.png"
                    className="lg:w-16 xl:w-20 md:w-16 w-16"
                  />
                </div>
                <div className="xl:w-9/12 xl:ml-4 xl:mt-0 md:pt-2 pt-2">
                  <h3 className="font-semibold text-black banner-font xl:text-base1">
                    Agrizy
                  </h3>
                  <p className="font-medium text-black banner-font xl:text-base1">
                    Learn more ➝
                  </p>
                </div>
              </div>
            </a>

            <a href="" target="_blank">
              <div className="flex xl:w-12/12 border rounded-md bg-white xl:p-4 md:p-2 lg:p-3 p-2">
                <div className="xl:w-3/12">
                  <img
                    src="https://i.ibb.co/D97pCQc/redlogo.png"
                    className="lg:w-16 xl:w-20 md:w-16 w-16"
                  />
                </div>
                <div className="xl:w-9/12 xl:ml-4 xl:mt-0 md:pt-2 pt-2">
                  <h3 className="font-semibold text-black banner-font xl:text-base1">
                    Cj Info solution
                  </h3>
                  <p className="font-medium text-black banner-font xl:text-base1">
                    Learn more ➝
                  </p>
                </div>
              </div>
            </a>

            <a href="" target="_blank">
              <div className="flex xl:w-12/12 border rounded-md bg-white xl:p-4 md:p-2 lg:p-3 p-2">
                <div className="xl:w-3/12">
                  <img
                    src="https://i.ibb.co/D97pCQc/redlogo.png"
                    className="lg:w-16 xl:w-20 md:w-16 w-16"
                  />
                </div>
                <div className="xl:w-9/12 xl:ml-4 xl:mt-0 md:pt-2 pt-2">
                  <h3 className="font-semibold text-black banner-font xl:text-base1">
                    Palace Painters
                  </h3>
                  <p className="font-medium text-black banner-font xl:text-base1">
                    Learn more ➝
                  </p>
                </div>
              </div>
            </a>

            <a href="" target="_blank">
              <div className="flex xl:w-12/12 border rounded-md bg-white xl:p-4 md:p-2 lg:p-3 p-2">
                <div className="xl:w-3/12">
                  <img
                    src="https://i.ibb.co/D97pCQc/redlogo.png"
                    className="lg:w-16 xl:w-20 md:w-16 w-16"
                  />
                </div>
                <div className="xl:w-9/12 xl:ml-4 xl:mt-0 md:pt-2 pt-2">
                  <h3 className="font-semibold text-black banner-font xl:text-base1">
                    Genup Infotech
                  </h3>
                  <p className="font-medium text-black banner-font xl:text-base1">
                    Learn more ➝
                  </p>
                </div>
              </div>
            </a>

            <a href="" target="_blank">
              <div className="flex xl:w-12/12 border rounded-md bg-white xl:p-4 md:p-2 lg:p-3 p-2">
                <div className="xl:w-3/12">
                  <img
                    src="https://i.ibb.co/D97pCQc/redlogo.png"
                    className="lg:w-16 xl:w-20 md:w-16 w-16"
                  />
                </div>
                <div className="xl:w-9/12 xl:ml-4 xl:mt-0 md:pt-2 pt-2">
                  <h3 className="font-semibold text-black banner-font xl:text-base1">
                    iNidhi Group
                  </h3>
                  <p className="font-medium text-black banner-font xl:text-base1">
                    Learn more ➝
                  </p>
                </div>
              </div>
            </a>

            <a href="" target="_blank">
              <div className="flex xl:w-12/12 border rounded-md bg-white xl:p-4 md:p-2 lg:p-3 p-2">
                <div className="xl:w-3/12">
                  <img
                    src="https://i.ibb.co/D97pCQc/redlogo.png"
                    className="lg:w-16 xl:w-20 md:w-16 w-16"
                  />
                </div>
                <div className="xl:w-9/12 xl:ml-4 xl:mt-0 md:pt-2 pt-2">
                  <h3 className="font-semibold text-black banner-font xl:text-base1">
                    Volkskatt
                  </h3>
                  <p className="font-medium text-black banner-font xl:text-base1">
                    Learn more ➝
                  </p>
                </div>
              </div>
            </a>

            <a href="" target="_blank">
              <div className="flex xl:w-12/12 border rounded-md bg-white xl:p-4 md:p-2 lg:p-3 p-2">
                <div className="xl:w-3/12">
                  <img
                    src="https://i.ibb.co/D97pCQc/redlogo.png"
                    className="lg:w-16 xl:w-20 md:w-16 w-16"
                  />
                </div>
                <div className="xl:w-9/12 xl:ml-4 xl:mt-0 md:pt-2 pt-2">
                  <h3 className="font-semibold text-black banner-font xl:text-base1">
                    iSleep Life
                  </h3>
                  <p className="font-medium text-black banner-font xl:text-base1">
                    Learn more ➝
                  </p>
                </div>
              </div>
            </a>

            <a href="" target="_blank">
              <div className="flex xl:w-12/12 border rounded-md bg-white xl:p-4 md:p-2 lg:p-3 p-2">
                <div className="xl:w-3/12">
                  <img
                    src="https://i.ibb.co/D97pCQc/redlogo.png"
                    className="lg:w-16 xl:w-20 md:w-16 w-16"
                  />
                </div>
                <div className="xl:w-9/12 xl:ml-4 xl:mt-0 md:pt-2 pt-2">
                  <h3 className="font-semibold text-black banner-font xl:text-base1">
                    Pdpandye Rajpati Mahavidyalaya
                  </h3>
                  <p className="font-medium text-black banner-font xl:text-base1">
                    Learn more ➝
                  </p>
                </div>
              </div>
            </a>
          </div>
        </div>
      </section>

      <section
        style={{
          // backgroundImage: `url(${bglast})`,
          backgroundColor: "#ffff",
          width: "100%",
          backgroundSize: "cover",
        }}
      >
        <div className="container mx-auto justify-center xl:pt-16 lg:pt-8 xl:pt-0 pt-8 md:pt-10 flex flex-wrap ">
          <div className="">
            <h1 className="text-center font-medium xl:text-4xl lg:text-2xl md:text-2xl text-xl lg:text-3xl md:text-2xl text-2xl text-black  banner-font">
              We Worked For
            </h1>
            <p className="xl:px-84 lg:px-56 md:px-32 px-4 lg:px-68 xl:mt-2 px-8 text-center xl:text-base1 text-base  text-gray leading-relaxed headlineheight banner-font">
              "We Worked For: Empowering Success Stories, One Collaboration at a
              Time."
            </p>
            <div className="grid xl:gap-1 lg:gap-4 pt-4 grid-cols-6 xl:pt-4 md:pt-4 xl:pb-20 xl:px-52 lg:px-20 lg:pt-10">
              <div className="center-items">
                <img
                  src="https://i.ibb.co/pbrRpqB/flag-10597864.png"
                  className="xl:p-4 xl:w-24 lg:w-20 md:w-12 w-8"
                />
                <p className="text-center text-blue banner-font xl:pt-0 lg:pt-2 md:pt-2 xl:text-base1 md:text-base text-xs">
                  INDIA
                </p>
              </div>
              <div className="center-items">
                <img
                  src="https://i.ibb.co/jbKSbJQ/usa-11254995.png"
                  className="xl:p-4 xl:w-24 lg:w-20 md:w-12 w-8"
                />
                <p className="text-center text-blue banner-font xl:pt-0 lg:pt-2 md:pt-2 xl:text-base1 md:text-base text-xs">
                  USA
                </p>
              </div>
              <div className="center-items">
                <img
                  src="https://i.ibb.co/xL5xL1b/canada-9906451.png"
                  className="xl:p-4 xl:w-24 lg:w-20 md:w-12 w-8"
                />
                <p className="text-center text-blue banner-font xl:pt-0 lg:pt-2 md:pt-2 xl:text-base1 md:text-base text-xs">
                  CANADA
                </p>
              </div>
              <div className="center-items">
                <img
                  src="https://i.ibb.co/871hQ14/circle-11849301.png"
                  className="xl:p-4 xl:w-24 lg:w-20 md:w-12 w-8"
                />
                <p className="text-center text-blue banner-font xl:pt-0 lg:pt-2 md:pt-2 xl:text-base1 md:text-base text-xs">
                  UAE
                </p>
              </div>
              <div className="center-items">
                <img
                  src="https://i.ibb.co/z2BfX9y/mayalsia.png"
                  className="xl:p-4 xl:w-24 lg:w-20 md:w-12 w-8"
                />
                <p className="text-center text-blue banner-font xl:pt-0 lg:pt-2 md:pt-2 xl:text-base1 md:text-base text-xs">
                  MALASIYA
                </p>
              </div>
              <div className="center-items">
                <img
                  src="https://i.ibb.co/4Y842DM/australia-323367-removebg-preview.png"
                  className="xl:p-4 xl:w-24 lg:w-20 md:w-12 w-8"
                />
                <p className="text-center text-blue banner-font xl:pt-0 lg:pt-2 md:pt-2 xl:text-base1 md:text-base text-xs">
                  NewZealand
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="container xl:pt-0 lg:pt-8 pt-8 mx-auto justify-center flex flex-wrap ">
          <div className="">
            <h1 className="text-center font-medium text-black banner-font xl:text-4xl lg:text-2xl md:text-2xl text-xl">
              Interested?
            </h1>
            <p className="xl:px-84 lg:px-56 md:px-32 px-4 lg:px-68 md:px-52 px-8 xl:mt-2 text-center xl:text-base1 text-base  text-gray leading-relaxed headlineheight banner-font">
              Zento includes a fully documentation and 5 stars support within a
              ticket system.
            </p>
            <div className="grid grid-cols-2 xl:px-maxx px-12 mt-4 mb-8 md:px-52 md:pt-2 md:pb-10 lg:px-72 lg:pt-4 lg:pb-20 xl:pt-3 xl:pb-20 gap-4">
              <button className="banner-font text-white px-6 py-2 text-sm font-medium bg-button">
                Call Us
              </button>
              <button className="banner-font text-white px-6 py-2 text-sm font-medium bg-button">
                Enquiry Us
              </button>
            </div>
          </div>
        </div>
      </section>
      <Footer2 />
    </>
  );
}
