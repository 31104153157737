import React from "react";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Homepage1 from "components/Homepage/Homepage1";

export default function Index() {
  return (
    <section className="relative overflow-hidden">
      <IndexNavbar fixed />
      <Homepage1/>
    </section>
  );
}
