import React, { useState } from "react";
import IndexNavbar2 from "components/Navbars/IndexNavbar2";
import Footer2 from "components/Footers/Footer2";
import tag from "assets/img/tag.png";

export default function BestPlans() {
  const [showParagraph1, setShowParagraph1] = useState(false);
  const [showParagraph2, setShowParagraph2] = useState(false);
  const [showParagraph3, setShowParagraph3] = useState(false);
  const [showParagraph4, setShowParagraph4] = useState(false);
  const [showParagraph5, setShowParagraph5] = useState(false);
  const [showParagraph6, setShowParagraph6] = useState(false);

  const toggleParagraph1 = () => {
    setShowParagraph1(!showParagraph1);
  };

  const toggleParagraph2 = () => {
    setShowParagraph2(!showParagraph2);
  };

  const toggleParagraph3 = () => {
    setShowParagraph3(!showParagraph3);
  };

  const toggleParagraph4 = () => {
    setShowParagraph4(!showParagraph4);
  };

  const toggleParagraph5 = () => {
    setShowParagraph5(!showParagraph5);
  };

  const toggleParagraph6 = () => {
    setShowParagraph6(!showParagraph6);
  };
  return (
    <>
      <IndexNavbar2 />
      <div className="relative bottom-0">
        <section className="bg-white md:pb-12 md:block hidden">
          <div className="bg-grey xl:h-500px md:h-120">
            <h2 className="xl:px-52 xl:pt-44 pt-3 text-center font-medium xl:text-4xl lg:text-2xl md:text-2xl text-xl lg:text-3xl md:text-2xl text-xl text-black banner-font">
              Simple, affordable plans to boost your business.
            </h2>
            <p className="xl:px-84 lg:px-56 md:px-32 px-4 lg:px-48 md:px-32 xl:mt-3 md:mt-2 mt-1 text-center xl:text-lg text-base  text-gray headlineheightt banner-font">
              Get a free 30-day trial. No credit card required!
            </p>
            <div className="xl:px-52 xl:pt-12 flex justify-between">
              <div></div>
              <div className="flex">
                <p className="text-blue  pt-1 xl:text-base1 font-semibold pr-2">
                  Monthly
                </p>
                <input type="checkbox" id="switch" />
                <label for="switch">Toggle</label>
                <p className="text-gray pl-2 pt-1 xl:text-base1 font-semibold pr-2">
                  Yearly
                </p>
                <div
                  style={{
                    backgroundImage: `url(${tag})`,
                    backgroundSize: "cover",
                  }}
                  className="xl:w-32 xl:h-12 xl:-mt-2"
                >
                  <p className="lomi text-black text-xs items-center banner-font font-semibold">
                    Save up to 20%
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="xl:py-8 md:py-8 rounded-xl xl:mx-60 md:mx-10 z-50 xl:-mt-20 md:-mt-20">
            <div className="center-items">
              <div className="xl:px-8 grid xl:grid-cols-3 md:grid-cols-3 grid-cols-1 xl:-mt-16">
                <div className="bg-white border xl:mt-8 shadow-xl xl:w-20rem">
                  <div className="xl:p-12 lg:p-8 md:p-8 p-8">
                    <p className="xl:pt-2 lg:pt-0 xl:text-sm text-blue lg:text-xl md:text-lg text-lg banner-font font-medium">
                      ESSENTIALS
                    </p>
                    <h1 className="xl:pt-8 font-semibold text-black banner-font xl:text-5xl">
                      ₹1,380
                    </h1>
                    <p className="xl:pt-3 lg:pt-8 pt-4 xl:text-base1 text-base  text-gray leading-relaxed headlineheight banner-font">
                      /month billed annually
                    </p>

                    <button className="bg-green px-10 py-2 banner-font text-center text-white mt-4">
                      Sign up
                    </button>
                  </div>
                  <div className="xl:pl-6 xl:pb-8 bggg pt-6 border-t xl:w-12/12">
                    <ul>
                      <li className="flex">
                        <span className="mr-2 text-green">✔</span>
                        Blocks/Sections
                      </li>
                      <li className="flex mt-2">
                        <span className="mr-2 text-greenn">✔</span>
                        Multi-Page device preview
                      </li>
                      <li className="flex mt-2">
                        <span className="mr-2 text-greenn">✔</span>
                        Mobile responsive pages
                      </li>
                      <li className="flex mt-2">
                        <span className="mr-2 text-greenn">✔</span>
                        On-scroll/On-load animation
                      </li>
                      <li className="flex mt-2">
                        <span className="mr-2 text-greenn">✔</span>
                        Social share button
                      </li>
                      <li className="flex mt-2">
                        <span className="mr-2 text-greenn">✔</span>
                        Free stack image gallery
                      </li>
                      <li className="flex mt-2">
                        <span className="mr-2 text-greenn">✔</span>
                        Socila share preview
                      </li>
                      <li className="flex mt-2">
                        <span className="mr-2 text-greenn">✔</span>
                        Custom fevicon
                      </li>
                      <li className="flex mt-2">
                        <span className="mr-2 text-greenn">✔</span>
                        SSL Hosting
                      </li>
                      <li className="flex mt-2">
                        <span className="mr-2 text-greenn">✔</span>
                        Direct Integration
                      </li>
                    </ul>
                  </div>
                  <div className="xl:pl-6 xl:pb-8 bg-light-green pt-6 border-t xl:w-12/12">
                    <ul>
                      <li className="flex">
                        <span className="mr-2 text-green">✔</span>
                        Blocks/Sections
                      </li>
                      <li className="flex mt-2">
                        <span className="mr-2 text-greenn">✔</span>
                        Multi-Page device preview
                      </li>
                      <li className="flex mt-2">
                        <span className="mr-2 text-greenn">✔</span>
                        Mobile responsive pages
                      </li>
                      <li className="flex mt-2">
                        <span className="mr-2 text-greenn">✔</span>
                        On-scroll/On-load animation
                      </li>
                      <li className="flex mt-2">
                        <span className="mr-2 text-greenn">✔</span>
                        Social share button
                      </li>
                      <li className="flex mt-2">
                        <span className="mr-2 text-greenn">✔</span>
                        Free stack image gallery
                      </li>
                      <li className="flex mt-2">
                        <span className="mr-2 text-greenn">✔</span>
                        Socila share preview
                      </li>
                      <li className="flex mt-2">
                        <span className="mr-2 text-greenn">✔</span>
                        Custom fevicon
                      </li>
                      <li className="flex mt-2">
                        <span className="mr-2 text-greenn">✔</span>
                        SSL Hosting
                      </li>
                      <li className="flex mt-2">
                        <span className="mr-2 text-greenn">✔</span>
                        Direct Integration
                      </li>
                      <li className="flex mt-2">
                        <span className="mr-2 text-greenn">✔</span>
                        Zoho Flow/Zapier Connectors
                      </li>
                      <li className="flex mt-2">
                        <span className="mr-2 text-greenn">✔</span>
                        Direct Integration
                      </li>
                      <li className="flex mt-2">
                        <span className="mr-2 text-greenn">✔</span>
                        SSL Hosting
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="bg-planorange border-planorange spcl-shadow ">
                  <div className="xl:p-12 lg:p-8 md:p-8 p-8">
                    <p className="-mt-1 xl:text-sm text-blue lg:text-xl md:text-lg text-lg banner-font font-semibold">
                      * MOST POPULAR *
                    </p>
                    <p className="xl:pt-7 lg:pt-0 xl:text-sm text-blue lg:text-xl md:text-lg text-lg banner-font font-medium">
                      PROFESSIONAL
                    </p>
                    <h1 className="xl:pt-8 font-semibold text-black banner-font xl:text-5xl">
                      ₹1,740
                    </h1>
                    <p className="xl:pt-3 lg:pt-8 pt-4 xl:text-base1 text-base  text-gray leading-relaxed headlineheight banner-font">
                      /month billed annually
                    </p>

                    <button className="bg-orange px-10 py-2 uppercase font-semibold text-xs banner-font text-center text-white mt-4">
                      Sign up
                    </button>
                  </div>
                  <div className="xl:pl-6 xl:pb-8 bgg pt-6 border-t xl:w-12/12">
                    <ul>
                      <li className="flex">
                        <span className="mr-2 text-green">✔</span>
                        Blocks/Sections
                      </li>
                      <li className="flex mt-2">
                        <span className="mr-2 text-greenn">✔</span>
                        Multi-Page device preview
                      </li>
                      <li className="flex mt-2">
                        <span className="mr-2 text-greenn">✔</span>
                        Mobile responsive pages
                      </li>
                      <li className="flex mt-2">
                        <span className="mr-2 text-greenn">✔</span>
                        On-scroll/On-load animation
                      </li>
                      <li className="flex mt-2">
                        <span className="mr-2 text-greenn">✔</span>
                        Social share button
                      </li>
                      <li className="flex mt-2">
                        <span className="mr-2 text-greenn">✔</span>
                        Free stack image gallery
                      </li>
                      <li className="flex mt-2">
                        <span className="mr-2 text-greenn">✔</span>
                        Socila share preview
                      </li>
                      <li className="flex mt-2">
                        <span className="mr-2 text-greenn">✔</span>
                        Custom fevicon
                      </li>
                      <li className="flex mt-2">
                        <span className="mr-2 text-greenn">✔</span>
                        SSL Hosting
                      </li>
                      <li className="flex mt-2">
                        <span className="mr-2 text-greenn">✔</span>
                        Direct Integration
                      </li>
                    </ul>
                  </div>
                  <div className="xl:pl-6 xl:pb-8 bg-light-green pt-6 shadow-xl border-t xl:w-12/12">
                    <ul>
                      <li className="flex">
                        <span className="mr-2 text-green">✔</span>
                        Blocks/Sections
                      </li>
                      <li className="flex mt-2">
                        <span className="mr-2 text-greenn">✔</span>
                        Multi-Page device preview
                      </li>
                      <li className="flex mt-2">
                        <span className="mr-2 text-greenn">✔</span>
                        Mobile responsive pages
                      </li>
                      <li className="flex mt-2">
                        <span className="mr-2 text-greenn">✔</span>
                        On-scroll/On-load animation
                      </li>
                      <li className="flex mt-2">
                        <span className="mr-2 text-greenn">✔</span>
                        Social share button
                      </li>
                      <li className="flex mt-2">
                        <span className="mr-2 text-greenn">✔</span>
                        Free stack image gallery
                      </li>
                      <li className="flex mt-2">
                        <span className="mr-2 text-greenn">✔</span>
                        Socila share preview
                      </li>
                      <li className="flex mt-2">
                        <span className="mr-2 text-greenn">✔</span>
                        Custom fevicon
                      </li>
                      <li className="flex mt-2">
                        <span className="mr-2 text-greenn">✔</span>
                        SSL Hosting
                      </li>
                      <li className="flex mt-2">
                        <span className="mr-2 text-greenn">✔</span>
                        Direct Integration
                      </li>
                      <li className="flex mt-2">
                        <span className="mr-2 text-greenn">✔</span>
                        Zoho Flow/Zapier Connectors
                      </li>
                      <li className="flex mt-2">
                        <span className="mr-2 text-greenn">✔</span>
                        SSL Hosting
                      </li>
                      <li className="flex mt-2">
                        <span className="mr-2 text-greenn">✔</span>
                        Direct Integration
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="bg-white border xl:mt-8 shadow-xl">
                  <div className="xl:p-12 lg:p-8 md:p-8 p-8">
                    <p className="xl:pt-2 lg:pt-0 xl:text-sm text-blue lg:text-xl md:text-lg text-lg banner-font font-medium">
                      ENTERPRISE
                    </p>
                    <h1 className="xl:pt-8 font-semibold text-black banner-font xl:text-5xl">
                      ₹2,820
                    </h1>
                    <p className="xl:pt-3 lg:pt-8 pt-4 xl:text-base1 text-base  text-gray leading-relaxed headlineheight banner-font">
                      /month billed annually
                    </p>

                    <button className="bg-sky-blue px-10 py-2 banner-font text-center text-white mt-4">
                      Sign up
                    </button>
                  </div>
                  <div className="xl:pl-6 xl:pb-8 bggg pt-6 border-t xl:w-12/12">
                    <ul>
                      <li className="flex">
                        <span className="mr-2 text-green">✔</span>
                        Blocks/Sections
                      </li>
                      <li className="flex mt-2">
                        <span className="mr-2 text-greenn">✔</span>
                        Multi-Page device preview
                      </li>
                      <li className="flex mt-2">
                        <span className="mr-2 text-greenn">✔</span>
                        Mobile responsive pages
                      </li>
                      <li className="flex mt-2">
                        <span className="mr-2 text-greenn">✔</span>
                        On-scroll/On-load animation
                      </li>
                      <li className="flex mt-2">
                        <span className="mr-2 text-greenn">✔</span>
                        Social share button
                      </li>
                      <li className="flex mt-2">
                        <span className="mr-2 text-greenn">✔</span>
                        Free stack image gallery
                      </li>
                      <li className="flex mt-2">
                        <span className="mr-2 text-greenn">✔</span>
                        Socila share preview
                      </li>
                      <li className="flex mt-2">
                        <span className="mr-2 text-greenn">✔</span>
                        Custom fevicon
                      </li>
                      <li className="flex mt-2">
                        <span className="mr-2 text-greenn">✔</span>
                        SSL Hosting
                      </li>
                      <li className="flex mt-2">
                        <span className="mr-2 text-greenn">✔</span>
                        Direct Integration
                      </li>
                    </ul>
                  </div>
                  <div className="xl:pl-6 xl:pb-8 bg-light-green pt-6 border-t xl:w-12/12">
                    <ul>
                      <li className="flex">
                        <span className="mr-2 text-green">✔</span>
                        Blocks/Sections
                      </li>
                      <li className="flex mt-2">
                        <span className="mr-2 text-greenn">✔</span>
                        Multi-Page device preview
                      </li>
                      <li className="flex mt-2">
                        <span className="mr-2 text-greenn">✔</span>
                        Mobile responsive pages
                      </li>
                      <li className="flex mt-2">
                        <span className="mr-2 text-greenn">✔</span>
                        On-scroll/On-load animation
                      </li>
                      <li className="flex mt-2">
                        <span className="mr-2 text-greenn">✔</span>
                        Social share button
                      </li>
                      <li className="flex mt-2">
                        <span className="mr-2 text-greenn">✔</span>
                        Free stack image gallery
                      </li>
                      <li className="flex mt-2">
                        <span className="mr-2 text-greenn">✔</span>
                        Socila share preview
                      </li>
                      <li className="flex mt-2">
                        <span className="mr-2 text-greenn">✔</span>
                        Custom fevicon
                      </li>
                      <li className="flex mt-2">
                        <span className="mr-2 text-greenn">✔</span>
                        SSL Hosting
                      </li>
                      <li className="flex mt-2">
                        <span className="mr-2 text-greenn">✔</span>
                        Direct Integration
                      </li>
                      <li className="flex mt-2">
                        <span className="mr-2 text-greenn">✔</span>
                        Zoho Flow/Zapier Connectors
                      </li>
                      <li className="flex mt-2">
                        <span className="mr-2 text-greenn">✔</span>
                        Direct Integration
                      </li>
                      <li className="flex mt-2">
                        <span className="mr-2 text-greenn">✔</span>
                        SSL Hosting
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <p className="pt-8 text-gray text-xs banner-font text-right pr-8">
              Local taxes (VAT, GST, etc.) will be charged in addition to the
              prices mentioned.
            </p>
          </div>
          <div className="xl:px-68 xl:pt-12">
            <div className="grid grid-cols-3 border rounded-md xl:px-12 xl:py-4">
              <div className="flex items-center border-r">
                <img
                  src="https://i.ibb.co/ZSrQXX7/client-addon.png"
                  className="xl:w-3/12"
                />
                <h1 className="banner-font text-black xl:text-2xl font-semibold pl-4">
                  Add-ons
                </h1>
              </div>
              <div className="xl:pt-2 xl:pl-20">
                <h1 className="banner-font text-left text-black xl:text-base1 pl-4">
                  1 Domain - <b> ₹300</b>
                </h1>
                <h1 className="banner-font text-left text-black xl:text-base1 pl-4 pt-4">
                  1 Workspace - <b>₹300</b>
                </h1>
              </div>
              <div className="xl:pt-2 xl:pl-20">
                <h1 className="banner-font text-left text-black xl:text-base1 pl-4">
                  5K visitors - <b> ₹300</b>
                </h1>
                <h1 className="banner-font text-left text-black xl:text-base1 pl-4 pt-4">
                  10 Users - <b>₹300</b>
                </h1>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="xl:px-72 xl:pb-20">
        <h2 className="xl:px-52 xl:pt-4 pt-3 text-center font-medium xl:text-4xl lg:text-2xl md:text-2xl text-xl lg:text-3xl md:text-2xl text-xl text-black banner-font">
          Frequently Asked Questions
        </h2>
        <p className="text-gray banner-font xl:text-base1 text-center xl:px-64 xl:pt-3">
          We help you prepare for your trip and ensure an effortless and
          enjoyable travel experience.
        </p>
        <div className="grid xl:grid-cols-1 gap-2 xl:pt-12">
          <div>
            <h1
              className={`banner-font  bg-white xl:p-3 border-b font-semibold xl:text-base1 ${
                showParagraph1 ? "active-heading" : "banner-font font-medium"
              }`}
              onClick={toggleParagraph1}
              style={{ cursor: "pointer" }}
            >
              <div className="flex justify-between">
                <p>Can I get a demo of Zoho LandingPage?</p>
                <p className="font-extrabold pr-2">✛</p>
              </div>
            </h1>
            {showParagraph1 && (
              <p
                className={`paragraph xl:p-3 bg-white text-gray banner-font ${
                  showParagraph1 ? "show" : "hide"
                }`}
              >
                Yes, we would be happy to demonstrate Zoho LandingPage to you in
                a web conference. To schedule a one-on-one personal session at a
                time that suits you, please contact support@zoholandingpage.com.
              </p>
            )}
          </div>
          <div>
            <h1
              className={`banner-font bg-white xl:p-3 border-b font-semibold xl:text-base1 ${
                showParagraph2 ? "active-heading" : ""
              }`}
              onClick={toggleParagraph2}
              style={{ cursor: "pointer" }}
            >
              <div className="flex justify-between">
                <p> Can I get a free trial of the product?</p>
                <p className="font-extrabold pr-2">✛</p>
              </div>
            </h1>
            {showParagraph2 && (
              <p className="xl:p-3 text-gray bg-white banner-font">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
                tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
              </p>
            )}
          </div>
          <div>
            <h1
              className={`banner-font rounded-t-md bg-white xl:p-3 border-b font-semibold xl:text-base1 ${
                showParagraph3 ? "active-heading" : ""
              }`}
              onClick={toggleParagraph3}
              style={{ cursor: "pointer" }}
            >
              <div className="flex justify-between">
                <p> What types of payment do you accept?</p>
                <p className="font-extrabold pr-2">✛</p>
              </div>
            </h1>
            {showParagraph3 && (
              <p className="xl:p-3 text-gray bg-white banner-font">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
                tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
              </p>
            )}
          </div>

          <div>
            <h1
              className={`banner-font rounded-t-md bg-white xl:p-3 border-b font-semibold xl:text-base1 ${
                showParagraph4 ? "active-heading" : ""
              }`}
              onClick={toggleParagraph4}
              style={{ cursor: "pointer" }}
            >
              <div className="flex justify-between">
                <p> Is my data safe?</p>
                <p className="font-extrabold pr-2">✛</p>
              </div>
            </h1>
            {showParagraph4 && (
              <p className="xl:p-3 text-gray bg-white banner-font">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
                tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
              </p>
            )}
          </div>
          <div>
            <h1
              className={`banner-font rounded-t-md bg-white xl:p-3 border-b font-semibold xl:text-base1 ${
                showParagraph5 ? "active-heading" : ""
              }`}
              onClick={toggleParagraph5}
              style={{ cursor: "pointer" }}
            >
              <div className="flex justify-between">
                <p> How long are your contracts?</p>
                <p className="font-extrabold pr-2">✛</p>
              </div>
            </h1>
            {showParagraph5 && (
              <p className="xl:p-3 text-gray bg-white banner-font">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
                tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
              </p>
            )}
          </div>
          <div>
            <h1
              className={`banner-font rounded-t-md bg-white xl:p-3 border-b font-semibold xl:text-base1 ${
                showParagraph6 ? "active-heading" : ""
              }`}
              onClick={toggleParagraph6}
              style={{ cursor: "pointer" }}
            >
              <div className="flex justify-between">
                <p> What happens to my data after I cancel the account?</p>
                <p className="font-extrabold pr-2">✛</p>
              </div>
            </h1>
            {showParagraph6 && (
              <p className="xl:p-3 text-gray bg-white banner-font">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
                tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
              </p>
            )}
          </div>
        </div>
      </div>
      <Footer2 />
    </>
  );
}
